import Icon from "../../../../components/body/icon";
import Td from "../../../../components/body/table/tbody/td";
import Tr from "../../../../components/body/table/tr";
import Funcionario from "./funcionario";

export default function Loja({ loja_name, vendedor_name, loja, setLoja, vendedor, setVendedor }) {
    return (
        <>
            <Tr>
                <Td style={{ paddingLeft: "40px" }}>
                    {loja_name} <Icon type={vendedor ? "minus" : "plus"} className="text-secondary" onClick={() => setVendedor(vendedor ? '' : 2)} />
                </Td>
                <Td>
                    300
                </Td>
                <Td>
                    200
                </Td>
                <Td>
                    150
                </Td>
                <Td>
                    300
                </Td>
                <Td>
                    400
                </Td>
                <Td>
                    300
                </Td>
                <Td>
                    40
                </Td>
                <Td>
                    500
                </Td>
            </Tr>
            {(
                vendedor ?
                    <>
                        <Funcionario

                        />
                        <Funcionario

                        />
                        <Funcionario

                        />
                    </>
                    : <></>
            )}
        </>
    )
}