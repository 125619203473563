import { useEffect, useState } from "react";
import Button from "../../../components/body/button";
import Gerenciador from "../../../components/body/gerenciador";
import Form from "../../../components/body/form"
import Input from "../../../components/body/form/input";
import Icon from "../../../components/body/icon";

export default function NovaPergunta({ callback_close, id, title }) {

    //ESTADOS INPUT

    const [pergunta, setPergunta] = useState('');

    useEffect(() => { if (id) { setPergunta(title) } }, [id]);


    return (
        <Gerenciador
            title={id ? "Editar" : "Novo"}
            icon={<Icon type="reprovar" title="Fechar" onClick={() => callback_close(false)} />}
        >
            <Form

            >
                <Input
                    type="text"
                    name="pergunta"
                    label="Pergunta"
                    value={pergunta}
                    onChange={(e) => setPergunta(e.target.value)}
                />

                <Button type="submit" >Enviar</Button>
            </Form>
        </Gerenciador>
    )
}