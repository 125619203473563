import { useState, useEffect } from 'react';

import Gerenciador from '../../../components/body/gerenciador';
import Input from '../../../components/body/form/input';
import Table from '../../../components/body/table';
import Tbody from '../../../components/body/table/tbody';
import Tr from '../../../components/body/table/tr';
import Td from '../../../components/body/table/tbody/td';
import { scrollToCol } from '../../../_assets/js/global';

export default function Tipo({emp, callback}){
    // ESTADOS
    const [active, setActive] = useState(false);
    const [filter, setFilter] = useState(null);

    // SETA TIPO ATIVO
    function handleSetActive(id){
        let id_aux = (active == id ? '' : id); // VERIFICA SE O ITEM ESTÁ ATIVO PARA MANDAR O ID VAZIO E RESETAR O FILTRO SE FOR O CASO

        setActive(id_aux);

        if(callback){
            callback({
                items: items,
                active: id_aux,
                edit: false
            })
        }

        // SCROLL AUTOMÁTICO ATÉ A COLUNA DE SISTEMAS
        scrollToCol((id === 'sistemas' ? 'sistemas' : 'usuarios'));
    }

    // RESETA STATUS
    useEffect(() => {
        setActive((window.rs_permission_apl === 'master' ? null : 'usuarios'));
    },[emp]);

    // TIPOS
    const items = [
        {value: 'sistemas', label: 'Sistemas'},
        {value: 'usuarios', label: 'Lojas e Usuários'}
    ]

    return(
        <>
            <Gerenciador 
                id="tipo"
                title="Tipos"
                search={
                    <Input
                        name="filter_tipo"
                        value={filter}
                        onChange={(e) => setFilter(e.target.value)}
                    />
                }
            >
                <Table
                    id="table_group"      
                    text_limit={(window.isMobile ? 20 : 30)}
                >
                    <Tbody>
                        {(items.length > 0 ?
                            items.filter((item) => {
                                if (!filter) return true;
                                if (item.label.toLowerCase().includes(filter.toLocaleLowerCase())) {
                                    return true
                                }
                            }).map((item, i) => {
                                return(
                                    <Tr
                                        key={'tipo_'+item.value}
                                        cursor="pointer"
                                        active={(active === item.value ? true : false)}
                                    >
                                        <Td
                                            onClick={() => handleSetActive(item.value)}
                                        >
                                            {item.label}
                                        </Td>
                                    </Tr>
                                )
                            })
                        :
                            <></>
                        )}
                    </Tbody>
                </Table>
            </Gerenciador>
        </>
    )
}
