import { useEffect, useState, useContext, useRef } from 'react';

import style from './Chat.module.scss';
import Form from "../form";
import Textarea from "../form/textarea";
import Button from '../button';

import { GlobalContext } from "../../../context/Global";
import Input from '../form/input';
import Message from './message';

export default function Chat(props) {

    //ESTADOS
    const bottomRef = useRef(null);
    const { refreshChat, handleRefreshChat, handleChatLoaded, chatLoaded } = useContext(GlobalContext);
    const [message, setMessage] = useState(props?.defaultValue);
    const [anexos, setAnexos] = useState([]);
    const [buttonStatus, setButtonStatus] = useState('');

    const data = {
        ativ: 'Enviou uma mensagem',
        id_mov: props.id,
        ativ_desc: message,
        anexos: anexos,
        nivel_msg: (props?.nivel_msg || props?.nivel_msg === 0 ? props?.nivel_msg : (props?.data?.nivel_msg ? props.data.nivel_msg : '' ))
    }

    if (refreshChat) {
        setTimeout(() => {
            handleChatLoaded(!chatLoaded);
        }, 100);
        if (message) {
            setMessage('');
        }
    }

    // KEY ALEATÓRIA
    const key = Math.random();

    useEffect(() => {
        setTimeout(() => {
            var divChat = document.getElementById('chat_' + key);
            if (divChat) {
                divChat.scrollTop = divChat.scrollHeight;
            }
        }, 100);
    }, [refreshChat, bottomRef, chatLoaded]);

    useEffect(() => {
        if (props?.show) {
            // handleRefreshChat(true);
        }
    }, [props?.show]);

    function formCallback() {
        // handleRefreshChat(true);
        setAnexos([]);
        props.callback(true);
        props.setAutoSubmit(false);
    }

    // SETAR ANEXO
    const handleSetAnexos = (response) => {
        setAnexos(response[0]);
    };

    // ATUALIZA DEFAULT VALUE DO CHAT SEMPRE O VALOR FOR ALTERADO NO COMPONENTE PAI
    useEffect(() => {
        setMessage(props?.defaultValue);
    },[props?.defaultValue]);

    // STATUS BOTÃO
    const handleButtonStatus = (e) => {
        if(e === 'loading'){
            setButtonStatus(e);
        }else if(e === 'success'){
            setButtonStatus(e);
        }
    }

    // SETA MENSAGEM
    const handleSetMessage = (e) => {
        setMessage((props?.editor ? e : e.target.value));
        setButtonStatus('');
    }

    return (
        <>
            {(!props.empty && (props.children || props.defaultMessage) ?
                <div
                    className={style.message__container + ' ' + props?.className}
                    data-message_container
                    id={'chat_' + props?.id}
                    style={{ marginBottom: (props.send !== false ? 10 : ''), maxHeight: (props?.maxHeight ? props?.maxHeight : '') }}
                >
                    {(props.defaultMessage ?
                        <>
                            <Message
                                date={props.defaultMessage?.date}
                                sender={props.defaultMessage?.sender}
                                text={props.defaultMessage?.text}
                                align={props.defaultMessage?.align}
                            />
                            <span style={{ clear: 'both', display: 'block' }}></span>
                        </>
                        : '')}

                    {props.children}

                    <span style={{ clear: 'both', display: 'block' }}></span>
                    <div ref={bottomRef} />
                </div>
                : '')}
            {(props.send !== false ?
                <Form
                    api={props?.api}
                    border={props?.border}
                    data={data}
                    toast="Mensagem enviada com sucesso"
                    callback={() => formCallback()}
                    status={handleButtonStatus}
                    autoSend={(props.autoSubmit && message ? true : false)}
                >
                    <Textarea
                        placeholder={(props.placeholder ? props.placeholder : 'Mensagem')}
                        value={message}
                        editor={props?.editor}
                        onChange={handleSetMessage}
                    />

                    {(props.anexo !== false ?
                        <Input
                            type="file"
                            label="Anexo"
                            required={false}
                            value={anexos}
                            multiple={(props.anexo?.multiple ? props.anexo.multiple : false)}
                            callback={handleSetAnexos}
                        />
                        : '')}

                    <Button type="submit" status={buttonStatus}>Enviar</Button>
                </Form>
                : '')}
        </>
    )
}
