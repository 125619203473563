import Col from "../../../components/body/col";
import Row from "../../../components/body/row";
import SelectReact from "../../../components/body/select";
import Table from "../../../components/body/table";
import Tbody from "../../../components/body/table/tbody";
import Td from "../../../components/body/table/tbody/td";
import Thead from "../../../components/body/table/thead";
import Th from "../../../components/body/table/thead/th";
import Tr from "../../../components/body/table/tr";
import Title from "../../../components/body/title";
import Dashboard from "../../../components/body/dashboard";
import Input from "../../../components/body/form/input";

export default function DashboardNps() {

    // VARIAVEIS DE INFORMAÇÕES DAS TABELAS
    const campanhas = [
        {
            id: 1,
            titulo: "Asd",
            quantidade: 0,
            por_cento: "0%"
        },
        {
            id: 2,
            titulo: "Suporte TI",
            quantidade: 2,
            por_cento: "0%"
        },
        {
            id: 3,
            titulo: "Teste Drogal Telefones",
            quantidade: 0,
            por_cento: "0%"
        },
        {
            id: 4,
            titulo: "Suporte TI 2",
            quantidade: 0,
            por_cento: "0%"
        },
        {
            id: 5,
            titulo: "Teste Drogal",
            quantidade: 0,
            por_cento: "0%"
        },
        {
            id: 6,
            titulo: "Suporte TI 2",
            quantidade: 0,
            por_cento: "0%"
        },
        {
            id: 7,
            titulo: "Teste Drogal 2",
            quantidade: 0,
            por_cento: "0%"
        },
        {
            id: 8,
            titulo: "Suporte TI 4",
            quantidade: 0,
            por_cento: "0%"
        },
        {
            id: 9,
            titulo: "Teste 1",
            quantidade: 0,
            por_cento: "0%"
        },
        {
            id: 10,
            titulo: "Teste 2",
            quantidade: 0,
            por_cento: "0%"
        },
        {
            id: 11,
            titulo: "Teste 3",
            quantidade: 0,
            por_cento: "0%"
        },
        {
            id: 12,
            titulo: "Teste 4",
            quantidade: 0,
            por_cento: "0%"
        },
        {
            id: 13,
            titulo: "Teste 5",
            quantidade: 0,
            por_cento: "0%"
        }
    ]
    const lojas = [
        {
            id: 1,
            titulo: "Loja 1",
            quantidade: 2,
            por_cento: "0%"
        },
        {
            id: 2,
            titulo: "MDZ",
            quantidade: 2,
            por_cento: "0%"
        },
        {
            id: 3,
            titulo: "L047",
            quantidade: 2,
            por_cento: "0%"
        },
        {
            id: 4,
            titulo: "Loja m072",
            quantidade: 2,
            por_cento: "0%"
        },
        {
            id: 5,
            titulo: "Loja 123",
            quantidade: 2,
            por_cento: "0%"
        },
        {
            id: 6,
            titulo: "Loja 4",
            quantidade: 2,
            por_cento: "0%"
        },
        {
            id: 7,
            titulo: "Loja Teste",
            quantidade: 2,
            por_cento: "0%"
        },
        {
            id: 8,
            titulo: "Loja 1",
            quantidade: 2,
            por_cento: "0%"
        },
        {
            id: 9,
            titulo: "Loja MDZ",
            quantidade: 2,
            por_cento: "0%"
        },
        {
            id: 10,
            titulo: "Loja Fast",
            quantidade: 2,
            por_cento: "0%"
        },

    ]
    const funcionarios = [
        {
            id: 1,
            titulo: "Funcionario 1",
            quantidade: 2,
            por_cento: "0%"
        },
        {
            id: 2,
            titulo: "Anderson Schmitt",
            quantidade: 2,
            por_cento: "0%"
        },
        {
            id: 3,
            titulo: "Funcionario 1",
            quantidade: 2,
            por_cento: "0%"
        },
        {
            id: 3,
            titulo: "Antonio",
            quantidade: 2,
            por_cento: "0%"
        },
        {
            id: 4,
            titulo: "Eduardo",
            quantidade: 2,
            por_cento: "0%"
        },
        {
            id: 5,
            titulo: "Emanuel",
            quantidade: 2,
            por_cento: "0%"
        },
        {
            id: 6,
            titulo: "Larissa",
            quantidade: 2,
            por_cento: "0%"
        },
        {
            id: 7,
            titulo: "Paula",
            quantidade: 2,
            por_cento: "0%"
        },
        {
            id: 8,
            titulo: "Leticia",
            quantidade: 2,
            por_cento: "0%"
        },
        {
            id: 9,
            titulo: "Anderson",
            quantidade: 2,
            por_cento: "0%"
        },
        {
            id: 10,
            titulo: "Funcionario Teste 123S",
            quantidade: 2,
            por_cento: "0%"
        },
        {
            id: 11,
            titulo: "Funcionario 2",
            quantidade: 2,
            por_cento: "0%"
        }
        ,
        {
            id: 12,
            titulo: "Funcionario 2",
            quantidade: 2,
            por_cento: "0%"
        }
        ,
        {
            id: 13,
            titulo: "Funcionario 2",
            quantidade: 2,
            por_cento: "0%"
        }
        ,
        {
            id: 14,
            titulo: "Funcionario 2",
            quantidade: 2,
            por_cento: "0%"
        }
        ,
        {
            id: 15,
            titulo: "Funcionario 2",
            quantidade: 2,
            por_cento: "0%"
        }
        ,
        {
            id: 16,
            titulo: "Funcionario 2",
            quantidade: 2,
            por_cento: "0%"
        }
        ,
        {
            id: 17,
            titulo: "Funcionario 2",
            quantidade: 2,
            por_cento: "0%"
        }
        ,
        {
            id: 18,
            titulo: "Funcionario 2",
            quantidade: 2,
            por_cento: "0%"
        }
        ,
        {
            id: 19,
            titulo: "Funcionario 2",
            quantidade: 2,
            por_cento: "0%"
        }
        ,
        {
            id: 20,
            titulo: "Funcionario 2",
            quantidade: 2,
            por_cento: "0%"
        }
        ,
        {
            id: 21,
            titulo: "Funcionario 2",
            quantidade: 2,
            por_cento: "0%"
        }

    ]
    const respostas = [
        {
            id: 1,
            titulo: "Resposta 123",
            quantidade: 2,
            por_cento: "0%"
        },
        {
            id: 2,
            titulo: "Internet não funciona",
            quantidade: 2,
            por_cento: "0%"
        },
        {
            id: 3,
            titulo: "Computador não liga",
            quantidade: 2,
            por_cento: "0%"
        },
        {
            id: 4,
            titulo: "Travamento no caixa",
            quantidade: 2,
            por_cento: "0%"
        },
        {
            id: 5,
            titulo: "Outros 1",
            quantidade: 2,
            por_cento: "0%"
        },
        {
            id: 6,
            titulo: "resposta ",
            quantidade: 2,
            por_cento: "0%"
        },
        {
            id: 7,
            titulo: "Internet não funciona",
            quantidade: 2,
            por_cento: "0%"
        },
        {
            id: 8,
            titulo: "Computador não liga",
            quantidade: 2,
            por_cento: "0%"
        },
        {
            id: 9,
            titulo: "Travamento no caixa",
            quantidade: 2,
            por_cento: "0%"
        },
        {
            id: 10,
            titulo: "resposta ",
            quantidade: 2,
            por_cento: "0%"
        },
        {
            id: 11,
            titulo: "Internet não funciona",
            quantidade: 2,
            por_cento: "0%"
        },
        {
            id: 12,
            titulo: "Computador não liga",
            quantidade: 2,
            por_cento: "0%"
        },
        {
            id: 13,
            titulo: "Travamento no caixa",
            quantidade: 2,
            por_cento: "0%"
        },
        {
            id: 14,
            titulo: "Internet não funciona",
            quantidade: 2,
            por_cento: "0%"
        },
        {
            id: 15,
            titulo: "Computador não liga",
            quantidade: 2,
            por_cento: "0%"
        },
        {
            id: 16,
            titulo: "resposta ",
            quantidade: 2,
            por_cento: "0%"
        },
        {
            id: 17,
            titulo: "Internet não funciona",
            quantidade: 2,
            por_cento: "0%"
        },
        {
            id: 18,
            titulo: "Computador não liga",
            quantidade: 2,
            por_cento: "0%"
        },
        {
            id: 19,
            titulo: "Travamento no caixa",
            quantidade: 2,
            por_cento: "0%"
        },
        {
            id: 20,
            titulo: "Internet não funciona",
            quantidade: 2,
            por_cento: "0%"
        },
        {
            id: 21,
            titulo: "Computador não liga",
            quantidade: 2,
            por_cento: "0%"
        },
        {
            id: 22,
            titulo: "Resposta 20",
            quantidade: 2,
            por_cento: "0%"
        },
        {
            id: 23,
            titulo: "Resposta Teste",
            quantidade: 2,
            por_cento: "0%"
        }
    ]
    return (
        <>
            <Title
                overflow={true}
                filter={
                    <Input
                        type="date"
                    />
                }>
                Dashboard
            </Title>
            <Row wrap={(window.isMobile ? true : false)}>

                <Col>
                    <Dashboard
                        title="Campanhas">
                        <Table>
                            <Thead>
                                <Tr>
                                    <Th>Campanha</Th>
                                    <Th> QTD </Th>
                                    <Th> % </Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {campanhas.map((item) => {
                                    return (
                                        <Tr>
                                            <Td> {item.titulo} </Td>
                                            <Td> {item.quantidade} </Td>
                                            <Td> {item.por_cento} </Td>
                                        </Tr>
                                    )
                                })}
                            </Tbody>
                        </Table>
                    </Dashboard>
                </Col>
                <Col>
                    <Dashboard
                        title="Lojas">
                        <Table>
                            <Thead>
                                <Tr>
                                    <Th>Loja</Th>
                                    <Th> QTD </Th>
                                    <Th> % </Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {lojas.map((item)=>{
                                    return(
                                        <Tr>
                                            <Td> {item.titulo} </Td>
                                            <Td> {item.quantidade} </Td>
                                            <Td> {item.por_cento} </Td>
                                        </Tr>
                                    )
                                })}
                            </Tbody>
                        </Table>
                    </Dashboard>
                </Col>
                <Col>
                    <Dashboard
                        title="Funcionários">
                        <Table>
                            <Thead>
                                <Tr>
                                    <Th>Funcionário</Th>
                                    <Th> QTD </Th>
                                    <Th> % </Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {funcionarios.map((item)=>{
                                        return(
                                            <Tr>
                                                <Td> {item.titulo} </Td>
                                                <Td> {item.quantidade} </Td>
                                                <Td> {item.por_cento} </Td>
                                            </Tr>
                                        )
                                    })}
                            </Tbody>
                        </Table>
                    </Dashboard>
                </Col>
                <Col>
                    <Dashboard
                        title="Respostas">
                        <Table>
                            <Thead>
                                <Tr>
                                    <Th>Avaliação</Th>
                                    <Th> QTD </Th>
                                    <Th> % </Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                            {respostas.map((item)=>{
                                    return(
                                        <Tr>
                                            <Td> {item.titulo} </Td>
                                            <Td> {item.quantidade} </Td>
                                            <Td> {item.por_cento} </Td>
                                        </Tr>
                                    )
                                })}
                            </Tbody>
                        </Table>
                    </Dashboard>
                </Col>
            </Row>
        </>
    )
}
