import { useState } from 'react';
import style from './group.module.scss';
import { FiChevronDown, FiChevronUp } from "react-icons/fi";

export default function Group(props){
    // ESTADOS
    const [collapse, setCollapse] = useState((props.collapse ? props.collapse : false));

    return(
        <div className={style.group}>
            <div className="d-flex align-items-center justify-content-between w-100" onClick={() => setCollapse(!collapse)}>
                <span>
                    {props?.icon}
                    {props?.title}
                </span>

                {(collapse ? 
                    <FiChevronUp />
                : 
                    <FiChevronDown />
                )}                
            </div>
            <div className={style.children + ' ' + (collapse ? '' : style.collapse)}>
                {props.children}
            </div>
        </div>
    )
}
