
import { useState } from "react";
import Icon from "../../../components/body/icon";
import Upload from "../../../components/body/upload/Upload";
import "./style.scss"

export default function InputFoto(props) {


    const [fileValue, setFileValue] = useState(null);
    const [fileValueName, setFileValueName] = useState(null);

    // FUNÇÃO PARA PEGAR VALOR INPUT FILE
    function handleFileValue(value) {
        let parts = value.split('\\');
        let result = parts[parts.length - 1];
        setFileValue(value);
        setFileValueName(result);
    }

    return (
        <>
            <span
                className="inputFotoStyle"
                value={props.value}
            >
                <span title={fileValueName}>
                    {fileValueName}
                </span>
                {(fileValue ? <Icon type="reprovar" title="Remover anexo" onClick={() => (setFileValue(null), setFileValueName(null))} /> : '')}
                <Upload
                    type={props?.type}
                    callback={props.callback} 
                />
                
            </span>
        </>
    )
}