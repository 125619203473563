import { useState, useEffect } from "react";

import Modal from "../../../components/body/modal";
import ModalBody from "../../../components/body/modal/modalBody";
import ModalHeader from "../../../components/body/modal/modalHeader";
import ModalTitle from "../../../components/body/modal/modalHeader/modalTitle";
import Table from "../../../components/body/table";
import Tbody from "../../../components/body/table/tbody";
import Tr from "../../../components/body/table/tr";
import Td from "../../../components/body/table/tbody/td";
import Icon from "../../../components/body/icon";

export default function Legendas({show, hide, items}){
    // ESTADOS
    const [showModal, setShowModal] = useState(show);

    //FECHA
    const handleCloseModal = (send) => {
        setShowModal(false);
        if(hide){
            hide(send);
        }
    }

    useEffect(() => {
        if(items){
            setShowModal(show);
        }
    },[show]);

    return(
        <Modal show={showModal} onHide={() => handleCloseModal(false)}>
            <ModalHeader>
                <ModalTitle>
                    Legendas
                </ModalTitle> 
            </ModalHeader>
            <ModalBody>
                <Table text_limit={false}>
                    <Tbody>     
                        {(items.length > 0 ?                   
                            items.map((item, i) => {
                                return(
                                    <Tr key={'icone_'+item?.id}>
                                        <Td disableView={false}>
                                            <div>
                                                <Icon
                                                    type={item?.icone}
                                                    readonly
                                                    title={false}
                                                    className="me-2"
                                                />

                                                {(item?.item_nome.includes('(') ?
                                                    item?.item_nome.split('(')[0]
                                                :
                                                    item?.item_nome
                                                )}

                                                <p className="mt-2 mb-0">{item?.item_descricao}</p>
                                            </div>
                                        </Td>
                                    </Tr>
                                )
                            })
                        :'')}
                    </Tbody>
                </Table>
            </ModalBody>
        </Modal>
    )
}