import style from './User.module.scss';
import { useState, useEffect, useRef } from 'react';
import { FaCaretDown, FaCog, FaFile, FaPalette, FaBuilding, FaCode, FaSignOutAlt } from 'react-icons/fa';

export default function User(props){
    //FUNÇÃO PARA DETECTAR O CLIQUE FORA DO ELEMENTO
    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true)
    }, []);

    //FUNÇÃO PARA DETECTAR QUANDO O USUÁRIO PRESSIONAR A TECLA ESC
    useEffect(() => {
        document.addEventListener('keydown', escFunction, false)
    }, []);

    const [ menuUserActive, setMenuUserActive ] = useState(false);   
    const boxUser = useRef(null);

    const handleClickOutside = (e) => {
        // if(!boxUser.current.contains(e.target)){
        //     setMenuUserActive(false);
        // }
    }

    const escFunction = (e) => {
        if(e.key === 'Escape'){
            setMenuUserActive(false);
        }
    }

    return(
        <div className={ style.user + ' ' + style.boxUser + ' ' + (menuUserActive===true?style.user__active:'')} ref={ boxUser }>
            <div onClick={ () => setMenuUserActive((menuUserActive===true?false:true)) }>
                <div className={ style.user__photo }>
                    { props.name.slice(0,1) }
                </div>

                <div className={ style.user__info_container }>
                    <span className={ style.user__name }>
                        { props.name }
                    </span>
                    <span className={ style.user__info }>
                        { props.info }
                    </span>                
                </div>

                <FaCaretDown className={ style.arrow } />
            </div>

            <div className={ style.user__menu }>
                <ul>
                    <li>Configurações <FaCog /></li>
                    <li>Termo de aceite <FaFile /></li>
                    <li>Personalizar <FaPalette /></li>
                    <li>Trocar empreendimento <FaBuilding /></li>
                    <li>Política de Privacidade <FaFile /></li>
                    <li>Visão desenvolvedor <FaCode /></li>
                    <li>Sair <FaSignOutAlt /></li>
                </ul>
            </div>
        </div>
    );
}
