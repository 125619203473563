import { useState, useEffect } from "react";
import axios from "axios";
import Table from "../../../components/body/table"

export default function QRCode() {


    const [info, setInfo] = useState([]);
    const [loading, setLoading] = useState([])



    //thead
    const thead = [
        { enabled: true, label: "Loja" },
        { enabled: true, label: "Nome" },
        { enabled: true, label: "Leitura" },
        { enabled: true, label: "Ações" },
    ]

    // TITLES EXPORT
    let thead_export = {};
    thead.map((item, i) => {
        if (item?.export !== false) {
            thead_export[item?.name] = item?.label;
        }
    })

    // URL API TABLE
    const url = `https://backend.madnezz.com.br/api/v1/funcionarios`;

    // PARAMS API TABLE
    const params = {
        // loja: lojas,
        // cracha: cracha,
        // inicio: dataInicio,
        // fim: dataFim,
        // nome: nome,
        // cpf: cpf,
        // cargo: cargo,
        // email: email,
        // telefone: telefone
    };

    // BODY DO EXPORTADOR
    const body = {
        titles: thead_export,
        url: url,
        name: 'Funcionários',
        filters: params,
        orientation: 'L'
    }

    //PEGAR INFORMAÇÃO DA API NOVA
    function getInfo() {
        axios({
            url: url,
            params: params,
        }).then((response) => {
            setInfo(response?.data?.data);
            setLoading(false);
        })
    }

    const callback = () => getInfo();

    useEffect(() => { getInfo() }, []);


    return (
        <div>
            <Table
                thead={thead}
            >

            </Table>
        </div>
    )
}