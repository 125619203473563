import { cd } from "../../../_assets/js/global";
import Col from "../../../components/body/col";
import Input from "../../../components/body/form/input";
import Row from "../../../components/body/row";
import Table from "../../../components/body/table";
import Tbody from "../../../components/body/table/tbody";
import Td from "../../../components/body/table/tbody/td";
import Thead from "../../../components/body/table/thead";
import Th from "../../../components/body/table/thead/th";
import Tr from "../../../components/body/table/tr";
import { useState, useEffect } from "react";
import "./style.css";
import VendaInput from "./VendaInput";

export default function Semanal({ mes, lancamento, postLancamento, style, callback, loja }) {

    //CALENDARIO SEMANAL
    const [calendar, setCalendar] = useState([]);
    const [loading, setLoading] = useState([]);

    const today = new Date();

    //ATUALIZAR CALENDÁRIO
    useEffect(() => {
        setLoading(true)
        async function fetchData() {
            if (lancamento) {
                const apiData = lancamento[0];
                const generatedCalendar = await generateCalendar(new Date(), apiData);
                setCalendar(generatedCalendar);
            }
            setLoading(false)
        }

        fetchData();
    }, [lancamento, loja]);

    async function generateCalendar(dia, apiData, atrasado = false) {
        const today = new Date();
        const sevenDaysAgo = new Date(today);
        sevenDaysAgo.setDate(today.getDate() - 7);

        const firstDayOfWeek = new Date(sevenDaysAgo.getFullYear(), sevenDaysAgo.getMonth(), sevenDaysAgo.getDate() - sevenDaysAgo.getDay() + 1);
        const dataArray = [];

        // Adiciona os dias desta semana
        for (let i = 0; i < 7; i++) {
            const currentDay = new Date(firstDayOfWeek);
            currentDay.setDate(firstDayOfWeek.getDate() + i);

            const event = {
                dia: new Date(currentDay.getFullYear(), currentDay.getMonth(), currentDay.getDate(), 7, 0),
                inputValue: 'Digite o Valor',
                venda: null,
                completo: false,
                atrasado: false,
            };

            const formattedDay = currentDay.getDate() < 10 ? `0${currentDay.getDate()}` : currentDay.getDate();

            // Verifica se há dados na API para o dia específico
            const apiVendaKey = `venda_${formattedDay}`;
            if (apiData && apiData.hasOwnProperty(apiVendaKey)) {
                event.venda = apiData[apiVendaKey];
                event.completo = event.venda !== 0;
                // Extrai o valor numérico da string, considerando que a API retorna strings
                // event.valor = event.venda.replace(".", ",").replace(".", ",");
            }

            // Verifica se o dia é anterior ao dia de hoje e não tem venda associada
            if (!event.completo && atrasado && currentDay < today) {
                event.atrasado = true;
            }

            dataArray.push(event);
        }

        return dataArray;
    }

    return (
        <Row>
            <Col>
                <Table>
                    <Thead>

                        <Tr>
                            {/* <Th align="center" >
                                Em Atraso
                            </Th> */}
                            <Th align="center">
                                Semana Passada
                            </Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {
                            calendar.map((item) => {

                                let color = "";
                                if (item.dia < today && (item.venda === 0 || item.venda === null) && !item.completo) {
                                    color = "late-s"
                                } else {
                                    color = ''
                                }            
                                return (
                                    <Tr>
                                        <Td className={color}>
                                            <div>
                                                <span className="span-dia-semana" >{cd(item.dia).slice(0, 2)}</span>
                                                <VendaInput
                                                    venda={item?.venda}
                                                    color={color}
                                                    style={'input-sac-semana'}
                                                    callback={callback}
                                                    loading={loading}
                                                    loja={loja}
                                                    dia={item?.dia}
                                                />
                                            </div>
                                        </Td>
                                    </Tr>
                                )
                            })
                        }
                    </Tbody>
                    <tfoot>
                        <Tr>
                            {/* <Td style={{ borderRight: "1px solid #e8edf1" }}>
                                Total: 0,00
                            </Td> */}
                            <Td>
                                Total: 0,00
                            </Td>
                        </Tr>
                    </tfoot>
                </Table>
            </Col>
        </Row>
    )
}