import { useState, useEffect } from "react";
import Gerenciador from "../../../components/body/gerenciador";
import Form from "../../../components/body/form";


export default function GerenciadorFuncionarios() {


    //ESTADOS
    const [solicitacao, setSolicitacao] = useState();
    const [msgCadastro, setMsgCadastro] = useState();
    const [msgSolicitacao, setMsgSolicitacao] = useState();

    //
    const [periodo, setPeriodo] = useState()

    return (
        <>
            <Gerenciador>
                <Form>

                </Form>
            </Gerenciador>
        </>
    )
}