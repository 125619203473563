import { useState, useEffect } from "react";
import axios from "axios";
import Table from "../../../components/body/table";
import Icon from "../../../components/body/icon";
import Cadastro from "./cadastro";
import Tbody from "../../../components/body/table/tbody";
import Tr from "../../../components/body/table/tr";
import Td from "../../../components/body/table/tbody/td";
import Loader from "../../../components/body/loader";
import { cd } from "../../../_assets/js/global";
import Cracha from "./cracha";
import Funcionario from "./funcionario";
import { toast } from 'react-hot-toast';
import SolicitarCracha from "./solicitarCracha";
import { BsFilePerson } from "react-icons/bs";
import { BsFilePersonFill } from "react-icons/bs";

export default function Lista({ icons, filters }) {


    //ESTADOS
    const [info, setInfo] = useState([]);
    const [loading, setLoading] = useState(true);
    //ESTADOS FILTROS
    const [lojas, setLojas] = useState(null);
    const [nome, setNome] = useState(null);
    const [cpf, setCpf] = useState(null);
    const [cargo, setCargo] = useState(null);
    const [email, setEmail] = useState(null);
    const [telefone, setTelefone] = useState(null);
    const [cracha, setCracha] = useState(null);
    const [dataInicio, setDataInicio] = useState(null);
    const [dataFim, setDataFim] = useState(null);
    //MODAL CADASTRO
    const [show, setShow] = useState(false);
    const [idAux, setIdAux] = useState('');
    //MODAL CRACHA
    const [showCracha, setShowCracha] = useState(false);
    const [idCracha, setIdCracha] = useState('');
    //MODAL INFORMÇÕES FUNCIONARIO
    const [showFuncionario, setShowFuncionario] = useState(false);
    const [idFuncionario, setIdFuncionario] = useState('');
    //MODAL SOLICITAR CRACHÁ
    const [showSolicitar, setShowSolicitar] = useState('');
    const [idSolicitar, setIdSolicitar] = useState('');
    //ABRIR MODAL CADASTRO
    const handleShow = (id) => (
        setShow(true),
        setIdAux(id)
    );
    //FECHAR MODAL CADASTRO
    const handleClose = (res) => setShow(res);

    //ABRIR MODAL CRACHA
    const handleShowCracha = (id) => (
        setShowCracha(true),
        setIdCracha(id)
    );
    //FECHAR MODAL CRACHA
    const handleCloseCracha = (res) => setShowCracha(res);

    //ABRIR MODAL INFORMÇÕES FUNCIONARIO
    const handleShowFuncionario = (id) => (
        setShowFuncionario(true),
        setIdFuncionario(id)
    );
    //FECHAR MODAL INFORMÇÕES FUNCIONARIO
    const handleCloseFuncionario = (res) => setShowFuncionario(res);

    //ABRIR MODAL SOLICITAR CRACHA
    const handleSolicitarCracha = (id) => (
        setShowSolicitar(true),
        setIdSolicitar(id)
    )
    //FECHAR MODAL SOLICITAR CRACHA
    const handleCloseSolicitarCracha = (res) => setShowSolicitar(res);

    const callback_solicitar = () => getInfo();


    // LOJAS
    // NOME
    // CPF
    // CARGO
    // E - MAIL
    // TELEFONE
    // CRACHÁ
    // ADMISSÃO
    // AÇÕES

    const handleEvent = (setState) => (e) => {
        setState(e)
    };
    const handleTarget = (setState) => (e) => {
        setState(e.target.value)
    };
    const handleValue = (setState) => (e) => {
        setState(e.value)
    };

    const thead = [
        { enabled: true, label: "Loja", id: "loja_nome", name: "loja_nome", onChange: handleEvent(setLojas), export: true },
        { enabled: true, label: "Nome", id: "nome", name: "nome", onChange: handleTarget(setNome), export: true },
        { enabled: true, label: "CPF", id: "cpf", name: "cpf", onChange: handleTarget(setCpf), export: true },
        { enabled: true, label: "Cargo", id: "cargo_nome", name: "cargo_nome", export: true },
        { enabled: true, label: "E-mail", id: "email", name: "email", export: true },
        { enabled: true, label: "Telefone", id: "telefone", name: "telefone", export: true },
        { enabled: true, label: "Crachá", id: "cracha_status", name: "cracha_status", export: true },
        { enabled: true, label: "Admissão", id: "admissao", name: "admissao", type: 'date', start: { value: dataInicio, onChange: handleEvent(setDataInicio) }, end: { value: dataFim, onChange: handleEvent(setDataFim) }, export: true },
        { enabled: true, label: "Ações", export: false },
    ];

    // TITLES EXPORT
    let thead_export = {};
    thead.map((item, i) => {
        if (item?.export !== false) {
            thead_export[item?.name] = item?.label;
        }
    })

    // URL API TABLE
    const url = 'https://backend.madnezz.com.br/api/v1/funcionarios';

    // PARAMS API TABLE
    const params = {
        loja: lojas,
        cracha: cracha,
        inicio: dataInicio,
        fim: dataFim,
        nome: nome,
        cpf: cpf,
        cargo: cargo,
        email: email,
        telefone: telefone
    };

    // BODY DO EXPORTADOR
    const body = {
        titles: thead_export,
        url: url,
        name: 'Funcionários',
        filters: params,
        orientation: 'L',
        and: false,
        custom_values:{
            cracha_status:{
                1: 'Pendente',
                2: 'Impresso'
            }
        }        
        // header: "Bearer" + window.token
    }

    //PEGAR INFORMAÇÃO DA API NOVA
    function getInfo() {
        axios({
            url: url,
            params: params,
        }).then((response) => {
            setInfo(response?.data);
            setLoading(false);
        })
    }

    const callback = () => getInfo();

    useEffect(() => { getInfo() }, [lojas, nome, cracha, dataInicio, dataFim, cpf, cargo, email, telefone]);

    //DELETAR FUNCIONARIO
    function deleteEmployee(id) {
        if (window.confirm("Deseja remover?")) {
            axios({
                url: `https://backend.madnezz.com.br/api/v1/funcionarios/${id}`,
                method: "delete"
            }).then(() => {
                toast("Excluído com sucesso");
                getInfo();
            })
        }
    }

    useEffect(() => {
        if (icons) {
            icons(
                <>
                    <Icon type="print" />
                    <Icon type="excel" api={{ body: body }} />
                    <Icon type="new" onClick={() => handleShow('')} />
                </>
            )
        }
    }, [info])

    return (
        <>
            <Cadastro
                show={show}
                onHide={handleClose}
                id={idAux}
                callback={callback}
            />
            <Cracha
                show={showCracha}
                onHide={handleCloseCracha}
                id={idCracha}
                handleSolicitar={handleShow}
            />
            <Funcionario
                show={showFuncionario}
                onHide={handleCloseFuncionario}
                id={idFuncionario}
                handleSolicitar={handleShow}
            />
            <SolicitarCracha
                show={showSolicitar}
                onHide={handleCloseSolicitarCracha}
                id={idSolicitar}
                callback={callback_solicitar}
                handleSolicitar={handleShow}
            />
            <Table
                thead={thead}
            >
                <Tbody>
                    {
                        loading ?
                            <Tr><Td colspan="100%"><Loader show={true} /></Td></Tr>
                            :
                            info && info.map((item, i) => {                             
                                return (
                                    <Tr key={item.id}>
                                        <Td>
                                            {item.loja_nome}
                                        </Td>
                                        <Td>
                                            {item.nome}
                                        </Td>
                                        <Td>
                                            {item.cpf}
                                        </Td>
                                        <Td>
                                            {item.cargo_nome}
                                        </Td>
                                        <Td>
                                            {item.email}
                                        </Td>
                                        <Td>
                                            {item.telefone}
                                        </Td>
                                        <Td>
                                            {item.cracha_status_nome}
                                        </Td>
                                        <Td>
                                            {cd(item.admissao)}
                                        </Td>
                                        <Td>
                                            <BsFilePersonFill
                                                type="card"
                                                title="Solicitar novo crachá"
                                                onClick={() => handleSolicitarCracha(item.id)}
                                            />
                                            {/* <Icon
                                                type="card"
                                                title="Solicitar novo crachá"
                                                onClick={() => handleSolicitarCracha(item.id)}
                                            /> */}
                                            {item.cracha_id &&
                                                <BsFilePerson
                                                    type="card"
                                                    title="Ver crachá"
                                                    onClick={() => handleShowCracha(item.id)}
                                                />
                                                // <Icon
                                                //     type="card"
                                                //     title="Ver crachá"
                                                //     onClick={() => handleShowCracha(item.id)}
                                                // />
                                            }
                                            <Icon
                                                type="view"
                                                title="Visualizar informações"
                                                onClick={() => handleShowFuncionario(item.id)}
                                            />
                                            <Icon
                                                type="edit"
                                                onClick={() => handleShow(item.id)}
                                            />
                                            <Icon
                                                type="reprovar2"
                                                title="Remover funcionário"
                                                onClick={() => deleteEmployee(item.id)}
                                            />
                                        </Td>
                                    </Tr>
                                )
                            })}
                </Tbody>
            </Table>
        </>
    )
}