import style from './button.module.scss';

export default function Button(props) {
    return (
        <button 
            onClick={props.onClick}
            className={style.button + ' ' + (props?.finished ? style.finished : '')}
        >
            {props.children}
        </button>
    )
}