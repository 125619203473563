import { useEffect, useState } from "react";
import Modal from "../../../components/body/modal";
import ModalHeader from "../../../components/body/modal/modalHeader";
import ModalTitle from "../../../components/body/modal/modalHeader/modalTitle";
import Form from "../../../components/body/form";
import Input from "../../../components/body/form/input";
import SelectReact from "../../../components/body/select";
import CheckboxGroup from "../../../components/body/form/checkboxGroup";
import ModalBody from "../../../components/body/modal/modalBody";
import Button from "../../../components/body/button";
import { get_date, cd } from "../../../_assets/js/global";
import axios from "axios";
import Loader from "../../../components/body/loader";

export default function Cadastro({ id, show, onHide, callback }) {

    //FECHAR MODAL
    const handleClose = () => {
        onHide(false);
    }

    //ESTADOS
    const [loading, setLoading] = useState(null);
    const [loja, setLoja] = useState([]);
    const [nome, setNome] = useState(null);
    const [cpf, setCpf] = useState(null);
    const [rg, setRg] = useState(null);
    const [rgExp, setRgExp] = useState(null);
    const [telefone, setTelefone] = useState(null);
    const [celular, setCelular] = useState(null);
    const [email, setEmail] = useState(null);
    const [sexo, setSexo] = useState(null);
    const [nascimento, setNascimento] = useState(null);
    const [nascimentoUf, setNascimentoUf] = useState(null);
    const [estadoCivil, setEstadoCivil] = useState(null);
    const [departamento, setDepartamento] = useState([]);
    const [cargo, setCargo] = useState([]);
    const [admissao, setAdmissao] = useState(null);
    const [foto, setFoto] = useState(null);
    const [cep, setCep] = useState(null);
    const [estado, setEstado] = useState(null);
    const [cidade, setCidade] = useState(null);
    const [bairro, setBairro] = useState(null);
    const [endereco, setEndereco] = useState(null);
    const [numero, setNumero] = useState(null);
    const [complemento, setComplemento] = useState(null);
    const [cracha, setCracha] = useState(null);
    const [biometria, setBiometria] = useState(null);

    //HANDLE LOJA
    const handleSetLoja = (e) => {
        setLoja(e)
    }
    //HANDLE DEPARTAMENTO
    const handleSetDepartamento = (e) => {
        setDepartamento(e)
    }
    //HANDLE CARGO
    const handleSetCargo = (e) => {
        setCargo(e)
    }
    //HANDLE IMAGE
    const handleSetImage = (response) => {
        let files;
        JSON.parse(response[0]).map((file, i) => {
            files = (file.id);
        });
        setFoto(files);
    };

    //PEGAR INFO FUNCIONARIO
    function getInfo() {
        axios({
            url: `https://backend.madnezz.com.br/api/v1/funcionarios/${id}`,
            // params: params,
        }).then((response) => {
            setLoja(response?.data?.loja_id ? [response?.data?.loja_id] : []);
            setNome(response?.data?.pessoa?.nome ? response?.data?.pessoa?.nome : null);
            setCpf(response?.data?.pessoa?.cpf ? response?.data?.pessoa?.cpf : null);
            setRg(response?.data?.pessoa?.rg ? response?.data?.pessoa?.rg : null);
            setTelefone(response?.data?.pessoa?.telefone ? response?.data?.pessoa?.telefone : null);
            setCelular(response?.data?.pessoa?.celular ? response?.data?.pessoa?.celular : null);
            setEmail(response?.data?.pessoa?.email ? response?.data?.pessoa?.email : null);
            setSexo(response?.data?.pessoa?.sexo ? response?.data?.pessoa?.sexo : null);
            setNascimento(response?.data?.pessoa?.nascimento ? new Date(response?.data?.pessoa?.nascimento) : null)
            setAdmissao(response?.data?.admissao ? new Date(response?.data?.admissao) : null);
            setFoto(response?.data?.pessoa?.imagem ? response?.data?.pessoa?.imagem : null);
            setCep(response?.data?.pessoa?.cep ? response?.data?.pessoa?.cep : null)
            setEstadoCivil(response?.data?.pessoa?.civil ? response?.data?.pessoa?.civil : null);
            setDepartamento(response?.data?.departamento?.id ? [response?.data?.departamento?.id] : []);
            setCargo(response?.data?.cargo?.id ? [response?.data?.cargo?.id] : [])
            setEstado(response?.data?.pessoa?.estado ? response?.data?.pessoa?.estado : null);
            setCidade(response?.data?.pessoa?.cidade ? response?.data?.pessoa?.cidade : null)
            setBairro(response?.data?.pessoa?.bairro ? response?.data?.pessoa?.bairro : null);
            setEndereco(response?.data?.pessoa?.logradouro ? response?.data?.pessoa?.logradouro : null);
            setNumero(response?.data?.pessoa?.numero ? response?.data?.pessoa?.numero : null);
            setComplemento(response?.data?.pessoa?.complemento ? response?.data?.pessoa?.complemento : null);
        })
    }

    useEffect(() => {
        if (id) {
            getInfo();
        } else {
            setLoja([]);
            setNome(null);
            setCpf(null);
            setRg(null);
            setRgExp(null);
            setTelefone(null);
            setCelular(null);
            setEmail(null);
            setSexo(null);
            setNascimento(null);
            setNascimentoUf(null);
            setEstadoCivil(null);
            setDepartamento([]);
            setCargo([]);
            setAdmissao(null);
            setFoto(null);
            setCep(null);
            setEstado(null);
            setCidade(null);
            setBairro(null);
            setEndereco(null);
            setNumero(null);
            setComplemento(null);
            setCracha(null);
            setBiometria(null);
        }
    }, [id])


    //OPÇÕES ESTADOS ORDEM ALFABÉTICA
    const options_estados = [
        { id: "AC", value: "Acre", label: "AC" },
        { id: "AL", value: "Alagoas", label: "AL" },
        { id: "AP", value: "Amapá", label: "AP" },
        { id: "AM", value: "Amazonas", label: "AM" },
        { id: "BA", value: "Bahia", label: "BA" },
        { id: "CE", value: "Ceará", label: "CE" },
        { id: "DF", value: "Distrito Federal", label: "DF" },
        { id: "ES", value: "Espírito Santo", label: "ES" },
        { id: "GO", value: "Goiás", label: "GO" },
        { id: "MA", value: "Maranhão", label: "MA" },
        { id: "MT", value: "Mato Grosso", label: "MT" },
        { id: "MS", value: "Mato Grosso do Sul", label: "MS" },
        { id: "MG", value: "Minas Gerais", label: "MG" },
        { id: "PA", value: "Pará", label: "PA" },
        { id: "PB", value: "Paraíba", label: "PB" },
        { id: "PR", value: "Paraná", label: "PR" },
        { id: "PE", value: "Pernambuco", label: "PE" },
        { id: "PI", value: "Piauí", label: "PI" },
        { id: "RJ", value: "Rio de Janeiro", label: "RJ" },
        { id: "RN", value: "Rio Grande do Norte", label: "RN" },
        { id: "RS", value: "Rio Grande do Sul", label: "RS" },
        { id: "RO", value: "Rondônia", label: "RO" },
        { id: "RR", value: "Roraima", label: "RR" },
        { id: "SC", value: "Santa Catarina", label: "SC" },
        { id: "SP", value: "São Paulo", label: "SP" },
        { id: "SE", value: "Sergipe", label: "SE" },
        { id: "TO", value: "Tocantins", label: "TO" }
    ];

    const data = {
        funcionario: id ? id : null,
        loja_id: Number(loja),
        nome: nome,
        cpf: cpf,
        rg: rg,
        rg_expedicao: null,
        telefone: telefone,
        celular: celular,
        email: email,
        sexo: sexo,
        nascimento: get_date('date_sql', cd(nascimento)),
        nascimento_uf: nascimentoUf,
        civil: estadoCivil,
        departamento_id: Number(departamento),
        cargo_id: Number(cargo),
        admissao: get_date('date_sql', cd(admissao)),
        imagem: foto,
        cep: cep,
        estado: estado,
        cidade: cidade,
        bairro: bairro,
        logradouro: endereco,
        numero: numero,
        complemento: complemento,
        solicitar_cracha: cracha == 2 ? 0 : cracha,
        solicitar_biometria: biometria == 2 ? 0 : biometria
    }

    function resetForm() {
        handleClose();
        callback(true);
        setLoja(null);
        setNome(null);
        setCpf(null);
        setRg(null);
        setRgExp(null);
        setTelefone(null);
        setCelular(null);
        setEmail(null);
        setSexo(null);
        setNascimento(null);
        setNascimentoUf(null);
        setEstadoCivil(null);
        setDepartamento(null);
        setCargo(null);
        setAdmissao(null);
        setFoto(null);
        setCep(null);
        setEstado(null);
        setCidade(null);
        setBairro(null);
        setEndereco(null);
        setNumero(null);
        setComplemento(null);
        setCracha(null);
        setBiometria(null);
    }


    return (
        <Modal show={show} onHide={handleClose}>
            <ModalHeader>
                <ModalTitle>
                    {id ? "Editar" : "Novo"} - Funcionário
                </ModalTitle>
            </ModalHeader>
            <ModalBody>
                {
                    loading && id ?
                        <Loader show={true} />
                        :
                        <Form
                            api={`https://backend.madnezz.com.br/api/v1/funcionarios${id ? "/" + id : ""}`}
                            data={data}
                            method={id ? "put" : "post"}
                            callback={resetForm}
                        >
                            <CheckboxGroup
                                group="loja"
                                callback={handleSetLoja}
                                all={false}
                                value={loja}
                                // label={"Loja"}
                                required={false}
                            />
                            <Input
                                name="nome"
                                label="Nome"
                                type="text"
                                value={nome}
                                onChange={(e) => setNome(e.target.value)}
                                loading={loading}
                            />
                            <Input
                                name="cpf"
                                label="CPF"
                                type="text"
                                value={cpf}
                                mask={'999.999.999-99'}
                                onChange={(e) => setCpf(e.target.value)}
                            />
                            <Input
                                name="rg"
                                label="RG"
                                type="text"
                                value={rg}
                                mask={'99.999.999-9'}
                                onChange={(e) => setRg(e.target.value)}
                                loading={loading}
                            />
                            {/* <Input
                        name="rg_exp"
                        label="RG Expedição"
                        type="date"
                        value={rgExp}
                        // mask={'99.999.999-9'}
                        onChange={(e) => setRgExp(e)}
                        loading={loading}
                    /> */}
                            <Input
                                name="telefone"
                                label="Telefone"
                                type="text"
                                value={telefone}
                                mask={'(99) 9999-9999'}
                                onChange={(e) => setTelefone(e.target.value)}
                                loading={loading}
                                required={false}
                            />
                            <Input
                                name="celular"
                                label="Celular"
                                type="text"
                                value={celular}
                                mask={'(99) 9999-99999'}
                                onChange={(e) => setCelular(e.target.value)}
                                loading={loading}
                            // required={false}
                            />
                            <Input
                                name="email"
                                label="E-mail"
                                type="text"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                loading={loading}
                            />
                            <SelectReact
                                name="sexo"
                                label="Sexo"
                                // type="text"
                                value={sexo}
                                onChange={(e) => setSexo(e.value)}
                                options={[{ id: "Masculino", label: "Masculino" }, { id: "Feminino", label: "Feminino" }]}
                                loading={loading}
                            />
                            <Input
                                name="nascimento"
                                label="Nascimento"
                                type="date"
                                value={nascimento}
                                onChange={(e) => setNascimento(e)}
                                loading={loading}
                            />
                            <SelectReact
                                name="nascimento_uf"
                                label="Nascimento UF"
                                value={nascimentoUf}
                                onChange={(e) => setNascimentoUf(e.value)}
                                options={options_estados}
                                loading={loading}
                                required={false}
                            />
                            <SelectReact
                                name="estado_civil"
                                label="Estado Civil"
                                value={estadoCivil}
                                onChange={(e) => setEstadoCivil(e.value)}
                                options={[
                                    { id: "Solteiro", label: "Solteiro" },
                                    { id: "Casado", label: "Casado" },
                                    { id: "Divorciado", label: "Divorciado" },
                                    { id: "Viúvo", label: "Viúvo" },
                                    { id: "Outros", label: "Outros" }
                                ]}
                                required={false}
                                loading={loading}
                            />
                            <CheckboxGroup
                                group="departamento"
                                callback={handleSetDepartamento}
                                required={false}
                                all={false}
                                value={departamento}
                                label={"Departamento"}
                            />
                            <CheckboxGroup
                                group="cargo"
                                callback={handleSetCargo}
                                required={false}
                                all={false}
                                value={cargo}
                                label={"Cargo"}
                            />
                            <Input
                                name="admissao"
                                label="Admissão"
                                type="date"
                                value={admissao}
                                onChange={(e) => setAdmissao(e)}
                                loading={loading}
                            />
                            <Input
                                type="file"
                                name="foto"
                                label="Foto"
                                value={foto}
                                // onChange={(e) => setFoto(e)}
                                loading={loading}
                                callback={handleSetImage}
                            />
                            <Input
                                name="cep"
                                label="CEP"
                                type="text"
                                value={cep}
                                mask={'99999-999'}
                                onChange={(e) => setCep(e.target.value)}
                                // required={false}
                                loading={loading}
                            />
                            <SelectReact
                                name="estado"
                                label="Estado"
                                value={estado}
                                onChange={(e) => setEstado(e.value)}
                                options={options_estados}
                                // required={false}
                                loading={loading}
                            />
                            <Input
                                name="cidade"
                                label="Cidade"
                                type="text"
                                value={cidade}
                                onChange={(e) => setCidade(e.target.value)}
                                // required={false}
                                loading={loading}
                            />
                            <Input
                                name="bairro"
                                label="Bairro"
                                type="text"
                                value={bairro}
                                onChange={(e) => setBairro(e.target.value)}
                                // required={false}
                                loading={loading}
                            />
                            <Input
                                name="endereco"
                                label="Endereço"
                                type="text"
                                value={endereco}
                                onChange={(e) => setEndereco(e.target.value)}
                                // required={false}
                                loading={loading}
                            />
                            <Input
                                name="numero"
                                label="Número"
                                type="text"
                                value={numero}
                                onChange={(e) => setNumero(e.target.value)}
                                // required={false}
                                loading={loading}
                            />
                            <Input
                                name="complemento"
                                label="Complemento"
                                type="text"
                                value={complemento}
                                onChange={(e) => setComplemento(e.target.value)}
                                required={false}
                                loading={loading}
                            />
                            <SelectReact
                                name="cracha"
                                label="Solicitar crachá"
                                value={cracha}
                                onChange={(e) => setCracha(e.value)}
                                options={[{ value: 1, label: "Sim" }, { value: 2, label: "Não" }]}
                                required={false}
                                loading={loading}
                            />

                            <SelectReact
                                name="biometria"
                                label="Solicitar biometria"
                                value={biometria}
                                onChange={(e) => setBiometria(e.value)}
                                options={[{ value: 1, label: "Sim" }, { value: 2, label: "Não" }]}
                                // required={false}
                                loading={loading}
                            />
                            <Button type="submit">Salvar</Button>
                        </Form>
                }
            </ModalBody>
        </Modal>
    )
}