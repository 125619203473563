import { NavLink } from 'react-router-dom';
import style from './Item.module.scss';

export default function Item(props){
    return(
        <li className={ style.item }>
            <NavLink to={ props.link }>
                { props.title }
            </NavLink>
        </li>
    );
}