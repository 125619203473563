import { useState, useEffect } from "react";
import axios from "axios";
import Modal from "../../../components/body/modal";
import ModalHeader from "../../../components/body/modal/modalHeader";
import ModalTitle from "../../../components/body/modal/modalHeader/modalTitle";
import ModalBody from "../../../components/body/modal/modalBody";
import Col from "../../../components/body/col";
import Button from "../../../components/body/button";
import { cd, get_date } from "../../../_assets/js/global";
import "./style.css"
import Table from "../../../components/body/table"
import Tbody from "../../../components/body/table/tbody"
import Td from "../../../components/body/table/tbody/td"
import Tr from "../../../components/body/table/tr"
import "./style.css";

export default function Funcionario({ show, onHide, id, user_name, handleSolicitar }) {

    //FECHAR MODAL
    const handleClose = () => {
        onHide(false);
    }

    const [info, setInfo] = useState('');

    const [loading, setLoading] = useState(true);
    const [imageError, setImageError] = useState(false)

    // PARAMS API TABLE
    const params = {
        // loja: lojas,
        // cracha: cracha,
        // inicio: dataInicio,
        // fim: dataFim,
        // nome: nome,
        // cpf: cpf,
        // cargo: cargo,
        // email: email,
        // telefone: telefone
    };

    //PEGAR INFORMAÇÃO DA API NOVA
    function getInfo() {
        axios({
            url: `https://backend.madnezz.com.br/api/v1/funcionarios/${id}`,
            params: params,
        }).then((response) => {
            setInfo(response?.data);
            setLoading(false);
            // setCracha(response?.data?.cracha);
            // setPessoa(response?.data?.pessoa);
            // setLoja(response?.data?.loja);
        })
    }

    const callback = () => getInfo();

    useEffect(() => { if (id) { getInfo() } }, [id]);

    //MOSTRAR TEXTO SE A IMAGEM TIVER ERRO
    const imageOnError = (event) => {
        setImageError(true)
        event.currentTarget.className = "error";
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <ModalHeader>
                <ModalTitle>
                    Funcionário
                </ModalTitle>
            </ModalHeader>
            <ModalBody>
                <div className="w-100">
                    {
                        info.imagem_url ?

                            imageError ?
                                <div className="image-error">
                                    <span>Funcionário sem foto. <a onClick={() => (handleSolicitar(id), handleClose())} >Editar usuário</a></span>
                                </div>
                                :
                                <img
                                    src={info.imagem_url}
                                    className="img-funcionario w-25 pb-3"
                                    alt={`Foto do funcionário ${info?.pessoa?.nome}`}
                                    onError={imageOnError}
                                />
                            :
                            <div className="image-error">
                                    <span>Funcionário sem foto. <a onClick={() => (handleSolicitar(id), handleClose())} >Editar usuário</a></span>
                                </div>
                    }
                </div>
                <Table>
                    <Tbody>
                        <Tr>
                            <Td>Nome: {info?.pessoa?.nome}</Td>

                        </Tr>
                        <Tr>
                            <Td>E-mail: {info?.pessoa?.email}</Td>
                        </Tr>
                        <Tr>
                            <Td>CPF: {info?.pessoa?.cpf}</Td>
                        </Tr>
                        <Tr>
                            <Td>RG: {info?.pessoa?.rg}</Td>
                        </Tr>
                        <Tr>
                            <Td>Nascimento: {cd(info?.pessoa?.nascimento)}</Td>
                        </Tr>
                        <Tr>
                            <Td>Sexo: {info?.pessoa?.sexo}</Td>
                        </Tr>
                        <Tr>
                            <Td>Estado civil: {info?.pessoa?.civil}</Td>
                        </Tr>
                        <Tr>
                            <Td>Telefone: {info?.pessoa?.telefone}</Td>
                        </Tr>
                        <Tr>
                            <Td>Celular: {info?.pessoa?.celular}</Td>
                        </Tr>
                        <Tr>
                            <Td>Departamento: {info?.departamento?.nome}</Td>
                        </Tr>
                        <Tr>
                            <Td>Cargo: {info?.cargo?.nome}</Td>
                        </Tr>
                        <Tr>
                            <Td>Admissão: {cd(info.admissao)}</Td>
                        </Tr>
                        <Tr>
                            <Td>Bairro: {info?.pessoa?.bairro}</Td>
                        </Tr>
                        <Tr>
                            <Td>Endereço: {info?.pessoa?.logradouro}</Td>
                        </Tr>
                        <Tr>
                            <Td>Cidade: {info?.pessoa?.cidade}</Td>
                        </Tr>
                        <Tr>
                            <Td>Estado: {info?.pessoa?.estado}</Td>
                        </Tr>
                    </Tbody>
                </Table>
            </ModalBody>
        </Modal>
    )
}