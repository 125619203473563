import Title from "../../../components/body/title";
import Row from "../../../components/body/row";
import Col from "../../../components/body/col";
import Dashboard from "../../../components/body/dashboard";
import SelectReact from "../../../components/body/select";
import Table from "../../../components/body/table";
import Thead from "../../../components/body/table/thead";
import Th from "../../../components/body/table/thead/th";
import Tr from "../../../components/body/table/tr";
import Tbody from "../../../components/body/table/tbody";
import Td from "../../../components/body/table/tbody/td";
import Icon from "../../../components/body/icon";


export default function DashboardAuto() {

    //VARIAVEIS DE INFORMAÇÃO DA TABELA
    const ranking_supervisor = [
        {
            supervisor: "Supervisor 1",
            quantidade: 5,
            meta_por_cento: " 20 (100%)",
            nao_por_cento: "80",
            double_check: 2,
            double_check_por_cento: " 10% "
        },
        {
            supervisor: "Supervisor 2",
            quantidade: 5,
            meta_por_cento: " 20 (100%)",
            nao_por_cento: "80",
            double_check: 2,
            double_check_por_cento: " 10% "
        },
        {
            supervisor: "Supervisor 3",
            quantidade: 5,
            meta_por_cento: " 20 (100%)",
            nao_por_cento: "80",
            double_check: 2,
            double_check_por_cento: " 10% "
        },
        {
            supervisor: "Supervisor 4",
            quantidade: 5,
            meta_por_cento: " 20 (100%)",
            nao_por_cento: "80",
            double_check: 2,
            double_check_por_cento: " 10% "
        },
        {
            supervisor: "Supervisor 5",
            quantidade: 5,
            meta_por_cento: " 20 (100%)",
            nao_por_cento: "80",
            double_check: 2,
            double_check_por_cento: " 10% "
        },
        {
            supervisor: "Supervisor 6",
            quantidade: 5,
            meta_por_cento: " 20 (100%)",
            nao_por_cento: "80",
            double_check: 2,
            double_check_por_cento: " 10% "
        },
        {
            supervisor: "Supervisor 7",
            quantidade: 5,
            meta_por_cento: " 20 (100%)",
            nao_por_cento: "80",
            double_check: 2,
            double_check_por_cento: " 10% "
        },
        {
            supervisor: "Supervisor 8",
            quantidade: 5,
            meta_por_cento: " 20 (100%)",
            nao_por_cento: "80",
            double_check: 2,
            double_check_por_cento: " 10% "
        },
        {
            supervisor: "Supervisor 9",
            quantidade: 5,
            meta_por_cento: " 20 (100%)",
            nao_por_cento: "80",
            double_check: 2,
            double_check_por_cento: " 10% "
        }
    ]
    const ranking_loja = [
        {
            loja: "A/J CALZATURE",
            quantidade: 5,
            meta_por_cento: " 20 (100%)",
            nao_por_cento: "80",
            double_check: 2,
            double_check_por_cento: " 10% "
        },
        {
            loja: "FARMA PONTE",
            quantidade: 5,
            meta_por_cento: " 20 (100%)",
            nao_por_cento: "80",
            double_check: 2,
            double_check_por_cento: " 10% "
        },
        {
            loja: "LOJA 23",
            quantidade: 5,
            meta_por_cento: " 20 (100%)",
            nao_por_cento: "80",
            double_check: 2,
            double_check_por_cento: " 10% "
        },
        {
            loja: "A/J CALZATURE",
            quantidade: 5,
            meta_por_cento: " 20 (100%)",
            nao_por_cento: "80",
            double_check: 2,
            double_check_por_cento: " 10% "
        },
        {
            loja: "LOJA TESTE",
            quantidade: 5,
            meta_por_cento: " 20 (100%)",
            nao_por_cento: "80",
            double_check: 2,
            double_check_por_cento: " 10% "
        },
        {
            loja: "LOJA 4",
            quantidade: 5,
            meta_por_cento: " 20 (100%)",
            nao_por_cento: "80",
            double_check: 2,
            double_check_por_cento: " 10% "
        },
        {
            loja: "MALTECH",
            quantidade: 5,
            meta_por_cento: " 20 (100%)",
            nao_por_cento: "80",
            double_check: 2,
            double_check_por_cento: " 10% "
        }
    ]
    const ranking_funcionario = [
        {
            funcionario: "Lojista 1",
            quantidade: 5,
            meta_por_cento: " 0% ",
            nao_por_cento: 0,
            double_check: 2,
            double_check_por_cento: " 10% "
        },
        {
            funcionario: "Lojista 2",
            quantidade: 5,
            meta_por_cento: " 0% ",
            nao_por_cento: 0,
            double_check: 2,
            double_check_por_cento: " 10% "
        },
        {
            funcionario: "Lojista 3",
            quantidade: 5,
            meta_por_cento: " 0% ",
            nao_por_cento: 0,
            double_check: 2,
            double_check_por_cento: " 10% "
        },
        {
            funcionario: "Lojista 4",
            quantidade: 5,
            meta_por_cento: " 0% ",
            nao_por_cento: 0,
            double_check: 2,
            double_check_por_cento: " 10% "
        },
        {
            funcionario: "Lojista 5",
            quantidade: 5,
            meta_por_cento: " 0% ",
            nao_por_cento: 0,
            double_check: 2,
            double_check_por_cento: " 10% "
        },
        {
            funcionario: "Lojista 6",
            quantidade: 5,
            meta_por_cento: " 0% ",
            nao_por_cento: 0,
            double_check: 2,
            double_check_por_cento: " 10% "
        },
        {
            funcionario: "Lojista 7",
            quantidade: 5,
            meta_por_cento: " 0% ",
            nao_por_cento: 0,
            double_check: 2,
            double_check_por_cento: " 10% "
        },
        {
            funcionario: "Lojista 8",
            quantidade: 5,
            meta_por_cento: " 0% ",
            nao_por_cento: 0,
            double_check: 2,
            double_check_por_cento: " 10% "
        },
        {
            funcionario: "Lojista 9",
            quantidade: 5,
            meta_por_cento: " 0% ",
            nao_por_cento: 0,
            double_check: 2,
            double_check_por_cento: " 10% "
        },
        {
            funcionario: "Lojista 10",
            quantidade: 5,
            meta_por_cento: " 0% ",
            nao_por_cento: 0,
            double_check: 2,
            double_check_por_cento: " 10% "
        }
    ]
    return (
        <>
            <Row>
                <Title
                    overflow={true}
                    icon={ <Icon type="pdf"/> }
                    filter={
                        <>
                            <SelectReact
                                value={1}
                                options={[
                                    {
                                        value: 1,
                                        label: "Adesão"
                                    },
                                    {
                                        value: 2,
                                        label: "Pareto"
                                    }
                                ]}
                            />
                            <SelectReact
                                value={1}
                                options={[
                                    {
                                        value: 1,
                                        label: "Todas as linhas"
                                    },
                                    {
                                        value: 2,
                                        label: "25"
                                    },
                                    {
                                        value: 3,
                                        label: "50"
                                    },
                                    {
                                        value: 4,
                                        label: "100"
                                    }
                                ]}
                            />
                            <SelectReact
                                value={1}
                                options={[
                                    {
                                        value: 1,
                                        label: "Marca"
                                    },
                                    {
                                        value: 2,
                                        label: "Adidas"
                                    },
                                    {
                                        value: 3,
                                        label: "Lacoste"
                                    },
                                    {
                                        value: 4,
                                        label: "Nike"
                                    }
                                ]}
                            />
                            <SelectReact
                                value={1}
                                options={[
                                    {
                                        value: 1,
                                        label: "Tipo"
                                    }
                                ]}
                            />
                            <SelectReact
                                value={1}
                                options={[
                                    {
                                        value: 1,
                                        label: "Por mês"
                                    },
                                    {
                                        value: 2,
                                        label: "Por período"
                                    }
                                ]}
                            />
                            <SelectReact
                                value={1}
                                options={[
                                    {
                                        value: 1,
                                        label: "Mês"
                                    },
                                    {
                                        value: 2,
                                        label: "Janeiro"
                                    },
                                    {
                                        value: 2,
                                        label: "Fevereiro"
                                    },
                                    {
                                        value: 4,
                                        label: "Março"
                                    },
                                    {
                                        value: 5,
                                        label: "Abril"
                                    },
                                    {
                                        value: 6,
                                        label: "Maio"
                                    },
                                    {
                                        value: 7,
                                        label: "Junho"
                                    },
                                    {
                                        value: 8,
                                        label: "Julho"
                                    },
                                    {
                                        value: 9,
                                        label: "Agosto"
                                    },
                                    {
                                        value: 10,
                                        label: "Setembro"
                                    },
                                    {
                                        value: 11,
                                        label: "Outubro"
                                    },
                                    {
                                        value: 12,
                                        label: "Novembro"
                                    },
                                    {
                                        value: 13,
                                        label: "Dezembro"
                                    }

                                ]}
                            />
                            <SelectReact
                                value={7}
                                options={[
                                    {
                                        value: 1,
                                        label: "Ano"
                                    },
                                    {
                                        value: 2,
                                        label: "2018"
                                    },
                                    {
                                        value: 3,
                                        label: "2019"
                                    },
                                    {
                                        value: 4,
                                        label: "2020"
                                    },
                                    {
                                        value: 5,
                                        label: "2021"
                                    },
                                    {
                                        value: 6,
                                        label: "2022"
                                    },
                                    {
                                        value: 7,
                                        label: "2023"
                                    }
                                ]}
                            />
                        </>
                    }>
                    Dashboard
                </Title>
                <Col>
                    <Dashboard
                        title="Ranking - Supervisor"
                    >
                        <Table>
                            <Thead>
                                <Tr>
                                    <Th> Supervisor </Th>
                                    <Th> QTD. </Th>
                                    <Th> META %</Th>
                                    <Th> NÃO % </Th>
                                    <Th> DC </Th>
                                    <Th> DC % </Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {ranking_supervisor.map((item) => {
                                    return (
                                        <Tr>
                                            <Td>{item.supervisor}</Td>
                                            <Td>{item.quantidade}</Td>
                                            <Td>{item.meta_por_cento}</Td>
                                            <Td>{item.nao_por_cento}</Td>
                                            <Td>{item.double_check}</Td>
                                            <Td>{item.double_check_por_cento}</Td>
                                        </Tr>
                                    )
                                })}

                            </Tbody>
                        </Table>
                    </Dashboard>

                </Col>
                <Col>
                    <Dashboard
                        title="Ranking - Loja">
                        <Table>
                            <Thead>
                                <Tr>
                                    <Th> Loja </Th>
                                    <Th> QTD. </Th>
                                    <Th> META %</Th>
                                    <Th> NÃO % </Th>
                                    <Th> DC </Th>
                                    <Th> DC % </Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {ranking_loja.map((item) => {
                                    return (
                                        <Tr>
                                            <Td>{item.loja}</Td>
                                            <Td>{item.quantidade}</Td>
                                            <Td>{item.meta_por_cento}</Td>
                                            <Td>{item.nao_por_cento}</Td>
                                            <Td>{item.double_check}</Td>
                                            <Td>{item.double_check_por_cento}</Td>
                                        </Tr>
                                    )
                                })}

                            </Tbody>
                        </Table>
                    </Dashboard>

                </Col>
                <Col>
                    <Dashboard
                        title="Ranking - Funcionário">
                        <Table>
                            <Thead>
                                <Tr>
                                    <Th> Funcionário </Th>
                                    <Th> QTD. </Th>
                                    <Th> META %</Th>
                                    <Th> NÃO % </Th>
                                    <Th> DC </Th>
                                    <Th> DC % </Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {ranking_funcionario.map((item) => {
                                    return (
                                        <Tr>
                                            <Td>{item.funcionario}</Td>
                                            <Td>{item.quantidade}</Td>
                                            <Td>{item.meta_por_cento}</Td>
                                            <Td>{item.nao_por_cento}</Td>
                                            <Td>{item.double_check}</Td>
                                            <Td>{item.double_check_por_cento}</Td>
                                        </Tr>
                                    )
                                })}

                            </Tbody>
                        </Table>
                    </Dashboard>

                </Col>
            </Row>
        </>
    )
}
