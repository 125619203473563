import style from './documentation.module.scss';
import Title from "../../components/body/title";
import Container from './components/Container';
import Sidebar from './components/Sidebar';
import Content from './components/Content';
import Group from './components/Sidebar/Group';
import Link from './components/Sidebar/Link';

// ICONS
import { TbTable } from "react-icons/tb";
import Description from './components/Content/Description';

export default function Documentation(){
    return(
        <Container>
            <Sidebar>
                <Group
                    title="Tabela"
                    icon={<TbTable />}
                >
                    <Link title="Table" link="table" />
                    <Link title="Tr" link="tr" />
                    <Link title="Th" link="tathble" />
                    <Link title="Td" link="td" />
                </Group>
            </Sidebar>
            <Content>
                <Description id="table" title="Table">
                    <table>
                        <thead>
                            <tr>
                                <th>Parâmetro</th>
                                <th>Tipo</th>
                                <th>Padrão</th>
                                <th>Descrição</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>text_limit</td>
                                <td><b>Int</b> ou <b>false</b></td>
                                <td>30</td>
                                <td>Limita o texto de dentro das th's e td's, são aceitos números de 10 a 100, de 10 em 10 (Ex.: 10, 20, 30...).</td>
                            </tr>
                            <tr>
                                <td>api</td>
                                <td><b>string</b></td>
                                <td>' '</td>
                                <td>Ao passar uma URL, o componente realiza a consulta automaticamente com <b>axios</b>.</td>
                            </tr>
                            <tr>
                                <td>reload</td>
                                <td><b>boolean</b></td>
                                <td>false</td>
                                <td>Junto ao parâmetro <b>api</b>, ao passar <b>reload</b> como <b>true</b> a lista é recarregada.</td>
                            </tr>
                            <tr>
                                <td>params</td>
                                <td><b>object</b></td>
                                <td>' '</td>
                                <td>Funciona juntamente com o parâmetro <b>api</b>, é utilizado para passar parametros na consulta como por exemplo, filtros.</td>
                            </tr>
                            <tr>
                                <td>id</td>
                                <td><b>string</b></td>
                                <td>' '</td>
                                <td>Necessário para o correto funcionamento do carregamento via scroll da tabela.</td>
                            </tr>
                            <tr>
                                <td>fixed</td>
                                <td><b>boolean</b></td>
                                <td><b>true</b></td>
                                <td>Utilizado para travar o tamanho da tabela de acordo com o tamanho do monitor (Necessário para o carramento por scroll).</td>
                            </tr>
                            <tr>
                                <td>border</td>
                                <td><b>boolean</b></td>
                                <td><b>true</b></td>
                                <td>Utilizado para remover as bordas da tabela, somente para casos específicos.</td>
                            </tr>
                            <tr>
                                <td>className</td>
                                <td><b>string</b></td>
                                <td>' '</td>
                                <td>Utilizado para incluir classes customizadas no componente.</td>
                            </tr>
                        </tbody>
                    </table>
                </Description>

                <Description id="tr" title="Tr">
                    <table>
                        <thead>
                            <tr>
                                <th>Parâmetro</th>
                                <th>Tipo</th>
                                <th>Padrão</th>
                                <th>Descrição</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>onClick</td>
                                <td><b>function</b></td>
                                <td>' '</td>
                                <td>Função personalizada ao clicar na tr.</td>
                            </tr>
                        </tbody>
                    </table>
                </Description>

                <Description id="td" title="Td">
                    <table>
                        <thead>
                            <tr>
                                <th>Parâmetro</th>
                                <th>Tipo</th>
                                <th>Padrão</th>
                                <th>Descrição</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>onClick</td>
                                <td><b>function</b></td>
                                <td>' '</td>
                                <td>Função personalizada ao clicar na tr.</td>
                            </tr>
                        </tbody>
                    </table>
                </Description>
            </Content>
        </Container>
    )
}