import Tippy from '@tippyjs/react';
import style from './card.module.scss';

export default function Card({title, background, dark, icon, info}){
    return(
        <div className={style.card} style={{backgroundColor: (background ? background : '#10459E')}}>
            <div className={style.container}>
                <div className={style.info + ' ' + (dark === false ? style.light : '')}>
                    <div className={style.title}>
                        {title}
                    </div>

                    {(info && info[0] ? 
                        <div className={style.info1}>
                            <span className={style.number}>
                                {info[0]?.number}
                            </span>
                            <span className={style.label}>
                                {info[0].label}
                            </span>
                        </div>
                    :'')}

                    {(info && info[1] ? 
                        <div className={'d-block d-lg-flex'}>
                            <div className={style.info2}>
                                <span className={style.number + ' ' + (dark === false ? style.light : '')}>
                                    {info[1]?.number}
                                </span>
                                <Tippy content={info[1].label}>
                                    <span className={style.label}>
                                        {info[1].label}
                                    </span>
                                </Tippy>
                            </div>

                            {(info && info[2] ? 
                                <div className={style.info3}>
                                    <span className={style.number + ' ' + (dark === false ? style.light : '')}>
                                        {info[2]?.number}
                                    </span>
                                    <Tippy content={info[2].label}>
                                        <span className={style.label}>
                                            {info[2].label}
                                        </span>
                                    </Tippy>
                                </div>
                            :'')}
                        </div>
                    :'')}
                </div>

                <div className={style.icon + ' ' + (dark === false ? style.light : '')}>
                    {icon}
                </div>
            </div>
        </div>
    )
}
