import { useContext } from 'react';
import { TreinamentoContext } from "../../../context/Treinamento";

import style from './treinamento.module.scss';
import Row from "../../../components/body/row";
import File from "./File";
import { useState } from "react";
import Col from "../../../components/body/col";
import Questionario from "../Questionario";

export default function Treinamento({ curso }) {
    // CONTEXT
    const {view, handleSetView} = useContext(TreinamentoContext);

    // ESTADOS
    const [reload, setReload] = useState(false);

    //VARIAVEL QUE DECIDE O STATUS DO CURSO
    var statusCurso;
    if (curso.file[0].status === 1) {
        statusCurso = "EM ANDAMENTO"
    } else if (curso.file[0].status === 2) {
        statusCurso = "PENDENTE"
    }

    // ABRIR QUESTIONÁRIO
    const handleSetFinish = (e) => {
        if(e){
            handleSetView(2);
        }
    }

    // FUNÇÕES DE CALLBACK DO QUESTIONÁRIO
    const handleCallbackQuiz = (e) => {
        setReload(e);
    }

    return (
        <>
            <Row>
                <Col lg={12}>
                    {(view === 1 ? // ARQUIVOS
                        <div className={style.container}>
                            <File
                                id={curso.file[0].id}
                                reload={reload}
                                images={curso.images}    
                                videos={curso.file[0].link}                                
                                quiz={(curso?.quiz?.questions.length > 0 ? true : false)}
                                callback={handleSetFinish}
                                description={curso.file[0].descricao}
                                anexos={curso.file[0].anexo}
                            /> 
                        </div>
                    : '')}

                    {(view === 2 ? // QUESTIONÁRIO
                        <Questionario
                            quiz={curso.quiz}
                            reload={reload}
                            doAgain={reload}
                            curso={curso.file[0].id}
                            callback={handleCallbackQuiz}
                        />
                    :'')}
                </Col>
            </Row>
        </>
    )
}

//anexo={curso.images[0].anexo}