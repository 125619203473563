import { useState, useEffect } from "react";
import Modal from "../../../components/body/modal";
import ModalHeader from "../../../components/body/modal/modalHeader";
import ModalTitle from "../../../components/body/modal/modalHeader/modalTitle";
import ModalBody from "../../../components/body/modal/modalBody";
import Form from "../../../components/body/form";
import Input from "../../../components/body/form/input";
import SelectReact from "../../../components/body/select";
import axios from "axios";
import Button from "../../../components/body/button";
import Loader from "../../../components/body/loader";
import "./style.css"


export default function SolicitarCracha({ show, onHide, id, callback, handleSolicitar }) {

    //FECHAR MODAL
    const handleClose = () => {
        onHide(false);
    }

    //ESTADOS INPUT
    const [funcionario, setFuncionario] = useState('');
    const [duracao, setDuracao] = useState('');
    const [cpf, setCpf] = useState('');
    const [rg, setRg] = useState('');
    const [foto, setFoto] = useState('');
    const [loading, setLoading] = useState(true);
    //
    const [imageError, setImageError] = useState(false);


    //OPTIONS
    const options_duracao = [
        { value: 15, label: '15' },
        { value: 30, label: '30' },
        { value: 90, label: '90' },
    ]

    //PEGAR INFORMAÇÃO DA API NOVA
    function getInfo() {
        axios({
            url: `https://backend.madnezz.com.br/api/v1/funcionarios/${id}`,
        }).then((response) => {
            setLoading(false);
            setFuncionario(response?.data?.pessoa?.nome);
            setRg(response?.data?.pessoa?.rg);
            setCpf(response?.data?.pessoa?.cpf);
            setFoto(response?.data?.imagem_url);
        })
    }

    useEffect(() => {
        if (id) {
            getInfo();
            setImageError(false)
        }
    }, [id]);


    const data = {
        funcionario: id,
        duracao: duracao,
        cpf: cpf,
        rg: rg,
    }

    function resetForm() {
        handleClose();
        setFuncionario('');
        setDuracao('');
        setCpf('');
        setRg('');
        callback(true)
    }

    //MOSTRAR TEXTO SE A IMAGEM TIVER ERRO
    const imageOnError = (event) => {
        setImageError(true)
        event.currentTarget.className = "error";
    };


    return (
        <Modal show={show} onHide={handleClose}>
            <ModalHeader>
                <ModalTitle>
                    Solicitar Crachá
                </ModalTitle>
            </ModalHeader>
            <ModalBody>
                {
                    loading ?

                        <Loader show={true} />
                        :
                        <>
                            {
                                imageError ?
                                    <div className="image-error">
                                        <span>Funcionário sem foto. <a onClick={() => (handleSolicitar(id), handleClose())} >Editar usuário</a></span>
                                    </div>
                                    :
                                    <div className="w-100 pb-2">
                                        <img
                                            src={foto}
                                            alt={`Foto de ${funcionario}`}
                                            className="img-funcionario w-25 pb-1" 
                                            onError={imageOnError}
                                        />
                                    </div>
                            }

                            <Form
                                api={'https://backend.madnezz.com.br/api/v1/funcionarios/cracha'}
                                method={"post"}
                                data={data}
                                callback={resetForm}
                            >
                                <Input
                                    name="nome"
                                    label="Nome"
                                    type="text"
                                    value={funcionario}
                                    onChange={(e) => setFuncionario(e.target.value)}
                                    readonly={true}
                                />
                                <Input
                                    label="RG"
                                    name="rg"
                                    type="text"
                                    mask={'99.999.999-9'}
                                    value={rg}
                                    onChange={(e) => setRg(e.target.value)}
                                    readonly={true}
                                />
                                <Input
                                    label="CPF"
                                    name="cpf"
                                    type="text"
                                    mask={'999.999.999-99'}
                                    value={cpf}
                                    onChange={(e) => setCpf(e.target.value)}
                                    readonly={true}
                                />
                                <SelectReact
                                    label="Duração"
                                    name="duracao"
                                    value={duracao}
                                    onChange={(e) => setDuracao(e.value)}
                                    options={options_duracao}
                                />
                                <Button type="submit">Enviar</Button>
                            </Form>
                        </>
                }
            </ModalBody>
        </Modal>
    )
}