import "./style.css";
import { useState, useEffect } from "react";
import Picker from 'emoji-picker-react';
import Icon from "../../../icon";

export default function InputEmoji(props) {
    const [chosenEmoji, setChosenEmoji] = useState('');
    const [showPicker, setShowPicker] = useState(false);
    const [inputValue, setInputValue] = useState('');

    // const onEmojiClick = (event, emojiObject) => {
    //     setChosenEmoji(prevInput => prevInput + emojiObject.emoji);
    //     setShowPicker(false);
    // };

    function handleChosenEmoji(object) {
        setChosenEmoji((prevInput) => prevInput + object);
        setShowPicker(false);
        setInputValue(props.value + object);
    }

    useEffect(() => {
        setInputValue(props.value);
    }, [props.value]);



    return (
        <>
            <input
                type={props.type}
                name={props.name}
                placeholder={props.placeholder}
                className="inputStyle"
                value={inputValue}
                onChange={props.onChange}
            />
            <div className="inputDiv">
                {(
                    !showPicker ?
                        <img
                            className="emoji-icon"
                            src="https://icons.getbootstrap.com/assets/icons/emoji-smile.svg"
                            onClick={() => setShowPicker(val => !val)}
                        />
                        :
                        <Icon
                            className="iconEmoji"
                            type="close"
                            onClick={() => setShowPicker(val => !val)}
                        />
                )}

                {showPicker && <Picker
                    emojiStyle="native"
                    previewConfig={
                        {
                            defaultCaption: " ",
                            showPreview: false,
                        }
                    }
                    onEmojiClick={(emojiObject) => handleChosenEmoji(emojiObject.emoji)}
                />}
            </div>
        </>
    )
}