import Modal from 'react-bootstrap/Modal';
import style from './modalBody.module.scss';

export default function ModalBody(props){

    return(
        <>
            <Modal.Body className={ style.modalBody + ' ' + (props.className ? props.className : '')}>
                { props.children }
            </Modal.Body>
        </>
    );
}
