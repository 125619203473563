import { Spinner } from 'react-bootstrap';

export default function Loader({align, className, style}){
    return(
        <div className={'text-'+(align ? align : 'center')+ ' ' + className} style={(style ? style : {})}>
            <Spinner animation="border" role="status" size="sm">
                <span className="visually-hidden">Carregando...</span>
            </Spinner>
        </div>
    )
}
