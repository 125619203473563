import style from './Container.module.scss';

import { useDroppable } from "@dnd-kit/core";
import { SortableContext } from "@dnd-kit/sortable";
import SortableItem from "../sortable";
import ModuleCard from '../../../../components/body/home/moduleCard';

export default function Container(props) {
  const { id, items, label, strategy, large, disabled, dragOver, dragEnd, activeContainer, overContainer } = props;

  const { setNodeRef } = useDroppable({id});

  return (
    <SortableContext
      id={id}
      items={items}
      strategy={strategy}
      disabled={disabled}
    >
        {items.map((item, i) => {
            return (
              <SortableItem
                forwardRef={setNodeRef}
                key={item?.id}
                id={item?.id}
                container={id}
                title={item?.label}
                placeholder={item?.placeholder}
                link={(disabled ? item?.link : null)}
                icon={item?.icon}
                large={large}
                info={item?.info}
                grabCursor={(disabled ? false : true)}
                size={item?.size}
                component={item?.component}
                dragOver={dragOver}
                dragEnd={dragEnd}
                activeContainer={activeContainer}
                overContainer={overContainer}
              />
            )
        })}

        {(!disabled && props?.placeholder !== false && items.length == 0 ? // SE ESTIVER NA TELA DE EDIÇÃO
          <div ref={setNodeRef} className={style.item_disabled}>
            <ModuleCard
              disabled={true}
            />
          </div>
        :'')}
    </SortableContext>
  );
}
