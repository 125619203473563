import { useState, useEffect } from "react";
import Modal from "../../../components/body/modal";
import ModalBody from "../../../components/body/modal/modalBody";
import ModalHeader from "../../../components/body/modal/modalHeader";
import ModalTitle from "../../../components/body/modal/modalHeader/modalTitle";
import Table from "../../../components/body/table";
import Tbody from "../../../components/body/table/tbody";
import Td from "../../../components/body/table/tbody/td";
import Thead from "../../../components/body/table/thead";
import Th from "../../../components/body/table/thead/th";
import Tr from "../../../components/body/table/tr";
import Loader from "../../../components/body/loader";
import axios from "axios";
// import axios from "../axios";
import { cd, cdh } from "../../../_assets/js/global";

export default function Visualizado({ id, show, onHide, callback }) {

    const handleClose = () => {
        onHide(false)
    }

    const [info, setInfo] = useState([]);
    const [loading, setLoading] = useState(true);
    const [lido, setLido] = useState('');

    function getInfo() {
        axios({
            url: `https://backend.madnezz.com.br/api/v1/documentos/${id}`,
        }).then((response) => {
            setInfo(response?.data?.data);
            setLoading(false);
            callback(true);
        })
    }

    useEffect(() => { if (id) { getInfo() } }, [id]);

    return (
        <Modal show={show} onHide={handleClose}>
            <ModalHeader>
                <ModalTitle>
                    Lido - título
                    {/* deveria vir da api */}
                </ModalTitle>
            </ModalHeader>
            <ModalBody>
                <Table>
                    <Thead>
                        <Tr>
                            <Th>Usuário</Th>
                            <Th>Data</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {(
                            loading ?
                                <Tr>
                                    <Td colspan="100%" >
                                        <Loader show={true} />
                                    </Td>
                                </Tr>
                                :
                                info && info?.lido?.map((item, i) => {
                                    return (
                                        <Tr key={item.id}>
                                            <Td>{item.usuario_id}</Td>
                                            <Td>{cdh(item.cad)}</Td>
                                        </Tr>
                                    )

                                })
                        )}
                    </Tbody>
                </Table>
            </ModalBody>
        </Modal>
    )
}