import { useState, useEffect } from "react";
import Row from "../../../components/body/row";
import Table from "../../../components/body/table";
import Tbody from "../../../components/body/table/tbody";
import Tr from "../../../components/body/table/tr";
import Td from "../../../components/body/table/tbody/td";

export default function TabelaMacro() {

    //ESTADOS FILTROS -
    const [tipo, setTipo] = useState(2);
    const [legenda, setLegenda] = useState(1);
    const [loja, setLoja] = useState('');
    const [funcionario, setFuncionario] = useState(1);
    const [avaliacao, setAvaliacao] = useState(1);
    const [periodo, setPeriodo] = useState('');

    const info = [
        { id: 1, funcionario: "Fernanda Costa", loja: "Filial 001", data_adm: "11/06/2023", disciplina: "AE", rel_interpessoal: "AE", zelo: "AE", aprendizagem: "AE", redimento: "AE", compromisso: "AE", nota: "7", resposta_geral: "AE", comentario: " - ", pergunta: "1. Disciplina (Considera a maneira que o avaliado respeita a chefia, acata e cumpre ás ordens que lhe são transmitidas).", resposta: "AE" },
        { id: 2, funcionario: "Gabriela Carvalho", loja: "Filial 001", data_adm: "11/06/2023", disciplina: "AE", rel_interpessoal: "AE", zelo: "AE", aprendizagem: "AE", redimento: "AE", compromisso: "AE", nota: "7", resposta_geral: "AE", comentario: " - ", pergunta: "1. Disciplina (Considera a maneira que o avaliado respeita a chefia, acata e cumpre ás ordens que lhe são transmitidas).", resposta: "AE" },
        { id: 3, funcionario: "Lucas Rodrigues", loja: "Filial 001", data_adm: "11/06/2023", disciplina: "AE", rel_interpessoal: "AE", zelo: "AE", aprendizagem: "AE", redimento: "AE", compromisso: "AE", nota: "7", resposta_geral: "AE", comentario: " - ", pergunta: "1. Disciplina (Considera a maneira que o avaliado respeita a chefia, acata e cumpre ás ordens que lhe são transmitidas).", resposta: "AE" },
        { id: 4, funcionario: "Mariana Silva", loja: "Filial 001", data_adm: "11/06/2023", disciplina: "AE", rel_interpessoal: "AE", zelo: "AE", aprendizagem: "AE", redimento: "AE", compromisso: "AE", nota: "7", resposta_geral: "AE", comentario: " - ", pergunta: "1. Disciplina (Considera a maneira que o avaliado respeita a chefia, acata e cumpre ás ordens que lhe são transmitidas).", resposta: "AE" },
        { id: 5, funcionario: "Pedro Souza", loja: "Filial 001", data_adm: "11/06/2023", disciplina: "AE", rel_interpessoal: "AE", zelo: "AE", aprendizagem: "AE", redimento: "AE", compromisso: "AE", nota: "7", resposta_geral: "AE", comentario: " - ", pergunta: "1. Disciplina (Considera a maneira que o avaliado respeita a chefia, acata e cumpre ás ordens que lhe são transmitidas).", resposta: "AE" },
        { id: 6, funcionario: "Rafael Almeida", loja: "Filial 001", data_adm: "11/06/2023", disciplina: "AE", rel_interpessoal: "AE", zelo: "AE", aprendizagem: "AE", redimento: "AE", compromisso: "AE", nota: "7", resposta_geral: "AE", comentario: " - ", pergunta: "1. Disciplina (Considera a maneira que o avaliado respeita a chefia, acata e cumpre ás ordens que lhe são transmitidas).", resposta: "AE" },
        { id: 7, funcionario: "Sabrina de Oliveira", loja: "Filial 001", data_adm: "11/06/2023", disciplina: "AE", rel_interpessoal: "AE", zelo: "AE", aprendizagem: "AE", redimento: "AE", compromisso: "AE", nota: "7", resposta_geral: "AE", comentario: " - ", pergunta: "1. Disciplina (Considera a maneira que o avaliado respeita a chefia, acata e cumpre ás ordens que lhe são transmitidas).", resposta: "AE" },
        { id: 8, funcionario: "Lucas Alves Ferreira", loja: "Filial 001", data_adm: "11/06/2023", disciplina: "AE", rel_interpessoal: "AE", zelo: "AE", aprendizagem: "AE", redimento: "AE", compromisso: "AE", nota: "7", resposta_geral: "AE", comentario: " - ", pergunta: "1. Disciplina (Considera a maneira que o avaliado respeita a chefia, acata e cumpre ás ordens que lhe são transmitidas).", resposta: "AE" },
        { id: 9, funcionario: "Marina Costa Rocha", loja: "Filial 001", data_adm: "11/06/2023", disciplina: "AE", rel_interpessoal: "AE", zelo: "AE", aprendizagem: "AE", redimento: "AE", compromisso: "AE", nota: "7", resposta_geral: "AE", comentario: " - ", pergunta: "1. Disciplina (Considera a maneira que o avaliado respeita a chefia, acata e cumpre ás ordens que lhe são transmitidas).", resposta: "AE" },
        { id: 10, funcionario: "Rafaela Rodrigues", loja: "Filial 001", data_adm: "11/06/2023", disciplina: "AE", rel_interpessoal: "AE", zelo: "AE", aprendizagem: "AE", redimento: "AE", compromisso: "AE", nota: "7", resposta_geral: "AE", comentario: " - ", pergunta: "1. Disciplina (Considera a maneira que o avaliado respeita a chefia, acata e cumpre ás ordens que lhe são transmitidas).", resposta: "AE" },
        { id: 11, funcionario: "Daniel Moraes Pereira", loja: "Filial 001", data_adm: "11/06/2023", disciplina: "AE", rel_interpessoal: "AE", zelo: "AE", aprendizagem: "AE", redimento: "AE", compromisso: "AE", nota: "7", resposta_geral: "AE", comentario: " - ", pergunta: "1. Disciplina (Considera a maneira que o avaliado respeita a chefia, acata e cumpre ás ordens que lhe são transmitidas).", resposta: "AE" },
        { id: 12, funcionario: "Pedro Gomes", loja: "Filial 001", data_adm: "11/06/2023", disciplina: "AE", rel_interpessoal: "AE", zelo: "AE", aprendizagem: "AE", redimento: "AE", compromisso: "AE", nota: "7", resposta_geral: "AE", comentario: " - ", pergunta: "1. Disciplina (Considera a maneira que o avaliado respeita a chefia, acata e cumpre ás ordens que lhe são transmitidas).", resposta: "AE" },
        { id: 13, funcionario: "Juliana Rodrigues", loja: "Filial 001", data_adm: "11/06/2023", disciplina: "AE", rel_interpessoal: "AE", zelo: "AE", aprendizagem: "AE", redimento: "AE", compromisso: "AE", nota: "7", resposta_geral: "AE", comentario: " - ", pergunta: "1. Disciplina (Considera a maneira que o avaliado respeita a chefia, acata e cumpre ás ordens que lhe são transmitidas).", resposta: "AE" },
        { id: 14, funcionario: "Lucas Ferreira", loja: "Filial 001", data_adm: "11/06/2023", disciplina: "AE", rel_interpessoal: "AE", zelo: "AE", aprendizagem: "AE", redimento: "AE", compromisso: "AE", nota: "7", resposta_geral: "AE", comentario: " - ", pergunta: "1. Disciplina (Considera a maneira que o avaliado respeita a chefia, acata e cumpre ás ordens que lhe são transmitidas).", resposta: "AE" },
        { id: 15, funcionario: "Carlos Eduardo Santos", loja: "Filial 001", data_adm: "11/06/2023", disciplina: "AE", rel_interpessoal: "AE", zelo: "AE", aprendizagem: "AE", redimento: "AE", compromisso: "AE", nota: "7", resposta_geral: "AE", comentario: " - ", pergunta: "1. Disciplina (Considera a maneira que o avaliado respeita a chefia, acata e cumpre ás ordens que lhe são transmitidas).", resposta: "AE" }
    ];


    //OPTIONS SELECT  ---> loja e funcionario usar filtercheckbox
    const options_tipo = [
        { value: 1, label: "Individual" }, { value: 2, label: "Macro" }
    ]

    const options_legenda = [
        { value: 1, label: "EE - Excedeu Expectativas" }, { value: 2, label: "AE - Atingiu Expectativas" },
        { value: 3, label: "PM - Precisa Melhorar" }, { value: 4, label: "Insatisfatorio" }
    ]

    const options_loja = [
        { value: 1, label: "Filial 1" }, { value: 2, label: "Filial 2" }
    ]

    const options_funcionario = [
        { value: 1, label: "Funcionario 1" }, { value: 2, label: "Funcionario 2" }
    ]

    const options_avaliacao = [
        { value: 1, label: "Avaliação" }, { value: 2, label: "Acumulados" }
    ]

    // FILTRA LOJA
    const handleFilterLoja = (selectedVals) => {
        setLoja(selectedVals);
    }

    // FILTRA USUÁRIO
    const handleFilterFuncionario = (selectedVals) => {
        setFuncionario(selectedVals);
    }

    const [count, setCount] = useState(0);
    // ->
    const handleCount = () => {
        console.log('click')
        setCount(count + 1)
    }

    //thead
    const thead = [
        { enabled: true, label: "Loja", name: "loja", id: "loja" },
        { enabled: true, label: "Funcionário", name: "funcionario", id: "funcionario" },
        { enabled: true, label: "Data", name: "data_adm", id: "data_adm" },
        { enabled: true, label: "Disciplina", name: "disciplina", id: "disciplina" },
        { enabled: true, label: "Relacionamento Interpessoal", name: "rel_interpessoal", id: "rel_interpessoal" },
        { enabled: true, label: "Zelo", name: "zelo", id: "zelo" },
        { enabled: true, label: "Aprendizagem", name: "aprendizagem", id: "aprendizagem" },
        { enabled: true, label: "Rendimento no Trabalho", name: "rendimento", id: "rendimento" },
        { enabled: true, label: "Compromisso", name: "compromisso", id: "compromisso" },
    ]



    return (
        <Row>
            <Table thead={thead} >
                <Tbody>
                    {info && info.map((item, i) => {
                        return (
                            <Tr key={item.id}>
                                <Td>{item.loja}</Td>
                                <Td>{item.funcionario}</Td>
                                <Td> {item.data_adm}</Td>
                                <Td>{item.disciplina}</Td>
                                <Td>{item.rel_interpessoal}</Td>
                                <Td>{item.zelo}</Td>
                                <Td>{item.aprendizagem}</Td>
                                <Td>{item.redimento}</Td>
                                <Td>{item.compromisso}</Td>
                            </Tr>
                        )
                    })}
                </Tbody>
            </Table>
        </Row>
    )
}