import { useState } from "react"
import Row from "../../../components/body/row";
import Col from "../../../components/body/col";
import Title from "../../../components/body/title";
import SelectReact from "../../../components/body/select";
import Icon from "../../../components/body/icon";
import Table from "../../../components/body/table";
import Thead from "../../../components/body/table/thead";
import Th from "../../../components/body/table/thead/th"
import Tbody from "../../../components/body/table/tbody";
import Tr from "../../../components/body/table/tr";
import Td from "../../../components/body/table/tbody/td";

export default function Lista() {

    //VARIAVEL COM INFORMAÇÕES DA TABELA
    const informacoes_relatorio = [
        {
            supervisor: "Supervisor 1",
            loja: "Loja 1",
            vendedor: "Vendedor 1",
            tipo: "Tipo 1",
            qtd_avaliacoes: 5,
            meta: 10,
            meta_por_cento: "50%",
            qtd_nao: 5,
            nao_por_cento: "50%"
        },
        {
            supervisor: "Supervisor 1",
            loja: "Loja 1",
            vendedor: "Vendedor 1",
            tipo: "Tipo 1",
            qtd_avaliacoes: 5,
            meta: 10,
            meta_por_cento: "50%",
            qtd_nao: 5,
            nao_por_cento: "50%"
        },
        {
            supervisor: "Supervisor 1",
            loja: "Loja 1",
            vendedor: "Vendedor 1",
            tipo: "Tipo 1",
            qtd_avaliacoes: 5,
            meta: 10,
            meta_por_cento: "50%",
            qtd_nao: 5,
            nao_por_cento: "50%"
        },
        {
            supervisor: "Supervisor 1",
            loja: "Loja 1",
            vendedor: "Vendedor 1",
            tipo: "Tipo 1",
            qtd_avaliacoes: 5,
            meta: 10,
            meta_por_cento: "50%",
            qtd_nao: 5,
            nao_por_cento: "50%"
        },
        {
            supervisor: "Supervisor 1",
            loja: "Loja 1",
            vendedor: "Vendedor 1",
            tipo: "Tipo 1",
            qtd_avaliacoes: 5,
            meta: 10,
            meta_por_cento: "50%",
            qtd_nao: 5,
            nao_por_cento: "50%"
        },
        {
            supervisor: "Supervisor 1",
            loja: "Loja 1",
            vendedor: "Vendedor 1",
            tipo: "Tipo 1",
            qtd_avaliacoes: 5,
            meta: 10,
            meta_por_cento: "50%",
            qtd_nao: 5,
            nao_por_cento: "50%"
        },
        {
            supervisor: "Supervisor 1",
            loja: "Loja 1",
            vendedor: "Vendedor 1",
            tipo: "Tipo 1",
            qtd_avaliacoes: 5,
            meta: 10,
            meta_por_cento: "50%",
            qtd_nao: 5,
            nao_por_cento: "50%"
        },
        {
            supervisor: "Supervisor 1",
            loja: "Loja 1",
            vendedor: "Vendedor 1",
            tipo: "Tipo 1",
            qtd_avaliacoes: 5,
            meta: 10,
            meta_por_cento: "50%",
            qtd_nao: 5,
            nao_por_cento: "50%"
        },
        {
            supervisor: "Supervisor 1",
            loja: "Loja 1",
            vendedor: "Vendedor 1",
            tipo: "Tipo 1",
            qtd_avaliacoes: 5,
            meta: 10,
            meta_por_cento: "50%",
            qtd_nao: 5,
            nao_por_cento: "50%"
        },
        {
            supervisor: "Supervisor 1",
            loja: "Loja 1",
            vendedor: "Vendedor 1",
            tipo: "Tipo 1",
            qtd_avaliacoes: 5,
            meta: 10,
            meta_por_cento: "50%",
            qtd_nao: 5,
            nao_por_cento: "50%"
        },
        {
            supervisor: "Supervisor 1",
            loja: "Loja 1",
            vendedor: "Vendedor 1",
            tipo: "Tipo 1",
            qtd_avaliacoes: 5,
            meta: 10,
            meta_por_cento: "50%",
            qtd_nao: 5,
            nao_por_cento: "50%"
        },
        {
            supervisor: "Supervisor 1",
            loja: "Loja 1",
            vendedor: "Vendedor 1",
            tipo: "Tipo 1",
            qtd_avaliacoes: 5,
            meta: 10,
            meta_por_cento: "50%",
            qtd_nao: 5,
            nao_por_cento: "50%"
        },
        {
            supervisor: "Supervisor 1",
            loja: "Loja 1",
            vendedor: "Vendedor 1",
            tipo: "Tipo 1",
            qtd_avaliacoes: 5,
            meta: 10,
            meta_por_cento: "50%",
            qtd_nao: 5,
            nao_por_cento: "50%"
        }
    ]

    //ESTADOS ITENS DA TABELA
    const [ , setSupervisor] = useState('');
    const [ , setLoja] = useState('');
    const [ , setVendedor] = useState('');
    const [ , setTipo] = useState('');

    //VARIAVEL DE INFORMAÇAO DOS FILTROS
    const todos_os_supervisores = [
        {
            id: 1,
            label: "Eduardo Ashikawa"
        },
        {
            id: 2,
            label: "Gustavo Pontes"
        },
        {
            id: 3,
            label: "Gustavo Teste"
        },
        {
            id: 4,
            label: "Henry Soligueti"
        },
        {
            id: 5,
            label: "Lojista Teste"
        },
        {
            id: 6,
            label: "Malltec"
        },
        {
            id: 7,
            label: "Tathi"
        },
        {
            id: 8,
            label: "Tathiana"
        }
    ]
    const todas_as_lojas = [
        {
            id: 1,
            label: "Filial 1"
        },
        {
            id: 2,
            label: "Filial 2"
        },
        {
            id: 3,
            label: "Filial 3"
        },
        {
            id: 4,
            label: "Filial 4"
        },
        {
            id: 5,
            label: "Loja 23"
        }
    ]
    const todos_os_vendedores = [
        {
            id: 1,
            label: "Lojista 1",
        },
        {
            id: 2,
            label: "Lojista 2"
        }
    ]
    const todos_os_tipos = [
        {
            id: 1,
            label: "Produtos Oferecidos"
        }
    ]

    //FILTRO SUPERVISOR
    const handleCheckSupervisor = (e) =>{
        setSupervisor(e);
    }

    //FILTRO LOJA
    const handleCheckLoja = (e) => {
        setLoja(e);
    }

    //FILTRO VENDEDOR
    const handleCheckVendedor = (e) => {
        setVendedor(e);
    }

    //FILTRO TIPO
    const handleCheckTipo = (e) => {
        setTipo(e);
    }

    return (
        <>
            <Row>
                <Col>
                    <Title
                        overflow={true}
                        icon={
                            <div>
                                <Icon type="pdf" />
                                <Icon type="excel" />
                            </div>
                        }
                        filter={<>
                            <SelectReact
                                value={1}
                                options={[
                                    {
                                        value: 1,
                                        label: "Autoavaliação"
                                    },
                                    {
                                        value: 2,
                                        label: "Acumulado"
                                    }
                                ]} />
                            <SelectReact
                                value={1}
                                options={[
                                    {
                                        value: 1,
                                        label: "Marca"
                                    },
                                    {
                                        value: 2,
                                        label: "Adidas",
                                    },
                                    {
                                        value: 3,
                                        label: "Lacoste"
                                    },
                                    {
                                        value: 4,
                                        label: "Nike"
                                    }
                                ]} />
                            <SelectReact
                                value={1}
                                options={[
                                    {
                                        value: 1,
                                        label: "Região"
                                    },
                                    {
                                        value: 2,
                                        label: "Nenhuma opção"
                                    }
                                ]} />
                            <SelectReact
                                value={1}
                                options={[
                                    {
                                        value: 1,
                                        label: "Por mês"
                                    },
                                    {
                                        value: 2,
                                        label: "Por período"
                                    }
                                ]} />
                            <SelectReact
                                value={1}
                                options={[
                                    {
                                        value: 1,
                                        label: "Janeiro"
                                    },
                                    {
                                        value: 2,
                                        label: "Fevereiro"
                                    },
                                    {
                                        value: 3,
                                        label: "Março"
                                    },
                                    {
                                        value: 4,
                                        label: "Abril"
                                    },
                                    {
                                        value: 5,
                                        label: "Maio"
                                    },
                                    {
                                        value: 6,
                                        label: "Junho"
                                    },
                                    {
                                        value: 7,
                                        label: "Julho"
                                    },
                                    {
                                        value: 8,
                                        label: "Agosto"
                                    },
                                    {
                                        value: 9,
                                        label: "Setembro"
                                    },
                                    {
                                        value: 10,
                                        label: "Outubro"
                                    },
                                    {
                                        value: 11,
                                        label: "Novembro"
                                    },
                                    {
                                        value: 12,
                                        label: "Dezembro"
                                    }
                                ]} />
                            <SelectReact
                                value={6}
                                options={[
                                    {
                                        value: 1,
                                        label: "2018"
                                    },
                                    {
                                        value: 2,
                                        label: "2019"
                                    },
                                    {
                                        value: 3,
                                        label: "2020"
                                    },
                                    {
                                        value: 4,
                                        label: "2021"
                                    },
                                    {
                                        value: 5,
                                        label: "2022"
                                    },
                                    {
                                        value: 6,
                                        label: "2023"
                                    },
                                    {
                                        value: 7,
                                        label: "2024"
                                    }
                                ]} />
                        </>}
                    >
                        Relatório
                    </Title>
                    <Table>
                        <Thead>
                            <Th
                                name="busca_supervisor"
                                items={todos_os_supervisores}
                                onChange={handleCheckSupervisor}
                            >
                                Supervisor
                            </Th>
                            <Th
                                name="busca_loja"
                                items={todas_as_lojas}
                                onChange={handleCheckLoja}                                
                            >
                                Loja
                            </Th>
                            <Th
                                name="busca_vendedor"
                                items={todos_os_vendedores}
                                onChange={handleCheckVendedor}
                            >
                                Vendedor
                            </Th>
                            <Th
                                name='busca_tipo'
                                items={todos_os_tipos}
                                onChange={handleCheckTipo}
                            >
                                Tipo
                            </Th>
                            <Th> QTD. Avaliações </Th>
                            <Th> Meta </Th>
                            <Th> Meta % </Th>
                            <Th> QTD. Não </Th>
                            <Th> Não % </Th>
                        </Thead>
                        <Tbody>
                            {informacoes_relatorio.map((item) => {
                                return (
                                    <Tr>
                                        <Td>{item.supervisor}</Td>
                                        <Td>{item.loja}</Td>
                                        <Td>{item.vendedor}</Td>
                                        <Td>{item.tipo}</Td>
                                        <Td align="center" >{item.qtd_avaliacoes}</Td>
                                        <Td>{item.meta}</Td>
                                        <Td>{item.meta_por_cento}</Td>
                                        <Td>{item.qtd_nao}</Td>
                                        <Td>{item.nao_por_cento}</Td>
                                    </Tr>
                                )
                            })}
                        </Tbody>
                    </Table>
                </Col>
            </Row>
        </>
    )
}
