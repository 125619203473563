import { useEffect, useState } from "react";
import Tipos from "./Tipos";
import { Row } from "react-bootstrap";
import Titulos from "./Titulos";

export default function GerenciadorDocumentos({ filters, icons }) {
    // ESTADOS
    const [tipo, setTipo] = useState('');

    // CALLBACK DO GERENCIADOR DE TIPOS
    const handleCallbackTipo = (e) => {
        setTipo(e.active ? e.active : '');
    }

    // FILTROS
    useEffect(() => {
        if (icons) {
            icons(<></>)
        }
        if (filters) {
            filters(<></>)
        }
    }, []);
    
    return (
        <Row>
            {/* GERENCIADOR DE TIPOS */}
            <Tipos
                callback={handleCallbackTipo}
            />

            {/* GERENCIADOR DE TÍTULOS */}
            {(tipo ?
                <Titulos />
            :'')}
        </Row>
    )
}