import style from './Breadcrumb.module.scss';

export default function Breadcrumb(props){
    return(
        <div
            className={style.breadcrumb}
            onClick={props.onClick}
        >
            <span>{props.children}</span>
        </div>
    )
}