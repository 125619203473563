import { useState, useEffect } from "react";
import Row from "../../../components/body/row";
import Gerenciador from "../../../components/body/gerenciador";
import Table from "../../../components/body/table";
import Tbody from "../../../components/body/table/tbody";
import Tr from "../../../components/body/table/tr";
import Td from "../../../components/body/table/tbody/td";
import Input from "../../../components/body/form/input";
import Icon from "../../../components/body/icon";
import Switch from "../../../components/body/switch";
import NovaAvaliacao from "./cadastroAvaliacao";
import NovaPergunta from "./cadastroPergunta";
import NovoItem from "./cadastroItem";

export default function GerenciadorAvaliacao({ icons, filters }) {

    //AVALIAÇÃO
    const [avaliacao, setAvaliacao] = useState([
        { id: 1, title: "Autoavaliação de desempenho - 15 dias", ativo: 1 },
        { id: 2, title: "Autoavaliação de desempenho - 30 dias", ativo: 1 },
        { id: 3, title: "Autoavaliação de desempenho - 45 dias", ativo: 1 },
        { id: 4, title: "Autoavaliação de desempenho - 60 dias", ativo: 1 },
        { id: 5, title: "Autoavaliação de desempenho - 90 dias", ativo: 1 },
    ]);
    const [inativos, setInativos] = useState('');
    const [search, setSearch] = useState('');
    const [editAvaliacao, setEditAvaliacao] = useState(false);
    const [idAvaliacao, setIdAvaliacao] = useState('');
    const [avaliacaoAux, setAvaliacaoAux] = useState('');

    //PERGUNTAS
    const [pergunta, setPergunta] = useState([
        { id: 1, title: "Pergunta 1", ativo: 1 },
        { id: 2, title: "Pergunta 2", ativo: 1 },
        { id: 3, title: "Pergunta 3", ativo: 1 },
        { id: 4, title: "Pergunta 4", ativo: 1 },
        { id: 5, title: "Pergunta 5", ativo: 1 },
    ]);
    const [perguntaSelected, setPerguntaSelected] = useState('');
    const [inativosPergunta, setInativosPergunta] = useState('');
    const [searchPergunta, setSearchPergunta] = useState('');
    const [editPergunta, setEditPergunta] = useState(false);
    const [idPergunta, setIdPergunta] = useState('');
    const [perguntaAux, setPerguntaAux] = useState('');

    //ITEM
    const [item, setItem] = useState([
        { id: 1, title: "EE", ativo: 1 },
        { id: 2, title: "AE", ativo: 1 },
        { id: 3, title: "PM", ativo: 1 },
        { id: 4, title: "I", ativo: 1 }
    ])
    const [itemSelected, setItemSelected] = useState('')
    const [inativosItem, setInativosItem] = useState('');
    const [searchItem, setSearchItem] = useState('');
    const [editItem, setEditItem] = useState(false);
    const [idItem, setIdItem] = useState('');
    const [itemAux, setItemAux] = useState('');


    //ABRIR EDITOR
    const handleEditAvaliacao = (id, title) => (
        setEditAvaliacao(true),
        setIdAvaliacao(id),
        setAvaliacaoAux(title),
        setPerguntaSelected(''),
        setItemSelected(''),
        setEditPergunta(false),
        setEditItem(false)
    )
    //ABRIR EDITOR
    const handleEditPergunta = (id, title) => (
        setEditPergunta(true),
        setIdPergunta(id),
        setPerguntaAux(title),
        setItemSelected(''),
        setEditItem(false)
    )
    //ABRIR EDITOR
    const handleEditItem = (id, title) => (
        setEditItem(true),
        setIdItem(id),
        setItemAux(title)
    )

    //FECHAR EDITOR
    const callbackCloseAvaliacao = (res) => {
        setEditAvaliacao(res)
    };
    //FECHAR EDITOR
    const callbackClosePergunta = (res) => {
        setEditPergunta(res)
    };
    //FECHAR EDITOR
    const callbackCloseItem = (res) => {
        setEditItem(res)
    };

    useEffect(() => {
        if (icons) {
            icons(
                <>
                    {/* <Icon type="print" />
                    <Icon type="pdf" /> */}
                </>
            )
        }
        if (filters) {
            filters(
                <>
                    {/* <SelectReact
                        options
                        placeholder
                        name
                        value
                        onChange
                    />
                    <SelectReact
                        options
                        placeholder
                        name
                        value
                        onChange
                    />
                    <SelectReact
                        options
                        placeholder
                        name
                        value
                        onChange
                    />
                    <SelectReact
                        options
                        placeholder
                        name
                        value
                        onChange
                    />
                    <SelectReact
                        options
                        placeholder
                        name
                        value
                        onChange
                    />
                    <Input
                    type="date"
                    format="mm/yyyy"
                    // label=""
                    name="filter_date"
                    required={false}
                    value={periodo}
                    onChange={(e) => setPeriodo(e)}
                    /> */}

                </>
            )
        }
    }, [])
    return (
        <Row>
            <Gerenciador
                id="avaliacao"
                title="Avaliação"
                autoScroll={true}
                icon={
                    <>
                        <Icon type="new" onClick={() => handleEditAvaliacao('')} />
                    </>
                }
                search={
                    <Input placeholder="Pesquisar" value={search} onChange={(e) => setSearch(e.target.value)} />
                }
                switch={
                    <Input
                        type="checkbox"
                        id="check_inativos"
                        label="Mostrar Inativos"
                        inverse={true}
                        onChange={(e) => (setInativos(e.target.checked))}
                    />
                }
            >
                <Table fullHeight={true} >
                    <Tbody>
                        {
                            avaliacao && avaliacao.map((item) => {
                                return (
                                    <Tr
                                        key={item.id}
                                        active={perguntaSelected === item.id ? true : false}
                                        cursor="pointer"
                                    >
                                        <Td
                                            title={item.title}
                                            onClick={() => (
                                                setPerguntaSelected(perguntaSelected === item.id ? '' : item.id,
                                                    setEditAvaliacao(false),
                                                    setEditPergunta(false),
                                                    setEditItem(false),
                                                ))}
                                        >
                                            {item.title}</Td>
                                        <Td align="end">
                                            <Icon type="edit" onClick={() => handleEditAvaliacao(item.id, item.title)} />
                                            <Switch
                                                checked={item.ativo == 1 ? true : false}
                                            />
                                        </Td>
                                    </Tr>
                                )
                            })
                        }
                    </Tbody>
                </Table>
            </Gerenciador>
            {
                editAvaliacao ? <NovaAvaliacao id={idAvaliacao} callback_close={callbackCloseAvaliacao} title={avaliacaoAux} /> : <></>
            }
            {
                perguntaSelected &&
                <>
                    <Gerenciador
                        id="perguntas"
                        title="Perguntas"
                        autoScroll={true}
                        icon={
                            <>
                                <Icon type="new" onClick={() => handleEditPergunta('')} />
                            </>
                        }
                        search={
                            <Input placeholder="Pesquisar" value={searchPergunta} onChange={(e) => setSearchPergunta(e.target.value)} />
                        }
                        switch={
                            <Input
                                type="checkbox"
                                id="check_inativos"
                                label="Mostrar Inativos"
                                inverse={true}
                                onChange={(e) => (setInativosPergunta(e.target.checked))}
                            />
                        }
                    >
                        <Table fullHeight={true} >
                            <Tbody>
                                {
                                    pergunta && pergunta.map((item) => {
                                        return (
                                            <Tr
                                                key={item.id}
                                                active={itemSelected === item.id ? true : false}
                                                cursor="pointer"
                                            >
                                                <Td
                                                    title={item.title}
                                                    onClick={() => (
                                                        setItemSelected(itemSelected === item.id ? '' : item.id),
                                                        setEditAvaliacao(false),
                                                        setEditPergunta(false),
                                                        setEditItem(false)
                                                    )}
                                                >
                                                    {item.title}</Td>
                                                <Td align="end">
                                                    <Icon type="edit" onClick={() => handleEditPergunta(item.id, item.title)} />
                                                    <Switch
                                                        checked={item.ativo == 1 ? true : false}
                                                    />
                                                </Td>
                                            </Tr>
                                        )
                                    })
                                }
                            </Tbody>
                        </Table>
                    </Gerenciador>
                    {
                        editPergunta ? <NovaPergunta id={idPergunta} callback_close={callbackClosePergunta} title={perguntaAux} /> : <></>
                    }
                </>
            }
            {
                itemSelected && perguntaSelected &&
                <>
                    <Gerenciador
                        id="item"
                        title="Item"
                        autoScroll={true}
                        icon={
                            <>
                                <Icon type="new" onClick={() => handleEditItem('')} />
                            </>
                        }
                        search={
                            <Input placeholder="Pesquisar" value={searchItem} onChange={(e) => setSearchItem(e.target.value)} />
                        }
                        switch={
                            <Input
                                type="checkbox"
                                id="check_inativos"
                                label="Mostrar Inativos"
                                inverse={true}
                                onChange={(e) => (setInativosItem(e.target.checked))}
                            />
                        }
                    >
                        <Table fullHeight={true} >
                            <Tbody>
                                {
                                    item && item.map((item) => {
                                        return (
                                            <Tr key={item.id}>
                                                <Td title={item.title} >{item.title}</Td>
                                                <Td align="end">
                                                    <Icon type="edit" onClick={() => handleEditItem(item.id, item.title)} />
                                                    <Switch
                                                        checked={item.ativo == 1 ? true : false}
                                                    />
                                                </Td>
                                            </Tr>
                                        )
                                    })
                                }
                            </Tbody>
                        </Table>
                    </Gerenciador>

                    {
                        editItem ? <NovoItem id={idItem} callback_close={callbackCloseItem} title={itemAux} /> : <></>
                    }
                </>
            }
        </Row>
    )
}