import Icon from "../../../../components/body/icon";
import Td from "../../../../components/body/table/tbody/td";
import Tr from "../../../../components/body/table/tr";

export default function Funcionario({ loja_name, vendedor_name, loja, setLoja, vendedor, setVendedor }) {
    return (
        <Tr >
            <Td style={{ paddingLeft: "60px" }}>
                Funcionario
            </Td>
            <Td>
                100
            </Td>
            <Td>
                600
            </Td>
            <Td>
                200
            </Td>
            <Td>
                150
            </Td>
            <Td>
                15 %
            </Td>
            <Td>
                400
            </Td>
            <Td>
                20 %
            </Td>
            <Td>
                100
            </Td>
        </Tr>
    )
}