import { useState } from "react"
import CardJobs from "../../Jobs/Main/Card"

export default function CardsMelhorias() {


    const [cards, setCards] = useState([
        {
            id: 1, title: "Filial 1", group: [{
                id: 1, label: "Control de Temperatura",
                horario: "Manhã", categoria: "XYZ", subcategoria: "xyz", horario: "12:00", frequencia: "Diario"
            },
            {
                id: 2, label: "Control de Temperatura",
                horario: "Tarde", categoria: "XYZ", subcategoria: "xyz", horario: "12:00", frequencia: "Diario"
            },
            {
                id: 3, label: "Control de Temperatura",
                horario: "Noite", categoria: "XYZ", subcategoria: "xyz", horario: "12:00", frequencia: "Diario"
            },]
        },
        {
            id: 2, title: "Filial 2", group: [{
                id: 1, label: "Control de Temperatura",
                horario: "Manhã", categoria: "XYZ", subcategoria: "xyz", horario: "12:00", frequencia: "Diario"
            },
            {
                id: 2, label: "Control de Temperatura",
                horario: "Tarde", categoria: "XYZ", subcategoria: "xyz", horario: "12:00", frequencia: "Diario"
            },
            {
                id: 3, label: "Control de Temperatura",
                horario: "Noite", categoria: "XYZ", subcategoria: "xyz", horario: "12:00", frequencia: "Diario"
            },]
        },
        {
            id: 3, title: "Filial 3", group: [{
                id: 1, label: "Control de Temperatura",
                horario: "Manhã", categoria: "XYZ", subcategoria: "xyz", horario: "12:00", frequencia: "Diario"
            },
            {
                id: 2, label: "Control de Temperatura",
                horario: "Tarde", categoria: "XYZ", subcategoria: "xyz", horario: "12:00", frequencia: "Diario"
            },
            {
                id: 3, label: "Control de Temperatura",
                horario: "Noite", categoria: "XYZ", subcategoria: "xyz", horario: "12:00", frequencia: "Diario"
            },]
        },
    ])




    return (
        <>
        </>
    )
}