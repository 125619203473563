import { useState, } from "react";
import Tr from "../../../components/body/table/tr";
import Td from "../../../components/body/table/tbody/td";
import Icon from "../../../components/body/icon";
import "./style.css"
import ImageCard from "../../../pages/Treinamento/components/productCard/imageCard";
import CardVM from "../../../components/body/vm/mainCardVM";
import BoxImageVM from "../../../components/body/vm/boxImageVM";
import DivBtnVm from "../../../components/body/vm/divBtnVM";
import ButtonVM from "../../../components/body/vm/buttonVM";
// import ModalEditarCampanha from "./modalEditar";
// import ModalListaVM from "./modal";
import { useEffect } from "react";

export default function TableBody(props) {
    
    //nsole.log(getChildInfo)
    
    //MOSTRAR IMAGENS NA TABELA
    const [showImage, setShowImage] = useState(false);

    //ABRIR FOTOS
    const [toggler, setToggler] = useState(false);
    const [img, setImg] = useState();

    //ABRIR A IMAGEM
    function handleClick(e) {
        setImg(e);
        setToggler(!toggler);
        props.image(e)
        props.toggler(e)
    }

    //PROMPT QUE REJEITA FOTOS
    const [promptAnswer, setPromptAnswer] = useState('')
    function handleReprove() {
        setPromptAnswer(prompt("Deseja reprovar essa foto?"))
    }

    //MODAL EDITAR 
    const [campanhaId, setCampanhaId] = useState('');
    //console.log(campanhaId, props.status)

    //MODAL
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(!show);
    const [reload, setReload] = useState('');
    const handleCloseModalLista = (e) => {
        setShow(e);
        setReload(true);
        setTimeout(() => {
            setReload(false)
        }, 500);
    };

    function editShow(id) {
        setCampanhaId(id);
        handleShow();
    }

    //MUDAR A COR DO ICON
    const [aprovado, setAprovado] = useState(true);
    const [desaprovado, setDesaprovado] = useState(false);

    useEffect(() => {
        if (props.item.fotos.length > 0) {
            {
                props.item.fotos.map((foto) => {
                    if (foto.status_foto_supervisor === 0 || foto.status_foto_supervisor == null || foto.status_foto_supervisor == 2) {
                        setAprovado(false)
                    } if (foto.status_foto_supervisor == 2) {
                        setDesaprovado(true)
                    }
                })
            }
        } else {
            setAprovado(false)

        }
    }, []);



    return (
        <>

            <Tr>
                {/* <ModalListaVM show={show} handleShow={handleShow} onHide={handleCloseModalLista} campanhaId={campanhaId} status={props.status} /> */}
                {/* <ModalEditarCampanha show={show} handleShow={handleShow} onHide={handleCloseModalLista} campanhaId={campanhaId} status={props.status} /> */}
                <Td>
                    {props.item.loja}
                    {(props.item.fotos.length > 0 ?
                        <Icon type={(!showImage ? "plus" : "close")}
                            onClick={() => setShowImage(!showImage)} />
                        : " ")}

                </Td>
                <Td>{props.item.campanha}</Td>
                <Td align="center">{props.item.data_campanha}</Td>
                <Td align="center">{props.item.data_execucao}</Td>
                <Td>{props.item.descricao}</Td>
                <Td>{(
                    props.item.fotos.length > 0 ?
                        props.item.fotos.map((foto) => {
                            return (
                                <div>
                                    <Icon type="search" onClick={() => (handleClick(window.host+"/systems/vm/upload/" + foto.imagem))} />
                                </div>
                            )
                        })
                        : " ")}
                </Td>
                <Td align="center">
                    <Icon type="check" className={aprovado ? "text-success" : ""}
                    />
                    <Icon type="close" className={desaprovado ? "text-danger" : "text-secondary"} />
                </Td>
                <Td align="center">
                    <Icon type="edit"
                        onClick={() => props.getChildInfo(props.item.campanha_id, props.item.status)} />
                </Td>
            </Tr>
            {(
                !showImage ? " " :
                    props.item.fotos.length > 0 ?
                        <Tr>
                            <Td colspan="100%">
                                {props.item.fotos.map((foto) => {
                                    return (
                                        <CardVM >
                                            <BoxImageVM onClick={() => (handleClick(window.host+"/systems/vm/upload/" + foto.imagem))}>
                                                <ImageCard image={window.host+"/systems/vm/upload/" + foto.imagem} alt="imagem" />
                                                <Icon type="search" className="searchImageVM" />
                                            </BoxImageVM>
                                            <DivBtnVm>
                                                <ButtonVM type="check" className={
                                                    foto.status_foto_supervisor == 1 ? "text-success" : "text-secondary"
                                                } />
                                                <ButtonVM type="close"
                                                    className={
                                                        foto.status_foto_supervisor == 2 ? "text-danger" : "text-secondary"
                                                    }
                                                    onClick={handleReprove}
                                                />
                                            </DivBtnVm>
                                        </CardVM>
                                    )
                                })}
                            </Td>
                        </Tr>
                        : " "
            )}
        </>
    )
}