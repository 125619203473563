import axios from 'axios';
import { useState, useContext, useEffect } from "react";
import { GlobalContext } from "../../../context/Global";
import Row from '../../../components/body/row';
import Table from '../../../components/body/table';
import Tr from '../../../components/body/table/tr';
import Icon from '../../../components/body/icon';
import Tbody from '../../../components/body/table/tbody';
import Td from '../../../components/body/table/tbody/td';
import toast from "react-hot-toast";
import { cd, cdh, get_date } from '../../../_assets/js/global';
import Cadastro from './Cadastro';
import Visualizado from './visualizado';
import Loader from '../../../components/body/loader';
import Col from '../../../components/body/col';

export default function Lista({ filters, icons }) {

    // CONTEXT
    const { handleSetFilter } = useContext(GlobalContext);

    //ESTADOS
    const [items, setItems] = useState([]);
    const [reload, setReload] = useState(false);

    //ESTADOS FILTROS TABELA
    const [tipo, setTipo] = useState(null);
    const [titulo, setTitulo] = useState(null);
    const [subtitulo, setSubtitulo] = useState(null);
    // const [loja, setLoja] = useState(null);
    const [para, setPara] = useState(null);
    const [dataInicio, setDataInicio] = useState(null);
    const [dataFim, setDataFim] = useState(null);
    const [periodo, setPeriodo] = useState(null);

    //OPÇÕES TIPO
    const options_tipo = [
        {value: 1, label: "Objeto"},{value: 267, label: "Documentos gerais"},{value:268, label: "Controle de pragas"},{value: 269, label:"Análise do ar"},{value: 270, label: "Potabilidade"},
        {value:271, label: "Higienização"},{value: 272, label: "SPDA"}, {value: 273, label: "Lixo hospitalar"}, {value:292, label: "Boleto"}, {value:544, label:"Manuais"}
    ];
    //OPÇÕES TÍTULO
    const options_titulo =  [
        { value: 1,label: "Livros"},{value: 1250,label: "Manual do lojista"},{value: 1251,label: "Manual do portal do lojista"},{value: 1252,label: "Normas gerais"},{value: 1254,label: "Regimento de mídias sociais"},
        {value: 1256,label: "Regimento interno"},{value: 1257,label: "Análise de qualidade do ar"},{value: 1258,label: "Certificado de garantia de controle de pragas"},{value: 1259,label: "Laudo de potabilidade da água"},
        {value: 1260,label: "Limpeza e higienização de reservatórios de água"},{value: 1261,label: "Laudo de SPDA"},{value: 1262,label: "Lixo hospitalar"},{value: 1272,label: "Boleto"},
        {value: 2245,label: "Outros"},{value: 2502,label: "Obra"}
      ]      

    //FUNÇÃO GENERICA FILTRAR POR E
    const handleEvent = (setState) => (e) => {
        setState(e)
    }
    //FUNÇÃO GENERICA FILTRAR POR E
    const handleValue = (setState) => (e) => {
        setState(e.value)
    }
    //FUNÇÃO GENERICA FILTRAR POR TARGET VALUE
    const handleTarget = (setState) => (e) => {
        setState(e.target.value)
    }

    //THEAD
    const thead = [
        { enabled: true, label: "Tipo", id: "tipo", name: "tipo", items: options_tipo, onChange: handleEvent(setTipo), export: true },
        { enabled: true, label: "Título", id: "titulo", name: "titulo", items: options_titulo, onChange: handleEvent(setTitulo), export: true },
        { enabled: true, label: "Subtítulo", id: "subtitulo", name: "subtitulo", onChange: handleTarget(setSubtitulo), export: true },
        { enabled: true, label: "Para", id: "para", name: "para", onChange: handleEvent(setPara), export: true },
        { enabled: true, label: "Cadastro", id: "cadastro", name: "cadastro", type: 'date', start: { value: dataInicio, onChange: handleEvent(setDataInicio) }, end: { value: dataFim, onChange: handleEvent(setDataFim) }, export: true },
        { enabled: true, label: "Período", id: "vigencia", name: "vigencia", type: "date", format: "mm/yyyy", start: { value: periodo, onChange: handleEvent(setPeriodo) }, export: true },
        { enabled: true, label: "Ações", width: 1, align: 'center', export: false }
    ];

    //TITLES EXPORT
    let thead_export = {};
    thead.map((item, i) => {
        if (item?.export !== false) {
            thead_export[item?.name] = item?.label
        }
    });

    //URL API TABLE
    const url = "https://backend.madnezz.com.br/api/v1/documentos";

    //PARAMS API TABLE
    const params = {
        tipo: tipo,
        titulo: titulo,
        subtitulo: subtitulo,
        cad_ini: dataInicio&&(get_date('date_sql', cd(dataInicio))),
        cad_fim: dataFim&&(get_date('date_sql', cd(dataFim))),
        periodo: (periodo ? cd(periodo).split('/')[2]+'-'+cd(periodo).split('/')[1] : undefined),
        para: para
    }

    //BODY
    const body = {
        titles: thead_export,
        url: url,
        name: "Documentos",
        filters: params,
        orientation: "L",
        key: 'data'
    }

    // SETA ITENS VINDOS DO COMPONENT TABLE
    const handleSetItems = (e) => {
        setItems(e);
    }

    //modal cadastro
    const [show, setShow] = useState();
    const [idAux, setIdAux] = useState()
    const handleModal = (id) => { setShow(true); setIdAux(id) }
    const handleClose = () => { setShow(false) }

    //modal visualizado
    const [showViewd, setShowViwed] = useState('');
    const [viewID, setViewID] = useState('');
    const handleViewd = (id) => (
        setViewID(id),
        setShowViwed(true)
    )
    const closeViwed = (res) => setShowViwed(res);

    //FUNÇÃO DE DELETAR TR DOCUMENTO
    const deleteFile = (id) => {
        if (window.confirm('Tem certeza que deseja remover o documento?')) {
            axios({
                url: `https://backend.madnezz.com.br/api/v1/documentos/${id}`,
                method: "delete",
            }).then(() => {
                toast('Documento removido com sucesso!');

                setReload(true);
                setTimeout(() => {
                    setReload(false);
                }, 500);
            });
        }
    };

    // FUNÇÃO PARA MARCAR O DOCUMENTO COMO VISUALIZADO
    function setViwed(id) {
        axios({
            url: `https://backend.madnezz.com.br/api/v1/documentos/${id}`,
            params: params,
        }).then(() => {
            setReload(true);
            setTimeout(() => {
                setReload(false);
            }, 500);
        })
    }

    //Apagar itens repetidos no array
    function resumirTipos(array) {
        const tiposUnicos = new Set(array);
        return [...tiposUnicos];
    }

    // CALLBACK DO CADASTRO
    const handleCallback = () => {
        setReload(true);
        setTimeout(() => {
            setReload(false);
        }, 500);
        setShow(false);
    }

    useEffect(() => {
        if (icons) {
            icons(
                <>
                    <Icon type="excel" api={{ body: body }} />
                    <Icon type="pdf" api={{ body: body }} />

                    {(window.rs_permission_apl === 'master' ?
                        <Icon type="plus" onClick={() => handleModal('')} />
                    :'')}
                </>
            )
        }
        if (filters) {
            filters('')
        }
    }, [items]);

    return (
        <>
            <Cadastro
                show={show}
                onHide={handleClose}
                id={idAux}
                callback={handleCallback}
            />

            <Visualizado
                id={viewID}
                show={showViewd}
                onHide={closeViwed}
            />

            <Row>
                <Col lg={'auto'}>            
                    <Table
                        text_limit={false}
                        id="documentos"
                        thead={thead}
                        api={url}
                        params={params}
                        pages={true}
                        key_aux_2={'data'}
                        onLoad={handleSetItems}
                        reload={reload}
                    >
                        <Tbody>
                            {(items.length > 0 ?                                
                                items.map((item) => {
                                    let periodo_aux = item?.vigencia.split('-');                               
                                    return (
                                        <Tr key={item?.id} >
                                            <Td>
                                                {item?.tipo}
                                            </Td>
                                            <Td>
                                                {item?.titulo ? item?.titulo : item?.subtipo}
                                            </Td>
                                            <Td>
                                                {item?.subitutlo}
                                            </Td>
                                            <Td>
                                                {resumirTipos(item?.para?.map(({ tipo }) => tipo)).join(', ')}
                                            </Td>
                                            <Td>
                                                {cd(item?.cadastro)}
                                            </Td>
                                            <Td>                                     
                                                {`${periodo_aux[1]}/${periodo_aux[0]}`}
                                            </Td>
                                            <Td width={1} align="center">
                                                {item?.arquivo &&
                                                    <a href={item?.arquivo} rel="noreferrer" target="_blank" >
                                                        <Icon type="view" onClick={() => setViwed(item.id)} animated />
                                                    </a>}

                                                {(window.rs_permission_apl === 'master' ?
                                                    <Icon type="edit" onClick={() => handleModal(item?.id)} animated />
                                                :'')}

                                                {(window.rs_permission_apl === 'master' ?
                                                    <Icon type="delete" onClick={() => deleteFile(item?.id)} animated />
                                                :'')}

                                                <Icon
                                                    type={item.lido.filter((elem) => elem.usuario_id == window.rs_id_usr).length > 0 ? 'mail-open' : 'mail-close'}
                                                    className={item.lido.filter((elem) => elem.usuario_id == window.rs_id_usr).length > 0  ? 'text-success' : ''}
                                                    onClick={() => handleViewd(item?.id)}
                                                    title={item.lido.filter((elem) => elem.usuario_id == window.rs_id_usr).length > 0 ? 'Visualizado' : 'Não visualizado'}
                                                />
                                            </Td>
                                        </Tr>
                                    )
                                })
                            :<></>)}
                        </Tbody>
                    </Table>
                </Col>
            </Row>
        </>
    )
};