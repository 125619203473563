import Icon from "../../../components/body/icon";
import Modal from "../../../components/body/modal";

export default function ImageModal({ show, handleShow, img, nome }) {
    return (
        <Modal show={show} restoreFocus={true} >
            <img className="modalNewsImage" src={img ? process.env.REACT_APP_URL_UPLOAD+"/" + img : window.host+"/systems/news-react/"} alt={nome} />
            <Icon type="close" onClick={handleShow} />
        </Modal>
    )
}