import { useState, useEffect, useContext } from "react";
import Row from "../../../components/body/row";
import Table from "../../../components/body/table";
import Tr from "../../../components/body/table/tr";
import Tbody from "../../../components/body/table/tbody";
import Td from "../../../components/body/table/tbody/td";
import Icon from "../../../components/body/icon";
import DetalheCliente from "./detalhe";
import { cd } from "../../../_assets/js/global";
import ModalSac from "./modal";
import { GlobalContext } from "../../../context/Global";


export default function Lista({ icons, filters }) {
    // GLOBAL CONTEXT
    const { handleSetFilter } = useContext(GlobalContext);

    // HABILITA O CARREGAMENTO INICIAL DA LISTA
    useEffect(() => {
        handleSetFilter(true);
    }, []);

    //ESTADOS
    const [clientes, setClientes] = useState([]);

    //ABRIR SUB-TROW DE ACORDO COM ID
    const [clienteId, setClienteId] = useState('');

    //FILTROS 
    const [monthSelected, setMonthSelected] = useState(window.currentMonth);
    const [yearSelected, setYearSelected] = useState(window.currentYear);
    const [filterMonth, setFilterMonth] = useState(new Date(window.currentYear, window.currentMonth - 1, '01'));
    
    //FILTROS TABLE
    const [dataInicio, setDataInicio] = useState('');
    const [dataFim, setDataFim] = useState('');
    const [cliente, setCliente] = useState('');
    const [cpf, setCpf] = useState('')
    const [rg, setRg] = useState('');
    const [protocolo, setProtocolo] = useState('');
    const [contato, setContato] = useState('');
    const [assunto, setAssunto] = useState('');
    const [operador, setOperador] = useState('');
    const [motivo, setMotivo] = useState('');
    const [departamento, setDepartamento] = useState('');
    const [status, setStatus] = useState(['Andamento', 'Pendente']);


    //MODAL
    const [show, setShow] = useState(false);
    //ABRIR MODAL
    const handleShow = () => { setShow(true) };

    //FECHAR MODAL
    const handleClose = (e) => {
        setShow(e);
        // getInfo();
    };

    //MASK CPF PRA PESQUISAR NA API
    const cpfMask = (value) => {
        return value
            .replace(/\D/g, '')
            .replace(/(\d{3})(\d)/, '$1.$2')
            .replace(/(\d{3})(\d)/, '$1.$2')
            .replace(/(\d{3})(\d{1,2})/, '$1-$2')
            .replace(/(-\d{2})\d+?$/, '$1')
    }

    // contato , departamento, status
    //OPÇÕES MOTIVO PARA A THEAD
    const opt_motivo = [
        { value: "0", label: "" },
        { value: 3730, label: "Elogio" },
        { value: 3731, label: "Reclamação" },
        { value: 3732, label: "Sugestão" }
    ]
    //OPÇÕES ASSUNTO PARA A THEAD
    const opt_assunto = [
        { value: "0", label: "" },
        { value: 3738, label: "Acidente" },
        { value: 3739, label: "Ar Condicionado" },
        { value: 3740, label: "Atendimento" },
        { value: 3741, label: "Balcão" },
        { value: 3742, label: "Bebedouro" },
        { value: 3743, label: "Comunicação" },
        { value: 3744, label: "Controle de Pragas" },
        { value: 3745, label: "Criança Perdida" },
        { value: 3746, label: "Dano Físico" },
        { value: 3747, label: "Dano Material" },
        { value: 3748, label: "Elevador" },
        { value: 3749, label: "Entradas e Saídas" },
        { value: 3750, label: "Limpeza" },
        { value: 3751, label: "Outros" },
        { value: 3752, label: "Pronto Atendimento" },
        { value: 3753, label: "Reforma" },
        { value: 3754, label: "Segurança" },
        { value: 3755, label: "Vagas Sinalização" },
        { value: 3756, label: "Vagas" },
        { value: 3757, label: "Vagas Especiais" },
    ]
    //OPÇÕES STATUS PARA A THEAD
    const opt_status = [
        { value: 'Pendente', label: "Pendente" }, { value: "Andamento", label: "Em andamento" }, { value: "Finalizado", label: "Finalizado" }
    ]
    //OPÇÕES DEPARTAMENTO PARA A THEAD
    const opt_departamento = [
        { value: 1488, label: "Brigada de Incêndio" },
        { value: 1491, label: "Estacionamento" },
        { value: 1567, label: "Limpeza" },
        { value: 1626, label: "Marketing" },
        { value: 1380, label: "Operações" },
        { value: 1236, label: "Segurança" },
    ]
    //OPÇÕES CONTATO PARA A THEAD
    const opt_contato = [
        { value: 3724, label: "E-mail(SAC)" },
        { value: 3725, label: "Facebook" },
        { value: 3726, label: "Pessoalmente" },
        { value: 3727, label: "Reclame Aqui" },
        { value: 3728, label: "Telefone" },
        { value: 3729, label: "Twitter" }
    ]

    //FILTRO DATA INICIO
    const handleSetInicio = (e) => { setDataInicio(e) };
    //FILTRO DATA FIM
    const handleSetFim = (e) => { setDataFim(e) };
    //FILTRO CLIENTE
    const handleCliente = (e) => { setCliente(e.target.value) };
    //FILTRO CPF
    const handleCpf = (e) => { setCpf(cpfMask(e.target.value)) };
    //FILTRO RG
    const handleRg = (e) => { setRg(e.target.value) };
    //FILTRO PROTOCOLO
    const handleProtocolo = (e) => { setProtocolo(e.target.value) };
    //FILTRO CONTATO
    const handleContato = (e) => { setContato(e) };
    //FILTRO ASSUNTO
    const handleSetAssunto = (e) => { setAssunto(e) };
    //FILTRO OPERADOR
    const handleOperador = (e) => { setOperador(e.target.value) };
    //FILTRO MOTIVO
    const handleSetMotivo = (e) => { setMotivo(e) };
    //FILTRO DEPARTAMENTO
    const handleDepartamento = (e) => { setDepartamento(e) };
    //FILTRO STATUS
    const handleStatus = (e) => { setStatus(e) };

    // const handleEvent = (setState) => (e) => {
    //     setState(e)
    // }
    // const handleTarget = (setState) => (e.target.value) => {
    //     setState(e)
    // }

    //MASK RG
    const rgMask = (value) => {
        value = value.replace(/\D/g, "");
        const formattedValue = value.replace(/(\d{2})(\d{3})(\d{3})(\d{0,1})/, "$1.$2.$3-$4");
        return formattedValue.trim(); // Remover espaços em branco no final (se houver)
    };

    // CONSTRÓI AS TH'S
    const thead = [
        { enabled: true, label: 'Data', type: 'date', id: 'cad', name: 'cad', mask: '999999999', start: { value: dataInicio, onChange: handleSetInicio }, end: { value: dataFim, onChange: handleSetFim }, export: true },
        { enabled: true, label: 'Cliente', id: 'cliente', name: 'cliente', onChange: handleCliente, export: true },
        { enabled: true, label: 'CPF', id: 'cpf', name: 'cpf', onChange: handleCpf, export: true },
        { enabled: true, label: 'RG', id: 'rg', name: 'rg', onChange: handleRg, export: true },
        { enabled: true, label: 'Protocolo', id: 'protocolo', name: 'protocolo', onChange: handleProtocolo, export: true },
        { enabled: true, label: 'Contato', id: 'contato', name: 'contato', items: opt_contato, onChange: handleContato, export: true },
        { enabled: true, label: 'Assunto', id: 'assunto', name: 'assunto', items: opt_assunto, onChange: handleSetAssunto, export: true },
        { enabled: true, label: 'Operador', id: 'operador', name: 'operador', onChange: handleOperador, export: true },
        { enabled: true, label: 'Motivo', id: 'motivo', name: 'motivo', items: opt_motivo, onChange: handleSetMotivo, export: true },
        { enabled: true, label: 'Departamento', id: 'departamento', name: 'departamento', items: opt_departamento, onChange: handleDepartamento, export: true },
        { enabled: true, label: 'Status', id: 'status', name: 'status', items: opt_status, filtered: ['Andamento', 'Pendente'], onChange: handleStatus, export: true },
        { enabled: true, label: 'Ações', id: 'acoes', align: 'center', export: false },
    ]

    // TITLES EXPORT
    let thead_export = {};
    thead.map((item, i) => {
        if (item?.export !== false) {
            thead_export[item?.name] = item?.label;
        }
    });

    // URL API TABLE
    const url = window.host + "/systems/sac/api/sac.php?do=sacRelatorio";

    //PARAMS
    const params = {
        data_inicio: dataInicio && cd(dataInicio),
        data_final: dataFim && cd(dataFim),
        nome: cliente,
        cpf: cpf,
        rg: rg,
        protocolo: protocolo,
        contato: contato,
        assunto: assunto,
        operador: operador,
        motivo: motivo,
        departamento: departamento,
        status: status,
        periodo: `${monthSelected}/${yearSelected}`
    }

    const body = {
        titles: thead_export,
        url: url,
        name: "Sac",
        filters: params,
        orientation: 'L',
    }

    const handleMes = (e) => {
        setFilterMonth(e);
        let arr_aux = cd(e).split("/");
        setMonthSelected(arr_aux[1]);
        setYearSelected(arr_aux[2]);
    }

    const handleSetItems = (e) => {
        setClientes(e);
        setClienteId('');
    }

    const callback_lista = ''

    useEffect(() => {
        if (icons) {
            icons(
                <>
                    <Icon type="excel" api={{ body: body }} />
                    <Icon type="pdf" api={{ body: body }} />
                    <Icon type="new" onClick={handleShow} />
                </>
            )
            if (filters) {
                filters(
                    <></>
                )
            }
        }
    }, [status, filterMonth, clientes]);
    
    return (
        <Row>
            <ModalSac
                show={show}
                onHide={handleClose}
                id_sac={''}
            />
            <Table
                id="sac"
                api={url}
                params={params}
                rightFixed={true}
                onLoad={handleSetItems}
                thead={thead}
            >
                <Tbody>
                    {(
                        clientes &&
                        clientes.map((item, i) => {

                            let cpf_aux

                            if (item?.cpf == 0 || item?.cpf == false || item?.cpf.includes("false")) {
                                cpf_aux = ' - '
                            } else {
                                cpf_aux = item?.cpf
                            }

                            let cliente_aux

                            if (item?.cliente == "" && item?.cliente_tipo && item?.cliente_tipo.includes("Anônimo")) {
                                cliente_aux = "Anônimo"
                            } else {
                                cliente_aux = item?.cliente
                            }

                            return (
                                <>
                                    <Tr key={item.id} disabled={clienteId && clienteId !== item.id} >
                                        <Td>{cd(item.cad)}</Td>
                                        <Td>{cliente_aux}</Td>
                                        <Td>{cpf_aux}</Td>
                                        <Td>{item.rg && rgMask(item.rg)}</Td>
                                        <Td>{item.protocolo}</Td>
                                        <Td>{item.contato}</Td>
                                        <Td>{item.assunto}</Td>
                                        <Td>{item.operador}</Td>
                                        <Td>{item.motivo}</Td>
                                        <Td>{item.departamento}</Td>
                                        <Td boxed={{ background: 'secondary', width: 'auto' }}>
                                            {item.status}
                                        </Td>
                                        <Td align="center">
                                            <Icon type="view"
                                                onClick={() => setClienteId(clienteId == item.id ? '' : item.id)}
                                                title="Visualizar"
                                            />
                                        </Td>
                                    </Tr>
                                    {clienteId == item.id &&
                                        <DetalheCliente
                                            andamento={item?.andamento}
                                            anexo={item?.anexo_opcional}
                                            assunto={item?.assunto}
                                            ativo={item?.ativo}
                                            cad={item?.cad}
                                            check_departamento={item?.departamento}
                                            cliente_tipo={item?.cliente}
                                            cliente_id={item?.cliente_id}
                                            conclusao={item?.conclusao}
                                            contato={item?.contato}
                                            cpf={cpf_aux}
                                            data_abertura={item?.data_abertura}
                                            data_nascimento={item?.data_nascimento}
                                            data_ocorrencia={item?.data_ocorrencia}
                                            departamento={item?.departamento}
                                            descricao={item?.descricao}
                                            email={item?.email}
                                            email_atualizacao={item?.email_atualizacao}
                                            empreendimento_id={item?.empreendimento_id}
                                            id={item.id}
                                            loja_id={item?.loja_id}
                                            motivo={item?.motivo}
                                            ocorrencia_id={item?.ocorrencia_id}
                                            prioridade={item?.priodidade}
                                            protocolo={item?.protocolo}
                                            rg={item.rg && rgMask(item.rg)}
                                            status={item?.status}
                                            callback_lista={callback_lista}
                                        />
                                    }
                                </>
                            )
                        })
                    )}
                </Tbody>
            </Table>
        </Row>
    )
}