import { useState } from "react";

import Icon from "../../../../../components/body/icon";
import Th from "../../../../../components/body/table/thead/th";
import Tr from "../../../../../components/body/table/tr";
import Subitems from "../subitems";

export default function Items(props){
    // ESTADOS
    const [collapse, setCollapse] = useState(false);

    return(
        <>
            <Tr
                onClick={() => setCollapse(!collapse)}
                cursor="pointer"
            >
                <Th title={props.nome}>
                    {props.nome}
                </Th>
                <Th width={1} align="center">
                    <Icon
                        type={(collapse?'collapseOut':'collapseIn')}                        
                    />
                </Th>
            </Tr>
            {(collapse?
                <Subitems id={props.id} />
            :'')}
        </>
    )
}