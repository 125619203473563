import { useState, useContext } from "react";
import style from './item.module.scss';

import Tr from "../../../../components/body/table/tr";
import Td from "../../../../components/body/table/tbody/td";
import Icon from "../../../../components/body/icon";
import { GlobalContext } from "../../../../context/Global";

export default function Item({loja}){
    // CONTEXT
    const { handleSetSources, handleSetToggler } = useContext(GlobalContext);

    // VISUALIZAR IMAGEM
    function view_image(id){
        handleSetToggler(true);
        handleSetSources([process.env.REACT_APP_URL_UPLOAD+'/'+id], 0);
    }
    
    return(
        <Tr>
            <Td disableView={false}>{loja.nome_emp}</Td>
            
            <Td disableView={false}>{loja.nome}</Td>

            {/* <Td>{loja.cnpj ? loja.cnpj : '-'}</Td> */}

            {/* <Td>{loja.contrato ? loja.contrato : '-'}</Td> */}

            {/* <Td>{loja.luc ? loja.luc : '-'}</Td> */}

            {/* <Td>{loja.localizacao ? loja.localizacao : '-'}</Td> */}

            <Td disableView={false}>{loja.email ? loja.email : '-'}</Td>

            {/* <Td>{loja.telefone ? loja.telefone : '-'}</Td> */}

            {/* <Td width={1} align="center">
                <Icon
                    type="view"
                    title={(loja.logo_lja ? 'Visualizar' : 'Não cadastrado')}
                    disabled={(loja.logo_lja ? false : true)}
                    onClick={() => view_image(loja.logo_lja)}
                    animated
                />
            </Td> */}

            <Td width={1} align="center" className="hide_print">
                <Icon
                    type={'check'}
                    animated={true}
                    title={loja.id_att_log ? 'Dados confirmados' : 'Dados não confirmados'}
                    readonly={true}
                    className={loja.id_att_log ? 'text-success' : ''}          
                />
            </Td>
        </Tr>
    )
}
