import { useState } from "react";
import Row from "../../../components/body/row";
import Table from "../../../components/body/table";
import Tbody from "../../../components/body/table/tbody";
import Td from "../../../components/body/table/tbody/td";
import Thead from "../../../components/body/table/thead";
import Th from "../../../components/body/table/thead/th";
import Tr from "../../../components/body/table/tr";
import Tippy from "@tippyjs/react";
// import Chart from "../../../components/body/chart";
import Col from "../../../components/body/col";
import Icon from "../../../components/body/icon";
import { Chart } from "react-google-charts";

import axios from '../lib/axios'
import { useEffect } from "react";


export default function Lista() {

    const [apiInfo, setApiInfo] = useState([
        { id: 1, loja: "Todos os jobs tem tutorial? ", status: "Reabrir", dia: " ? " },
        { id: 2, loja: "Enviou relatório? ", status: "Reabrir", dia: " ? " },
        { id: 3, loja: "Todos os jobs tem tutorial? ", status: "Reabrir", dia: " ? " },
        { id: 4, loja: "Enviou relatório? ", status: "Reabrir", dia: " ? " },
        { id: 5, loja: "Todos os jobs tem tutorial? ", status: "Reabrir", dia: " ? " },
        { id: 6, loja: "Enviou relatório? ", status: "Reabrir", dia: " ? " },
        { id: 7, loja: "Todos os jobs tem tutorial? ", status: "Reabrir", dia: " ? " },
    ]);
    const [loja, setLoja] = useState(10567);
    const [mes, setMes] = useState('');
    const [ano, setAno] = useState('');
    const [lancamento, setLancamento] = useState('');

    //CRIAR UM ARRAY DE DATAS, DE ACORDO COM A DATA ATUAL
    const today = new Date();
    const dateArray = [];
    // Função para formatar a data
    const formatDate = (date) => {
        const day = date.getDate();
        const month = date.getMonth() + 1;
        return `${day < 10 ? '0' : ''}${day}/${month < 10 ? '0' : ''}${month}`;
    };
    // Adiciona 6 dias antes da data de hoje
    for (let i = 6; i > 0; i--) {
        const prevDate = new Date(today);
        prevDate.setDate(today.getDate() - i);
        dateArray.push({ id: 7 - i, dia: formatDate(prevDate) });
    }
    // Adiciona a data de hoje
    dateArray.push({ id: 7, dia: formatDate(today) });
    // Adiciona 7 dias após a data de hoje, considerando mudanças de mês e ano
    for (let i = 1; i <= 7; i++) {
        const nextDate = new Date(today);
        nextDate.setDate(today.getDate() + i);
        if (nextDate.getMonth() !== today.getMonth()) {
            // Mudança de mês
            dateArray.push({ id: 7 + i, dia: formatDate(nextDate) });
        } else {
            dateArray.push({ id: 7 + i, dia: formatDate(nextDate) });
        }
    }

    ///--------------------

     // pegar lançamento
     function getLancamento() {
        const token = localStorage.getItem('token');
        axios({
            url: "/api/v1/gis/lancamento/relatorio",
            params: {
                loja_id: loja,
                month: mes ? mes : window.currentMonth,
                year: ano ? ano : window.currentYear,
            },
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then((response) => {
            setLancamento(response?.data?.data)
        }).catch((error) => console.log(error))
    }

    useEffect(()=>{getLancamento();},[])

    /// -------------------

    const data = [
        ["Legenda", "Valor"],
        ["Realizados", 65],
        ["Pendentes", 25],
        ["NF", 110]
    ];

    const options = {
        title: "Status lançamentos",
        pieHole: 0.6,
        // is3D: false,
    };


    return (
        <Row>
            {/* <Col lg={3}>
                <Chart
                    title="Status lançamentos"
                    headers={["Legenda", "Valor"]}
                    body={{
                        type: 'custom',
                        content: [["Realizados", 45], ["Pendentes", 100]]
                    }}
                    api={''}
                />
            </Col> */}
            <Row>
                <Col lg={3}>
                    <Chart
                        chartType="PieChart"
                        width="100%"
                        height="400px"
                        data={data}
                        options={options}
                    />
                </Col>
            </Row>
            <Row>
                <Table>
                    <Thead>
                        <Tr>
                            <Th>
                                Loja
                            </Th>
                            <Th>
                                Status
                            </Th>
                            <Th>
                                13/10
                            </Th>
                            <Th>
                                14/10
                            </Th>
                            <Th>
                                15/10
                            </Th>
                            <Th>
                                16/10
                            </Th>
                            <Th>
                                17/10
                            </Th>
                            <Th>
                                18/10
                            </Th>
                            <Th>
                                19/10
                            </Th>
                            <Th>
                                20/10
                            </Th>
                            <Th>
                                21/10
                            </Th>
                            <Th>
                                22/10
                            </Th>
                            <Th>
                                23/10
                            </Th>
                            <Th>
                                24/10
                            </Th>
                            <Th>
                                25/10
                            </Th>
                            <Th>
                                26/10
                            </Th>
                            {/* {(
                                dateArray.map((dia, i) => {
                                    return (
                                        <>
                                            <Th key={i}>
                                                {dia.dia}
                                            </Th>
                                        </>
                                    )
                                })
                            )} */}
                        </Tr>
                    </Thead>
                    <Tbody>
                        {(
                            apiInfo.map((item, i) => {
                                return (
                                    <Tr key={i}>
                                        <Td>
                                            {item.loja}
                                        </Td>
                                        <Td>
                                            {item.status}
                                        </Td>
                                        <Td>
                                            <Icon type="close" className="text-secondary" />
                                        </Td>
                                        <Td>
                                            <Icon type="close" className="text-secondary" />
                                        </Td>
                                        <Td>
                                            <Icon type="close" className="text-secondary" />
                                        </Td>
                                        <Td>
                                            <Icon type="close" className="text-secondary" />
                                        </Td>
                                        <Td>
                                            <Icon type="close" className="text-secondary" />
                                        </Td>
                                        <Td>
                                            <Icon type="close" className="text-secondary" />
                                        </Td>
                                        <Td>
                                            <Icon type="close" className="text-secondary" />
                                        </Td>
                                        <Td>
                                            <Icon type="close" className="text-secondary" />
                                        </Td>
                                        <Td>
                                            <Icon type="close" className="text-secondary" />
                                        </Td>
                                        <Td>
                                            <Icon type="close" className="text-secondary" />
                                        </Td>
                                        <Td>
                                            <Icon type="close" className="text-secondary" />
                                        </Td>
                                        <Td>
                                            <Icon type="close" className="text-secondary" />
                                        </Td>
                                        <Td>
                                            <Icon type="close" className="text-secondary" />
                                        </Td>
                                        <Td>
                                            <Icon type="close" className="text-secondary" />
                                        </Td>
                                    </Tr>
                                )
                            })
                        )}
                    </Tbody>
                </Table>
            </Row>
        </Row>
    )
}