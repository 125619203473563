import Col from "../../../components/body/col";
import Row from "../../../components/body/row";
import Table from "../../../components/body/table";
import Thead from "../../../components/body/table/thead";
import Title from "../../../components/body/title";
import Th from "../../../components/body/table/thead/th";
import Tbody from "../../../components/body/table/tbody";
import Tr from "../../../components/body/table/tr";
import Td from "../../../components/body/table/tbody/td";
import Icon from "../../../components/body/icon";
import { useState, useContext } from "react"
import { GlobalContext } from "../../../context/Global";

export default function Plano() {
    // CONTEXT
    const { handleSetFilter } = useContext(GlobalContext);
    // CONVERSOR DE DATA
    function cd(date) {
        let dateFormated =
        (new Date(date).getDate() < 10
            ? "0" + new Date(date).getDate()
            : new Date(date).getDate()) +
        "/" +
        (new Date(date).getMonth() + 1 < 10
            ? "0" + (new Date(date).getMonth() + 1)
            : new Date(date).getMonth() + 1) +
        "/" +
        new Date(date).getFullYear();
        return dateFormated;
    }

    //VARIAVEL COM INFORMAÇÕES DA TABELA
    const infos_plano = [
        {
            status: "Em andamento",
            loja: "Filial 1",
            data: "20/06/2022",
            data_finalizacao: " -- ",
            nome: "Anônimo",
            telefone: "(00) 0000-0000",
            campanha: "asd",
            pergunta: "asdf",
            resposta: " 4 ",
            descricao: " teste ",
        },
        {
            status: "Em andamento",
            loja: "Filial 1",
            data: "20/06/2022",
            data_finalizacao: " -- ",
            nome: "Anônimo",
            telefone: "(00) 0000-0000",
            campanha: "asd",
            pergunta: "asdf",
            resposta: " 4 ",
            descricao: " teste ",
        },
        {
            status: "Em andamento",
            loja: "Filial 1",
            data: "20/06/2022",
            data_finalizacao: " -- ",
            nome: "Anônimo",
            telefone: "(00) 0000-0000",
            campanha: "asd",
            pergunta: "asdf",
            resposta: " 4 ",
            descricao: " teste ",
        },
        {
            status: "Em andamento",
            loja: "Filial 1",
            data: "20/06/2022",
            data_finalizacao: " -- ",
            nome: "Anônimo",
            telefone: "(00) 0000-0000",
            campanha: "asd",
            pergunta: "asdf",
            resposta: " 4 ",
            descricao: " teste ",
        },
        {
            status: "Em andamento",
            loja: "Filial 1",
            data: "20/06/2022",
            data_finalizacao: " -- ",
            nome: "Anônimo",
            telefone: "(00) 0000-0000",
            campanha: "asd",
            pergunta: "asdf",
            resposta: " 4 ",
            descricao: " teste ",
        },
        {
            status: "Em andamento",
            loja: "Filial 1",
            data: "20/06/2022",
            data_finalizacao: " -- ",
            nome: "Anônimo",
            telefone: "(00) 0000-0000",
            campanha: "asd",
            pergunta: "asdf",
            resposta: " 4 ",
            descricao: " teste ",
        },
        {
            status: "Em andamento",
            loja: "Filial 1",
            data: "20/06/2022",
            data_finalizacao: " -- ",
            nome: "Anônimo",
            telefone: "(00) 0000-0000",
            campanha: "asd",
            pergunta: "asdf",
            resposta: " 4 ",
            descricao: " teste ",
        },
        {
            status: "Em andamento",
            loja: "Filial 1",
            data: "20/06/2022",
            data_finalizacao: " -- ",
            nome: "Anônimo",
            telefone: "(00) 0000-0000",
            campanha: "asd",
            pergunta: "asdf",
            resposta: " 4 ",
            descricao: " teste ",
        },
        {
            status: "Em andamento",
            loja: "Filial 1",
            data: "20/06/2022",
            data_finalizacao: " -- ",
            nome: "Anônimo",
            telefone: "(00) 0000-0000",
            campanha: "asd",
            pergunta: "asdf",
            resposta: " 4 ",
            descricao: " teste ",
        },
        {
            status: "Em andamento",
            loja: "Filial 1",
            data: "20/06/2022",
            data_finalizacao: " -- ",
            nome: "Anônimo",
            telefone: "(00) 0000-0000",
            campanha: "asd",
            pergunta: "asdf",
            resposta: " 4 ",
            descricao: " teste ",
        },
        {
            status: "Em andamento",
            loja: "Filial 1",
            data: "20/06/2022",
            data_finalizacao: " -- ",
            nome: "Anônimo",
            telefone: "(00) 0000-0000",
            campanha: "asd",
            pergunta: "asdf",
            resposta: " 4 ",
            descricao: " teste ",
        },
        {
            status: "Em andamento",
            loja: "Filial 1",
            data: "20/06/2022",
            data_finalizacao: " -- ",
            nome: "Anônimo",
            telefone: "(00) 0000-0000",
            campanha: "asd",
            pergunta: "asdf",
            resposta: " 4 ",
            descricao: " teste ",
        },
        {
            status: "Em andamento",
            loja: "Filial 1",
            data: "20/06/2022",
            data_finalizacao: " -- ",
            nome: "Anônimo",
            telefone: "(00) 0000-0000",
            campanha: "asd",
            pergunta: "asdf",
            resposta: " 4 ",
            descricao: " teste ",
        },
        {
            status: "Em andamento",
            loja: "Filial 1",
            data: "20/06/2022",
            data_finalizacao: " -- ",
            nome: "Anônimo",
            telefone: "(00) 0000-0000",
            campanha: "asd",
            pergunta: "asdf",
            resposta: " 4 ",
            descricao: " teste ",
        },
        {
            status: "Em andamento",
            loja: "Filial 1",
            data: "20/06/2022",
            data_finalizacao: " -- ",
            nome: "Anônimo",
            telefone: "(00) 0000-0000",
            campanha: "asd",
            pergunta: "asdf",
            resposta: " 4 ",
            descricao: " teste ",
        },
        {
            status: "Em andamento",
            loja: "Filial 1",
            data: "20/06/2022",
            data_finalizacao: " -- ",
            nome: "Anônimo",
            telefone: "(00) 0000-0000",
            campanha: "asd",
            pergunta: "asdf",
            resposta: " 4 ",
            descricao: " teste ",
        },
        {
            status: "Em andamento",
            loja: "Filial 1",
            data: "20/06/2022",
            data_finalizacao: " -- ",
            nome: "Anônimo",
            telefone: "(00) 0000-0000",
            campanha: "asd",
            pergunta: "asdf",
            resposta: " 4 ",
            descricao: " teste ",
        },
        {
            status: "Em andamento",
            loja: "Filial 1",
            data: "20/06/2022",
            data_finalizacao: " -- ",
            nome: "Anônimo",
            telefone: "(00) 0000-0000",
            campanha: "asd",
            pergunta: "asdf",
            resposta: " 4 ",
            descricao: " teste ",
        }
    ]
    //VARIAVEIS DOS FILTROS
    const todos_os_status = [
        {
            id: 1,
            label: "Finalizado"
        },
        {
            id: 2,
            label: "Em andamento"
        },
        {
            id: 3,
            label: "Cancelado"
        },
        {
            id: 4,
            label: "Stand By"
        }
    ]
    const todas_as_lojas = [
        {
            id: 1,
            label: "M072"
        },
        {
            id: 2,
            label: "L047"
        },
        {
            id: 3,
            label: "MDZ"
        }
    ]
    const todas_as_campanhas = [
        {
            id: 1,
            label: "Campanha Drogal"
        },
        {
            id: 2,
            label: "Teste 2"
        },
        {
            id: 3,
            label: "Teste 3"
        },
        {
            id: 4,
            label: "Suporte TI"
        }
    ]
    const todas_as_perguntas = [
        {
            id: 1,
            label: "Pergunta 1"
        },
        {
            id: 2,
            label: "Pergunta 2"
        },
        {
            id: 3,
            label: "Olá! Qual problema encontra?"
        },
        {
            id: 4,
            label: "Olá! Qual problema apresenta no sistema?"
        },
        {
            id: 5,
            label: "Olá! Qual problema apresenta na máquina?"
        }
    ]
    const todas_as_respostas = [
        {
            id: 1,
            label: "Resposta 1"
        },
        {
            id: 2,
            label: "Resposta 2"
        },
        {
            id: 3,
            label: "Resposta 3"
        },
        {
            id: 4,
            label: "Resposta 4"
        },
        {
            id: 5,
            label: "Travamento do computador"
        },
        {
            id: 6,
            label: "Internet não funciona"
        },
        {
            id: 7,
            label: "Computador não liga"
        },
        {
            id: 8,
            label: "Travamento do caixa"
        },
        {
            id: 9,
            label: "Outros"
        }
    ]

    //ESTADOS DE FILTRO DE BUSCA
    const [ , setBuscaStatus] = useState('');
    const [ , setBuscaLoja] = useState('');
    const [ , setBuscaNome] = useState('');
    const [ , setBuscaTelefone] = useState('');
    const [ , setBuscaCampanha] = useState('');
    const [ , setBuscaPergunta] = useState('');
    const [ , setBuscaResposta] = useState('');
    const [dataInicio, setDataInicio] = useState('');
    const [dataFim, setDataFim] = useState('');

    //FILTROs
    const handleStatus = (e) => {
        setBuscaStatus(e);
    }

    const handleLoja = (e) => {
        setBuscaLoja(e);
    }

    const handleNome = (e) => {
        setBuscaNome(e);
    }

    const handleTelefone = (e) => {
        setBuscaTelefone(e);
    }

    const handleCampanha = (e) => {
        setBuscaCampanha(e);
    }

    const handlePerguntas = (e) => {
        setBuscaPergunta(e);
    }

    const handleRespostas = (e) => {
        setBuscaResposta(e);
    }

    // FILTRO DE DATA (INÍCIO)
    const handleSetDataInicio = (e) => {
        setDataInicio(e);
        handleSetFilter(true);
    }

    // FILTRO DE DATA (FIM)
    const handleSetDataFim = (e) => {
        setDataFim(e);
        handleSetFilter(true);
    }


    return (
        <>
            <Row>
                <Col>
                    <Title>
                        Plano de Ação (Loja)
                    </Title>
                    <Table>
                        <Thead>
                            <Th
                                name="busca_status"
                                items={todos_os_status}
                                onChange={handleStatus}
                            >Status
                            </Th>
                            <Th
                                name="busca_lojas"
                                items={todas_as_lojas}
                                onChange={handleLoja}
                            >Loja
                            </Th>
                            <Th
                                type="date"
                                start={{ value: dataInicio, onChange: handleSetDataInicio }}
                                end={{ value: dataFim, onChange: handleSetDataFim }}
                            >Data
                            </Th>
                            <Th
                                type="date"
                                start={{ value: dataInicio, onChange: handleSetDataInicio }}
                                end={{ value: dataFim, onChange: handleSetDataFim }}
                            >Data Finalização
                            </Th>
                            <Th
                                name="busca_nome"
                                api={true}
                                onChange={handleNome}
                            >Nome
                            </Th>
                            <Th
                                name="busca_telefone"
                                api={true}
                                onChange={handleTelefone}
                            >Telefone
                            </Th>
                            <Th
                                name="busca_campanhas"
                                items={todas_as_campanhas}
                                onChange={handleCampanha}
                            >Campanha
                            </Th>
                            <Th
                                name="busca_pergunta"
                                items={todas_as_perguntas}
                                onChange={handlePerguntas}
                            >Pergunta
                            </Th>
                            <Th
                                name="busca_resposta"
                                items={todas_as_respostas}
                                onChange={handleRespostas}
                            >Resposta
                            </Th>
                            <Th>Descrição</Th>
                            <Th>Ações</Th>
                        </Thead>
                        <Tbody>
                            {infos_plano.map((item) => {
                                return (
                                    <Tr>
                                        <Td>{item.status}</Td>
                                        <Td>{item.loja}</Td>
                                        <Td>{item.data}</Td>
                                        <Td align="center">{item.data_finalizacao}</Td>
                                        <Td>{item.nome}</Td>
                                        <Td>{item.telefone}</Td>
                                        <Td>{item.campanha}</Td>
                                        <Td>{item.pergunta}</Td>
                                        <Td>{item.resposta}</Td>
                                        <Td>{item.descricao}</Td>
                                        <Td>
                                            <Icon type="view" />
                                            <Icon type="check" />
                                            <Icon type="ban" className="text-warning"/>
                                            <Icon type="standBy" />
                                        </Td>
                                    </Tr>
                                )
                            })}
                        </Tbody>
                    </Table>
                </Col>
            </Row>
        </>
    )
}
