import { useState, useEffect } from "react";
import Row from "../../../components/body/row";
import Icon from "../../../components/body/icon";
import SelectReact from "../../../components/body/select";
import Table from "../../../components/body/table";
import Tr from "../../../components/body/table/tr";
import Tbody from "../../../components/body/table/tbody";
import Td from "../../../components/body/table/tbody/td";
import Input from "../../../components/body/form/input";
import FilterCheckbox from "../../../components/body/filterCheckbox";
import Form from "../../../components/body/form";
import Textarea from "../../../components/body/form/textarea";
import "./style.css";

export default function Funcionario({ legenda, loja, funcionario, avaliacao }) {

    //ESTADOS FILTROS -

    //ESTADOS INPUT
    const [diagnostico1, setDiagnostico1] = useState('');
    const [diagnostico2, setDiagnostico2] = useState('');
    const [diagnostico3, setDiagnostico3] = useState('');
    const [acoes1, setAcoes1] = useState('');
    const [acoes2, setAcoes2] = useState('');
    const [acoes3, setAcoes3] = useState('');
    const [gerente, setGerente] = useState('');
    const [consultor, setConsultor] = useState('');

    //LISTA FILTRO GENÉRICA TARGET 
    const handleTarget = (setState) => (e) => {
        setState(e.target.value)
    }
    const info = [
        { id: 1, funcionario: "Fernanda Costa", loja: "Filial 001", data_adm: "11/06/2023", disciplina: "AE", rel_interpessoal: "AE", zelo: "AE", aprendizagem: "AE", redimento: "AE", compromisso: "AE", nota: "7", resposta_geral: "AE", comentario: " - ", pergunta: "1. Disciplina (Considera a maneira que o avaliado respeita a chefia, acata e cumpre ás ordens que lhe são transmitidas).", resposta: "AE" },
        { id: 2, funcionario: "Fernanda Costa", loja: "Filial 001", data_adm: "11/06/2023", disciplina: "AE", rel_interpessoal: "AE", zelo: "AE", aprendizagem: "AE", redimento: "AE", compromisso: "AE", nota: "7", resposta_geral: "AE", comentario: " - ", pergunta: "2. Relacionamento Interpessoal (Considera a maneira que o avaliado respeita a chefia, acata e cumpre ás ordens que lhe são transmitidas).", resposta: "AE" },
        { id: 3, funcionario: "Fernanda Costa", loja: "Filial 001", data_adm: "11/06/2023", disciplina: "AE", rel_interpessoal: "AE", zelo: "AE", aprendizagem: "AE", redimento: "AE", compromisso: "AE", nota: "7", resposta_geral: "AE", comentario: " - ", pergunta: "3. Zelo (Considera a maneira que o avaliado respeita a chefia, acata e cumpre ás ordens que lhe são transmitidas).", resposta: "AE" },
        { id: 4, funcionario: "Fernanda Costa", loja: "Filial 001", data_adm: "11/06/2023", disciplina: "AE", rel_interpessoal: "AE", zelo: "AE", aprendizagem: "AE", redimento: "AE", compromisso: "AE", nota: "7", resposta_geral: "AE", comentario: " - ", pergunta: "4. Aprendizagem (Considera a maneira que o avaliado respeita a chefia, acata e cumpre ás ordens que lhe são transmitidas).", resposta: "AE" },
        { id: 5, funcionario: "Fernanda Costa", loja: "Filial 001", data_adm: "11/06/2023", disciplina: "AE", rel_interpessoal: "AE", zelo: "AE", aprendizagem: "AE", redimento: "AE", compromisso: "AE", nota: "7", resposta_geral: "AE", comentario: " - ", pergunta: "5. Aprendizagem (Considera a maneira que o avaliado respeita a chefia, acata e cumpre ás ordens que lhe são transmitidas).", resposta: "AE" },
        { id: 6, funcionario: "Fernanda Costa", loja: "Filial 001", data_adm: "11/06/2023", disciplina: "AE", rel_interpessoal: "AE", zelo: "AE", aprendizagem: "AE", redimento: "AE", compromisso: "AE", nota: "7", resposta_geral: "AE", comentario: " - ", pergunta: "6. Aprendizagem (Considera a maneira que o avaliado respeita a chefia, acata e cumpre ás ordens que lhe são transmitidas).", resposta: "AE" },
        { id: 7, funcionario: "Fernanda Costa", loja: "Filial 001", data_adm: "11/06/2023", disciplina: "AE", rel_interpessoal: "AE", zelo: "AE", aprendizagem: "AE", redimento: "AE", compromisso: "AE", nota: "7", resposta_geral: "AE", comentario: " - ", pergunta: "7. Aprendizagem(Considera a maneira que o avaliado respeita a chefia, acata e cumpre ás ordens que lhe são transmitidas).", resposta: "AE" },
        { id: 8, funcionario: "Fernanda Costa", loja: "Filial 001", data_adm: "11/06/2023", disciplina: "AE", rel_interpessoal: "AE", zelo: "AE", aprendizagem: "AE", redimento: "AE", compromisso: "AE", nota: "7", resposta_geral: "AE", comentario: " - ", pergunta: "8. Aprendizagem (Considera a maneira que o avaliado respeita a chefia, acata e cumpre ás ordens que lhe são transmitidas).", resposta: "AE" },
        { id: 9, funcionario: "Fernanda Costa", loja: "Filial 001", data_adm: "11/06/2023", disciplina: "AE", rel_interpessoal: "AE", zelo: "AE", aprendizagem: "AE", redimento: "AE", compromisso: "AE", nota: "7", resposta_geral: "AE", comentario: " - ", pergunta: "9. Aprendizagem (Considera a maneira que o avaliado respeita a chefia, acata e cumpre ás ordens que lhe são transmitidas).", resposta: "AE" },
        { id: 10, funcionario: "Fernanda Costa", loja: "Filial 001", data_adm: "11/06/2023", disciplina: "AE", rel_interpessoal: "AE", zelo: "AE", aprendizagem: "AE", redimento: "AE", compromisso: "AE", nota: "7", resposta_geral: "AE", comentario: " - ", pergunta: "10. Aprendizagem(Considera a maneira que o avaliado respeita a chefia, acata e cumpre ás ordens que lhe são transmitidas).", resposta: "AE" },
    ];

    //OPTIONS SELECT  ---> loja e funcionario usar filtercheckbox
    const options_tipo = [
        { value: 1, label: "Individual" }, { value: 2, label: "Macro" }
    ]



    //add inputs
    const [count, setCount] = useState(0);
    //add inputs
    const handleCount = () => {
        setCount(count + 1)
    }

    //thead

    // loja - funcionario - data - disciplina - relacionamento interpessoal - zelo - aprendizagem - rendimento no trabalho - compromisso no trabalho
    const thead = [
        { enabled: true, label: "Loja", name: "loja", id: "loja" },
        { enabled: true, label: "Funcionário", name: "funcionario", id: "funcionario" },
        { enabled: true, label: "Perguntas", name: "pergunta", id: "pergunta", colspan: "18%" },
        { enabled: true, label: "Nota", name: "nota", id: "nota" },
        { enabled: true, label: "Resposta", name: "resposta", id: "resposta" },
        { enabled: true, label: "Comentário", name: "comentario", id: "comentario" },
    ]


    return (
        <>
            <Row>
                <Table thead={thead} >
                    <Tbody>
                        {info && info.map((item, i) => {
                            return (
                                <Tr key={item.id}>
                                    <Td>{item.loja}</Td>

                                    <Td>{item.funcionario}</Td>

                                    <Td title={item.pergunta}
                                        colspan="18%"
                                    > {item.pergunta}</Td>
                                    <Td >{item.nota}</Td>
                                    <Td >{item.resposta}</Td>
                                    <Td>{item.comentario}</Td>


                                </Tr>
                            )
                        })}
                    </Tbody>
                </Table>
            </Row>

            <Row>


                <Row>
                    <Form className="form_avaliacao">
                        <div className="div_form_avl">
                            <div>
                                <div> Diagnóstico do Consultor </div>
                                <Textarea
                                    label="Diagnóstico 1"
                                    required={false}
                                    value={diagnostico1}
                                    onChange={handleTarget(setDiagnostico1)}
                                />
                            </div>
                            <div>
                                <div className="acoes_div">
                                    <div>Ações acordadas</div>
                                    <Icon
                                        type="new"
                                        onClick={() => handleCount()}
                                        className={count >= 2 ? "text-disabled" : "text-primary"}
                                        disabled={count >= 2}
                                    />
                                </div>
                                <Textarea
                                    label="Ação 1"
                                    required={false}
                                    value={acoes1}
                                    onChange={handleTarget(setAcoes1)}
                                />
                            </div>
                        </div>
                        {
                            count > 0 &&
                            <div className="div_form_avl">
                                <Textarea
                                    label="Diagnóstico 2"
                                    required={false}
                                    value={diagnostico2}
                                    onChange={handleTarget(setDiagnostico2)}
                                />

                                <Textarea
                                    label="Ação 2"
                                    required={false}
                                    value={acoes2}
                                    onChange={handleTarget(setAcoes2)}
                                />
                            </div>
                        }
                        {
                            count > 1 &&
                            <div className="div_form_avl">
                                <Textarea
                                    label="Diagnóstico 3"
                                    required={false}
                                    value={diagnostico3}
                                    onChange={handleTarget(setDiagnostico3)}
                                />
                                <Textarea
                                    label="Ação 3"
                                    required={false}
                                    value={acoes3}
                                    onChange={handleTarget(setAcoes3)}
                                />
                            </div>
                        }

                    </Form>
                </Row>

                <Row>
                    <Form className="form_avaliacao" >
                        <div> Considerações Finais </div>
                        <div className="div_form_avl" >
                            <div>
                                <Textarea
                                    label="Gerente"
                                    required={false}
                                    value={gerente}
                                    onChange={handleTarget(setGerente)}
                                />
                            </div>
                            <div>
                                <Textarea
                                    label="Consultor"
                                    required={false}
                                    value={consultor}
                                    onChange={handleTarget(setConsultor)}
                                />
                            </div>
                        </div>
                    </Form>
                </Row>

            </Row>
        </>
    )
}
