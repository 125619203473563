import style from './cardContainer.module.scss';

export default function CardContainer(props){
    return(
        <div
            className={style.cardContainer + ' ' + (props.loading ? style.loading : '') + ' ' + (props.empty ? style.empty : '')}
            onClick={props.onClick}
        >
            {props.children}
        </div>
    )
}