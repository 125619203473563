import Row from "../../../components/body/row";
import Col from "../../../components/body/col";
import Title from "../../../components/body/title";
import Icon from "../../../components/body/icon"
import Table from "../../../components/body/table";
import Thead from "../../../components/body/table/thead";
import Tr from "../../../components/body/table/tr";
import { useState } from "react"
import Tbody from "../../../components/body/table/tbody";
import Td from "../../../components/body/table/tbody/td";
import Th from "../../../components/body/table/thead/th";
import Button from "../../../components/body/button";
import Input from "../../../components/body/form/input";

export default function Lista() {
    //ESTADO EXPANDIR COLUNAS
    const [hide, setHide] = useState(true);
    //VARIAVEL DE INFORMAÇÃO DAS TABELAS
    const info_tabela = [
        {
            vencimento_em: "22/02/2023",
            loja: "Loja 1",
            cod_produto: "0001 - 0002",
            descricao: "produto 1 teste 123",
            validade: "30 dias",
            data_importacao: "20/10/2022",
            etiqueta_lv: "123 123 123",
            quantidade_original: "500",
            quantidade_alterada: "50",
            codigo_lv: "456 teste 456",
            lote: "asd 123 fgh 456",
            preco_facricacao_uni: "0.50",
            descricao_marca: " Teste 123 456",
            descricao_secao: "FGH 456",
            subgrupo: "789",
            descricao_sub_grupo: "AsD",
            descricao_grupo: "Teste teste teste"
        },
        {
            vencimento_em: "22/02/2023",
            loja: "Loja 1",
            cod_produto: "0001 - 0002",
            descricao: "produto 1 teste 123",
            validade: "30 dias",
            data_importacao: "20/10/2022",
            etiqueta_lv: "123 123 123",
            quantidade_original: "500",
            quantidade_alterada: "50",
            codigo_lv: "456 teste 456",
            lote: "asd 123 fgh 456",
            preco_facricacao_uni: "0.50",
            descricao_marca: " Teste 123 456",
            descricao_secao: "FGH 456",
            subgrupo: "789",
            descricao_sub_grupo: "AsD",
            descricao_grupo: "Teste teste teste"
        },
        {
            vencimento_em: "22/02/2023",
            loja: "Loja 1",
            cod_produto: "0001 - 0002",
            descricao: "produto 1 teste 123",
            validade: "30 dias",
            data_importacao: "20/10/2022",
            etiqueta_lv: "123 123 123",
            quantidade_original: "500",
            quantidade_alterada: "50",
            codigo_lv: "456 teste 456",
            lote: "asd 123 fgh 456",
            preco_facricacao_uni: "0.50",
            descricao_marca: " Teste 123 456",
            descricao_secao: "FGH 456",
            subgrupo: "789",
            descricao_sub_grupo: "AsD",
            descricao_grupo: "Teste teste teste"
        },
        {
            vencimento_em: "22/02/2023",
            loja: "Loja 1",
            cod_produto: "0001 - 0002",
            descricao: "produto 1 teste 123",
            validade: "30 dias",
            data_importacao: "20/10/2022",
            etiqueta_lv: "123 123 123",
            quantidade_original: "500",
            quantidade_alterada: "50",
            codigo_lv: "456 teste 456",
            lote: "asd 123 fgh 456",
            preco_facricacao_uni: "0.50",
            descricao_marca: " Teste 123 456",
            descricao_secao: "FGH 456",
            subgrupo: "789",
            descricao_sub_grupo: "AsD",
            descricao_grupo: "Teste teste teste"
        },
        {
            vencimento_em: "22/02/2023",
            loja: "Loja 1",
            cod_produto: "0001 - 0002",
            descricao: "produto 1 teste 123",
            validade: "30 dias",
            data_importacao: "20/10/2022",
            etiqueta_lv: "123 123 123",
            quantidade_original: "500",
            quantidade_alterada: "50",
            codigo_lv: "456 teste 456",
            lote: "asd 123 fgh 456",
            preco_facricacao_uni: "0.50",
            descricao_marca: " Teste 123 456",
            descricao_secao: "FGH 456",
            subgrupo: "789",
            descricao_sub_grupo: "AsD",
            descricao_grupo: "Teste teste teste"
        },
        {
            vencimento_em: "22/02/2023",
            loja: "Loja 1",
            cod_produto: "0001 - 0002",
            descricao: "produto 1 teste 123",
            validade: "30 dias",
            data_importacao: "20/10/2022",
            etiqueta_lv: "123 123 123",
            quantidade_original: "500",
            quantidade_alterada: "50",
            codigo_lv: "456 teste 456",
            lote: "asd 123 fgh 456",
            preco_facricacao_uni: "0.50",
            descricao_marca: " Teste 123 456",
            descricao_secao: "FGH 456",
            subgrupo: "789",
            descricao_sub_grupo: "AsD",
            descricao_grupo: "Teste teste teste"
        },
        {
            vencimento_em: "22/02/2023",
            loja: "Loja 1",
            cod_produto: "0001 - 0002",
            descricao: "produto 1 teste 123",
            validade: "30 dias",
            data_importacao: "20/10/2022",
            etiqueta_lv: "123 123 123",
            quantidade_original: "500",
            quantidade_alterada: "50",
            codigo_lv: "456 teste 456",
            lote: "asd 123 fgh 456",
            preco_facricacao_uni: "0.50",
            descricao_marca: " Teste 123 456",
            descricao_secao: "FGH 456",
            subgrupo: "789",
            descricao_sub_grupo: "AsD",
            descricao_grupo: "Teste teste teste"
        },
        {
            vencimento_em: "22/02/2023",
            loja: "Loja 1",
            cod_produto: "0001 - 0002",
            descricao: "produto 1 teste 123",
            validade: "30 dias",
            data_importacao: "20/10/2022",
            etiqueta_lv: "123 123 123",
            quantidade_original: "500",
            quantidade_alterada: "50",
            codigo_lv: "456 teste 456",
            lote: "asd 123 fgh 456",
            preco_facricacao_uni: "0.50",
            descricao_marca: " Teste 123 456",
            descricao_secao: "FGH 456",
            subgrupo: "789",
            descricao_sub_grupo: "AsD",
            descricao_grupo: "Teste teste teste"
        },
        {
            vencimento_em: "22/02/2023",
            loja: "Loja 1",
            cod_produto: "0001 - 0002",
            descricao: "produto 1 teste 123",
            validade: "30 dias",
            data_importacao: "20/10/2022",
            etiqueta_lv: "123 123 123",
            quantidade_original: "500",
            quantidade_alterada: "50",
            codigo_lv: "456 teste 456",
            lote: "asd 123 fgh 456",
            preco_facricacao_uni: "0.50",
            descricao_marca: " Teste 123 456",
            descricao_secao: "FGH 456",
            subgrupo: "789",
            descricao_sub_grupo: "AsD",
            descricao_grupo: "Teste teste teste"
        },
        {
            vencimento_em: "22/02/2023",
            loja: "Loja 1",
            cod_produto: "0001 - 0002",
            descricao: "produto 1 teste 123",
            validade: "30 dias",
            data_importacao: "20/10/2022",
            etiqueta_lv: "123 123 123",
            quantidade_original: "500",
            quantidade_alterada: "50",
            codigo_lv: "456 teste 456",
            lote: "asd 123 fgh 456",
            preco_facricacao_uni: "0.50",
            descricao_marca: " Teste 123 456",
            descricao_secao: "FGH 456",
            subgrupo: "789",
            descricao_sub_grupo: "AsD",
            descricao_grupo: "Teste teste teste"
        },
        {
            vencimento_em: "22/02/2023",
            loja: "Loja 1",
            cod_produto: "0001 - 0002",
            descricao: "produto 1 teste 123",
            validade: "30 dias",
            data_importacao: "20/10/2022",
            etiqueta_lv: "123 123 123",
            quantidade_original: "500",
            quantidade_alterada: "50",
            codigo_lv: "456 teste 456",
            lote: "asd 123 fgh 456",
            preco_facricacao_uni: "0.50",
            descricao_marca: " Teste 123 456",
            descricao_secao: "FGH 456",
            subgrupo: "789",
            descricao_sub_grupo: "AsD",
            descricao_grupo: "Teste teste teste"
        },
        {
            vencimento_em: "22/02/2023",
            loja: "Loja 1",
            cod_produto: "0001 - 0002",
            descricao: "produto 1 teste 123",
            validade: "30 dias",
            data_importacao: "20/10/2022",
            etiqueta_lv: "123 123 123",
            quantidade_original: "500",
            quantidade_alterada: "50",
            codigo_lv: "456 teste 456",
            lote: "asd 123 fgh 456",
            preco_facricacao_uni: "0.50",
            descricao_marca: " Teste 123 456",
            descricao_secao: "FGH 456",
            subgrupo: "789",
            descricao_sub_grupo: "AsD",
            descricao_grupo: "Teste teste teste"
        },
        {
            vencimento_em: "22/02/2023",
            loja: "Loja 1",
            cod_produto: "0001 - 0002",
            descricao: "produto 1 teste 123",
            validade: "30 dias",
            data_importacao: "20/10/2022",
            etiqueta_lv: "123 123 123",
            quantidade_original: "500",
            quantidade_alterada: "50",
            codigo_lv: "456 teste 456",
            lote: "asd 123 fgh 456",
            preco_facricacao_uni: "0.50",
            descricao_marca: " Teste 123 456",
            descricao_secao: "FGH 456",
            subgrupo: "789",
            descricao_sub_grupo: "AsD",
            descricao_grupo: "Teste teste teste"
        },
        {
            vencimento_em: "22/02/2023",
            loja: "Loja 1",
            cod_produto: "0001 - 0002",
            descricao: "produto 1 teste 123",
            validade: "30 dias",
            data_importacao: "20/10/2022",
            etiqueta_lv: "123 123 123",
            quantidade_original: "500",
            quantidade_alterada: "50",
            codigo_lv: "456 teste 456",
            lote: "asd 123 fgh 456",
            preco_facricacao_uni: "0.50",
            descricao_marca: " Teste 123 456",
            descricao_secao: "FGH 456",
            subgrupo: "789",
            descricao_sub_grupo: "AsD",
            descricao_grupo: "Teste teste teste"
        },
        {
            vencimento_em: "22/02/2023",
            loja: "Loja 1",
            cod_produto: "0001 - 0002",
            descricao: "produto 1 teste 123",
            validade: "30 dias",
            data_importacao: "20/10/2022",
            etiqueta_lv: "123 123 123",
            quantidade_original: "500",
            quantidade_alterada: "50",
            codigo_lv: "456 teste 456",
            lote: "asd 123 fgh 456",
            preco_facricacao_uni: "0.50",
            descricao_marca: " Teste 123 456",
            descricao_secao: "FGH 456",
            subgrupo: "789",
            descricao_sub_grupo: "AsD",
            descricao_grupo: "Teste teste teste"
        },
        {
            vencimento_em: "22/02/2023",
            loja: "Loja 1",
            cod_produto: "0001 - 0002",
            descricao: "produto 1 teste 123",
            validade: "30 dias",
            data_importacao: "20/10/2022",
            etiqueta_lv: "123 123 123",
            quantidade_original: "500",
            quantidade_alterada: "50",
            codigo_lv: "456 teste 456",
            lote: "asd 123 fgh 456",
            preco_facricacao_uni: "0.50",
            descricao_marca: " Teste 123 456",
            descricao_secao: "FGH 456",
            subgrupo: "789",
            descricao_sub_grupo: "AsD",
            descricao_grupo: "Teste teste teste"
        },
        {
            vencimento_em: "22/02/2023",
            loja: "Loja 1",
            cod_produto: "0001 - 0002",
            descricao: "produto 1 teste 123",
            validade: "30 dias",
            data_importacao: "20/10/2022",
            etiqueta_lv: "123 123 123",
            quantidade_original: "500",
            quantidade_alterada: "50",
            codigo_lv: "456 teste 456",
            lote: "asd 123 fgh 456",
            preco_facricacao_uni: "0.50",
            descricao_marca: " Teste 123 456",
            descricao_secao: "FGH 456",
            subgrupo: "789",
            descricao_sub_grupo: "AsD",
            descricao_grupo: "Teste teste teste"
        },
        {
            vencimento_em: "22/02/2023",
            loja: "Loja 1",
            cod_produto: "0001 - 0002",
            descricao: "produto 1 teste 123",
            validade: "30 dias",
            data_importacao: "20/10/2022",
            etiqueta_lv: "123 123 123",
            quantidade_original: "500",
            quantidade_alterada: "50",
            codigo_lv: "456 teste 456",
            lote: "asd 123 fgh 456",
            preco_facricacao_uni: "0.50",
            descricao_marca: " Teste 123 456",
            descricao_secao: "FGH 456",
            subgrupo: "789",
            descricao_sub_grupo: "AsD",
            descricao_grupo: "Teste teste teste"
        },
        {
            vencimento_em: "22/02/2023",
            loja: "Loja 1",
            cod_produto: "0001 - 0002",
            descricao: "produto 1 teste 123",
            validade: "30 dias",
            data_importacao: "20/10/2022",
            etiqueta_lv: "123 123 123",
            quantidade_original: "500",
            quantidade_alterada: "50",
            codigo_lv: "456 teste 456",
            lote: "asd 123 fgh 456",
            preco_facricacao_uni: "0.50",
            descricao_marca: " Teste 123 456",
            descricao_secao: "FGH 456",
            subgrupo: "789",
            descricao_sub_grupo: "AsD",
            descricao_grupo: "Teste teste teste"
        },
        {
            vencimento_em: "22/02/2023",
            loja: "Loja 1",
            cod_produto: "0001 - 0002",
            descricao: "produto 1 teste 123",
            validade: "30 dias",
            data_importacao: "20/10/2022",
            etiqueta_lv: "123 123 123",
            quantidade_original: "500",
            quantidade_alterada: "50",
            codigo_lv: "456 teste 456",
            lote: "asd 123 fgh 456",
            preco_facricacao_uni: "0.50",
            descricao_marca: " Teste 123 456",
            descricao_secao: "FGH 456",
            subgrupo: "789",
            descricao_sub_grupo: "AsD",
            descricao_grupo: "Teste teste teste"
        }
    ]
    //ESTADOS ITENS DA LISTA 
    const [vencimento, setVencimento] = useState('');
    const [loja, setLoja] = useState('');
    //ESTADOS FILTRO DE BUSCA
    const [buscaVencimento, setBuscaVencimento] = useState('');
    const [buscaLoja, setBuscaLoja] = useState('');
    const [buscaCodProduto, setBuscaCodProduto] = useState('');
    const [buscaDescricao, setBuscaDescricao] = useState('');
    const [buscaEtiquetaLV, setBuscaEtiquetaLV] = useState('');
    const [buscaQuantOriginal, setBuscaQuantOriginal] = useState('');
    const [buscaQuantAlterada, setBuscaQuantAlterada] = useState('');
    const [buscaCodLV, setBuscaCodLV] = useState('');
    const [buscaLote, setBuscaLote] = useState('');
    const [buscaPrecoFabricaUni, setBuscaPrecoFabricaUni] = useState('');
    const [buscaDescricaoMarca, setBuscaDescricaoMarca] = useState('');
    const [buscaDescricaoSecao, setBuscaDescricaoSecao] = useState('');
    const [buscaSubGrupo, setBuscaSubGrupo] = useState('');
    const [buscaDescricaoSubGrupo, setBuscaDescricaoSubGrupo] = useState('');
    const [buscaDescricaoGrupo, setBuscaDescricaoGrupo] = useState('');
    //VARIAVEIS COM INFORMAÇÃO DOS FILTROS DE BUSCA
    const todos_os_vencimentos = [
        { id: 1, label: "30 dias" },
        { id: 2, label: "60 dias" },
        { id: 3, label: "90 dias" },
        { id: 4, label: "120 dias" },
        { id: 5, label: "150 dias" },
        { id: 6, label: "180 dias" },
        { id: 7, label: "365 dias" }
    ]
    const todas_as_lojas = [
        { id: 1, label: "Filial 1" },
        { id: 2, label: "Filial 2" },
        { id: 3, label: "Filial 3" },
        { id: 4, label: "Filial 4" },
        { id: 5, label: "Loja 23" }
    ]
    //FUNÇÃO DO CHECKBOX VENCIMENTO
    function handleCheckVencimento(e) {
        if (e.target.checked) {
            setVencimento([...vencimento, e.target.value])
        } else {
            setVencimento(vencimento.filter((item) => item !== e.taget.value))
        }
    }
    //FUNÇÃO DO CHECKBOX LOJA
    function handleCheckLoja(e) {
        if (e.target.checked) {
            setLoja([...loja, e.target.value])
        } else {
            setLoja(loja.includes((item) => item !== e.target.value))
        }
    }
    return (
        <>
            <Row>
                <Col>
                    <Title
                        icon={
                            <>
                                <Icon type="print" />
                                <Icon type="excel" />
                                <Icon type="expandir"
                                    expander={!hide}
                                    onClick={() => { setHide(!hide) }} />
                                <Icon type="i" />
                            </>
                        }
                    >Relatório
                    </Title>
                    <Table>
                        <Thead>
                            <Tr>
                                <Th
                                    search={<Input
                                        placeholder="Procurar"
                                        type="text"
                                        name="busca_vencimento"
                                        value={buscaVencimento}
                                        onChange={(e) => setBuscaVencimento(e.target.value)}
                                    />}
                                    items={todos_os_vencimentos.filter(item => {
                                        if (!buscaVencimento) return true
                                        if (item.label.toLowerCase().includes(buscaVencimento.toLowerCase())) {
                                            return true
                                        }
                                    }).map((item) => {
                                        return (
                                            <Input
                                                id={"busca-vencimento" + item.id}
                                                type="checkbox"
                                                checked={(vencimento.includes(item.id) ? true : null)}
                                                label={item.label}
                                                value={item.id}
                                                onChange={(e) => handleCheckVencimento(e)}
                                            />
                                        )
                                    })}
                                >Vencimento Em
                                </Th>
                                <Th
                                    search={
                                        <Input
                                            placeholder="Procurar"
                                            type="text"
                                            name="busca-loja"
                                            value={buscaLoja}
                                            onChange={(e) => setBuscaLoja(e.target.value)}
                                        />}
                                    items={todas_as_lojas.filter(item => {
                                        if (!buscaLoja) return true
                                        if (item.label.toLowerCase().includes(buscaLoja.toLowerCase())) {
                                            return true
                                        }
                                    }).map((item) => {
                                        return (
                                            <Input
                                                id={"busca-loja" + item.id}
                                                type="checkbox"
                                                checked={(loja.includes(item.id) ? true : null)}
                                                label={item.label}
                                                value={item.id}
                                                onChange={(e) => handleCheckLoja(e.target.value)}
                                            />
                                        )
                                    })}
                                >Loja
                                </Th>
                                <Th
                                    search={
                                        <Input
                                            placeholder="Procurar"
                                            type="text"
                                            name="cod-produto"
                                            value={buscaCodProduto}
                                            onChange={(e) => setBuscaCodProduto(e.target.value)}
                                        />}
                                >Código Produto
                                </Th>
                                <Th
                                    search={
                                        <Input
                                            placeholder="Procurar"
                                            type="text"
                                            name="busca-descricao"
                                            value={buscaDescricao}
                                            onChange={(e) => setBuscaDescricao(e.target.value)}
                                        />}
                                >Descrição
                                </Th>
                                <Th type="date">Validade</Th>
                                <Th type="date">Data Importação</Th>
                                <Th
                                    search={
                                        <Input
                                            placeholder="Procurar"
                                            type="text"
                                            name="busca-etiqueta-lv"
                                            value={buscaEtiquetaLV}
                                            onChange={(e) => setBuscaEtiquetaLV(e.target.value)}
                                        />}
                                >Etiqueta LV
                                </Th>
                                <Th
                                    search={<Input
                                        placeholder="Procurar"
                                        type="text"
                                        name="busca-quantidade-original"
                                        value={buscaQuantOriginal}
                                        onChange={(e) => setBuscaQuantOriginal(e.target.value)}
                                    />}
                                >Quantidade Original
                                </Th>
                                <Th
                                    search={<Input
                                        placeholder="Procurar"
                                        type="text"
                                        name="busca-quanti-alterada"
                                        value={buscaQuantAlterada}
                                        onChange={(e) => setBuscaQuantOriginal(e.target.value)}
                                    />}
                                >Quantidade Alterada
                                </Th>
                                <Th hide={hide}
                                    search={<Input
                                        placeholder="Procurar"
                                        type="text"
                                        name="busca-cod-lv"
                                        value={buscaCodLV}
                                        onChange={(e) => setBuscaCodLV(e.target.value)}
                                    />}
                                >Código LV
                                </Th>
                                <Th hide={hide}
                                    search={<Input
                                        placeholder="Procurar"
                                        type="text"
                                        name="busca-lote"
                                        value={buscaLote}
                                        onChange={(e) => setBuscaLote(e.target.value)}
                                    />}
                                >Lote
                                </Th>
                                <Th hide={hide}
                                    search={<Input
                                        placeholder="Procurar"
                                        type="text"
                                        name="busca-preco-fabri-unitario"
                                        value={buscaPrecoFabricaUni}
                                        onChange={(e) => setBuscaPrecoFabricaUni(e.target.value)}
                                    />}
                                >Preço Fábrica Unitário
                                </Th>
                                <Th hide={hide}
                                    search={<Input
                                        placeholder="Procurar"
                                        type="text"
                                        name="busca-descricao-marca"
                                        value={buscaDescricaoMarca}
                                        onChange={(e) => setBuscaDescricaoMarca(e.target.value)}
                                    />}
                                >Descrição Marca
                                </Th>
                                <Th hide={hide}
                                    search={<Input
                                        placeholder="Procurar"
                                        type="text"
                                        name="busca-descrica-secao"
                                        value={buscaDescricaoSecao}
                                        onChange={(e) => setBuscaDescricaoSecao(e.target.value)}
                                    />}
                                >Descrição Seção
                                </Th>
                                <Th hide={hide}
                                    search={<Input
                                        placeholder="Procurar"
                                        type="text"
                                        name="busca-sub-grupo"
                                        value={buscaSubGrupo}
                                        onChange={(e) => setBuscaSubGrupo(e.target.value)}
                                    />}
                                >Subgrupo
                                </Th>
                                <Th hide={hide}
                                    search={<Input
                                        placeholder="Procurar"
                                        type="text"
                                        name="busca-descricao-sub-grupo"
                                        value={buscaDescricaoSubGrupo}
                                        onChange={(e) => setBuscaDescricaoSubGrupo(e.target.value)}
                                    />}
                                >Descrição Subgrupo
                                </Th>
                                <Th hide={hide}
                                    search={<Input
                                        placeholder="Procurar"
                                        type="text"
                                        name="busca-descricao-grupo"
                                        value={buscaDescricaoGrupo}
                                        onChange={(e) => setBuscaDescricaoGrupo(e.target.value)}
                                    />}
                                >Descrição Grupo
                                </Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {info_tabela.map((item) => {
                                return (
                                    <Tr>
                                        <Td>{item.vencimento_em}</Td>
                                        <Td>{item.loja}</Td>
                                        <Td>{item.cod_produto}</Td>
                                        <Td>{item.descricao}</Td>
                                        <Td>{item.validade}</Td>
                                        <Td>{item.data_importacao}</Td>
                                        <Td>{item.etiqueta_lv}</Td>
                                        <Td>{item.quantidade_original}</Td>
                                        <Td>{item.quantidade_alterada}</Td>
                                        <Td hide={hide}>{item.codigo_lv}</Td>
                                        <Td hide={hide}>{item.lote}</Td>
                                        <Td hide={hide} align="center">{item.preco_facricacao_uni}</Td>
                                        <Td hide={hide}>{item.descricao_marca}</Td>
                                        <Td hide={hide}>{item.descricao_secao}</Td>
                                        <Td hide={hide}>{item.subgrupo}</Td>
                                        <Td hide={hide}>{item.descricao_sub_grupo}</Td>
                                        <Td hide={hide}>{item.descricao_grupo}</Td>
                                    </Tr>
                                )
                            })}
                        </Tbody>
                    </Table>
                    <Button><Icon type="mensagem" /></Button>
                </Col>
            </Row>
        </>
    )
}
