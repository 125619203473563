import { useState, useEffect, useRef } from "react";
import axios from "axios";
import Modal from "../../../components/body/modal";
import ModalHeader from "../../../components/body/modal/modalHeader";
import ModalTitle from "../../../components/body/modal/modalHeader/modalTitle";
import ModalBody from "../../../components/body/modal/modalBody";
import Button from "../../../components/body/button";
import { cd, get_date } from "../../../_assets/js/global";
import "./style.css"
import Loader from "../../../components/body/loader";
import html2canvas from "html2canvas";

export default function Cracha({ show, onHide, id, handleSolicitar }) {

    //USEREF
    const exportRef = useRef();

    //FECHAR MODAL
    const handleClose = () => {
        onHide(false);
    }

    //ESTADOS
    const [info, setInfo] = useState('');
    const [loading, setLoading] = useState(true);
    //
    const [imageError, setImageError] = useState(false);

    //PEGAR INFORMAÇÃO DA API NOVA
    function getInfo() {
        axios({
            url: `https://backend.madnezz.com.br/api/v1/funcionarios/${id}`,
            // params: params,
        }).then((response) => {
            setInfo(response?.data);
            setLoading(false);
        })
    }

    useEffect(() => {
        if (id) {
            getInfo();
            setImageError(false)
        }
    }, [id]);

    let color;
    if (Number(info?.cracha?.duracao) <= 15) {
        color = "red"
    }

    const options = {
        allowTaint: true,
        useCORS: true,
    };

    //DOWNLOAD DO CRACHA EM PNG
    function downloadImg() {
        const container = exportRef.current
        html2canvas(container, {
            allowTaint: true,
            useCORS: true
        }).then((canvas) => {
            const newCanvas = document.createElement('canvas');
            const ctx = newCanvas.getContext('2d');
            newCanvas.width = 438;
            newCanvas.height = 571;
            ctx.drawImage(canvas, 0, 0, newCanvas.width, newCanvas.height);
            const dataUrl = newCanvas.toDataURL('image/png');
            const link = document.createElement('a');
            link.href = dataUrl;
            link.download = 'image.png';
            link.click();
        }).catch((_error) => { })
    }

    //verificar se tem imagem
    const imageOnError = (event) => {
        setImageError(true)
        event.currentTarget.className = "error";
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <ModalHeader>
                <ModalTitle>
                    Crachá
                </ModalTitle>
            </ModalHeader>
            <ModalBody>
                {
                    loading ?
                        <Loader show={true} />
                        :
                        <div className="main-container-cracha" id="main-container-cracha">
                            <div ref={exportRef} style={{ width: '100%', height: '100%' }}>
                                <div className="image-container-cracha" style={{ position: 'relative' }}>
                                    {
                                        info.imagem_url ?
                                            imageError ?
                                                <div className="image-error">
                                                    <span>Funcionário sem foto. <a onClick={() => (handleSolicitar(id), handleClose())} >Editar usuário</a></span>
                                                </div>
                                                :
                                                <img
                                                    src={info.imagem_url}
                                                    className="img-funcionario"
                                                    alt="user_img"
                                                    onError={imageOnError}
                                                />
                                            :
                                            <div className="image-error">
                                                <span>Funcionário sem foto. <a onClick={() => (handleSolicitar(id), handleClose())} >Editar usuário</a></span>
                                            </div>
                                    }
                                </div>
                                <div className={`main-div-cracha`}>
                                    <h3>{info?.pessoa?.nome}</h3>
                                    <p>RG: <span>{info?.pessoa?.rg}</span></p>
                                    <p>Loja:  <span>{info?.loja?.nome}</span></p>
                                    <p>Cargo:  <span>{info?.cargo?.nome}</span></p>
                                    <p>Vencimento: <span className={color} > {cd(get_date('date_sql', (cd(info?.cracha?.cad)), 'date_add_day', info?.cracha?.duracao))}</span></p>
                                </div>
                                <div>
                                    <img
                                        src={`https://chart.googleapis.com/chart?chs=150x150&cht=qr&chl=https://v3.madnezz.com.br/systems/personalizacao/api/cracha.php?loja_id=${info?.loja?.id}%26qrcode=1%26cracha=${info?.cracha?.id}`}
                                    />
                                </div>
                            </div>
                            <div className="button-div-cracha">
                                <Button onClick={() => window.print()}>Imprimir</Button>
                                <Button onClick={() => downloadImg()}>Baixar</Button>
                            </div>
                        </div>
                }
            </ModalBody>
        </Modal>
    )
}