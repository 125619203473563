import { Routes, Route, useParams, Navigate } from 'react-router-dom';
import { decrypt } from '../../_assets/js/global';
import { useState } from 'react';
import axios from 'axios';
import Navbar from "../../components/header/navbar";
import NavbarLink from '../../components/header/navbar/navbarLink';
import Container from "../../components/body/container";

// PÁGINAS
import Lista from './Lista';
import GerenciadorDocumentos from './Gerenciador';

export default function Documentos(){
  const params = useParams();
  const paramsUrl = params['*'].substring(params['*'].indexOf('/') + 1);
  const paramsUrlCount = (paramsUrl.match(/\//g) ? paramsUrl.match(/\//g).length : 0);
  var tokenUrl, idJob;

  if (paramsUrlCount > 0) {
    tokenUrl = params['*'].substring(params['*'].indexOf('/') + 1, params['*'].lastIndexOf('/'));
    idJob = params['*'].substring(params['*'].lastIndexOf('/') + 1)
  } else {
    tokenUrl = params['*'].substring(params['*'].indexOf('/') + 1);
  }

  var decryptedReturn = JSON.parse(decrypt(tokenUrl));
  
  window.idJob = idJob;
  window.token = tokenUrl;
  axios.defaults.headers.common['Authorization'] = 'Bearer '+window.token;
  window.rs_id_emp = decryptedReturn.id_emp; 
  window.rs_id_usr = decryptedReturn.id_usr;
  window.rs_id_lja = decryptedReturn.id_lja;
  window.rs_name_usr = decryptedReturn.name_usr;
  window.rs_id_apl = decryptedReturn.id_apl;
  window.rs_permission_apl = decryptedReturn.pus;

 // ESTADOS (ÍCONES E FILTROS)
 const [icons, setIcons] = useState(null);
 const [filters, setFilters] = useState(null);

 // DEFINE ÍCONES NAVBAR
 const handleSetIcons = (e) => {
   setIcons(e);
 }

 // DEFINE FILTROS NAVBAR
 const handleSetFilters = (e) => {
   setFilters(e);
 }


  return (
    <>
      <Navbar
        filters={filters}
        icons={icons}
      >
        <NavbarLink link={'lista/' + window.token} name="Lista" icon="relatorio" />
        {(window.rs_permission_apl === 'master' ?
          <NavbarLink link={'gerenciador/' + window.token} name="Gerenciador" icon="cog" />
        :'')}
      </Navbar>

      <Container>
        <Routes>
          <Route path="/:token" index={true} element={<Navigate to={'lista/'+window.token} replace />} />
          <Route path="lista/:token" element={<Lista icons={handleSetIcons} filters={handleSetFilters} />} />

          {(window.rs_permission_apl === 'master' ?
            <Route path="gerenciador/:token" element={<GerenciadorDocumentos icons={handleSetIcons} filters={handleSetFilters} />} />
          :'')}
        </Routes>
      </Container>
    </>
  );
}
