import Modal from 'react-bootstrap/Modal';
import style from './modalHeader.module.scss';

export default function ModalHeader(props){

    return(
        <>
            <Modal.Header className={ style.modalHeader + ' ' + (props.className ? props.className : '') } closeButton>
                { props.children }
            </Modal.Header>
        </>
    );
}
