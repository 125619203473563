import Col from "../../../components/body/col";
import Row from "../../../components/body/row";
import { useState, useEffect } from "react";
import { cd } from "../../../_assets/js/global";
import "./style.css"
import Title from "../../../components/body/title";
import VendaInput from "./VendaInput";
import Loader from "../../../components/body/loader";

export default function Mensal({ mes, lancamento, aux, loja, callback, ano, status }) {


    //ESTADOS FILTRO

    //ESTADO CALENDÁRIO
    const [calendar, setCalendar] = useState([]);
    //CALENDÁRIO AUX
    const semana_aux = ['Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado', 'Domingo']
    //state pra verificar calendario carregando, limpar 
    const [loading, setLoading] = useState()

    //ATUALIZAR CALENDÁRIO
    useEffect(() => {
        //-> true
        setLoading(true)
        async function fetchData() {
            if (lancamento) {
                const apiData = lancamento[0];
                const generatedCalendar = await generateCalendar(mes - 1, apiData);
                setCalendar(generatedCalendar);
            }
            // -> false
            setLoading(false)
        }

        fetchData();
    }, [mes, lancamento, ano]);

    useEffect(() => { }, [calendar])

    const today = new Date();

    //GERAR CALENDÁRIO DE ACORDO COM O MÊS SELECIONADO
    async function generateCalendar(mes, apiData, ano) {

        const today = new Date();
        const firstDayOfMonth = new Date(today.getFullYear(), mes, 1);
        const lastDayOfMonth = new Date(window.currentYear, mes + 1, 0).getDate();
        const diasSemana = ['Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado', 'Domingo'];
        const dataArrayByWeekday = [];

        for (let day = 1; day <= lastDayOfMonth; day++) {
            const event = {
                dia: new Date(today.getFullYear(), mes, day, 7, 0),
                venda: null,
                completo: false,
            };
            const formattedDay = day < 10 ? `0${day}` : day;

            // Verifica se há dados na API para o dia específico
            const apiVendaKey = `venda_${formattedDay}`;
            if (apiData && apiData.hasOwnProperty(apiVendaKey)) {
                event.venda = apiData[apiVendaKey];
                event.completo = event.venda !== 0;
            }

            const weekdayIndex = new Date(today.getFullYear(), mes, day).getDay();
            const adjustedWeekdayIndex = weekdayIndex === 0 ? 6 : weekdayIndex - 1;
            const weekdayName = diasSemana[adjustedWeekdayIndex];

            const existingWeekdayObj = dataArrayByWeekday.find(obj => obj.dia_semana === weekdayName);

            if (existingWeekdayObj) {
                existingWeekdayObj.array_info.push(event);
            } else {
                const newWeekdayObj = {
                    id: adjustedWeekdayIndex + 1,
                    dia_semana: weekdayName,
                    array_info: [event]
                };

                dataArrayByWeekday.push(newWeekdayObj);
            }
        }
        return dataArrayByWeekday;
    }

    return (
        <Row>
            {
                loading && !lancamento ?
                    semana_aux.map((item) => {
                        return (
                            <Col>
                                <Row><Title>{item}</Title></Row>
                                <div className="mini-card-gis empty" ><Loader show={true} /></div>
                            </Col>
                        )
                    })
                    :
                    calendar && calendar.map((item, i) => {
                        
                        return (
                            <Col key={item.id}>                              
                                <Title>{item.dia_semana}</Title>
                                {item.array_info.
                                    filter((dia) => {
                                        if (status == 1) return true
                                        if (dia.completo && status == 2) {
                                            return true
                                        } else if (dia.dia < today && (dia.venda === 0 || dia.venda === null) && !dia.completo && status == 3) {
                                            return true
                                        }
                                    })
                                    .map((dia, i) => {                                    
                                        let color = "";
                                        if (dia.dia < today && (dia.venda === 0 || dia.venda === null) && !dia.completo) {
                                            color = "late"
                                        } else if (dia.completo == true) {
                                            color = "complete"
                                        } else {
                                            color = ''
                                        }
                                       
                                        return (
                                            <>
                                                <div key={cd(dia.dia)} className={`mini-card-gis ${color}`}>
                                                    {(cd(dia.dia).slice(0, 5))}
                                                    <VendaInput
                                                        venda={loading ? '' : dia.venda}
                                                        dia={dia?.dia}
                                                        color={color}
                                                        loja={loja}
                                                        callback={callback}
                                                        mes={mes}
                                                        style={'input-sac-mensal'}
                                                        loading={loading}
                                                    />
                                                </div>
                                            </>
                                        )
                                    })}
                                {item.array_info.length < 5 && <div className="mini-card-gis empty" ></div>}
                            </Col>
                        )
                    })
            }
        </Row>
    )
}