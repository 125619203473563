import style from './Breadcrumbs.module.scss';

export default function Breadcrumbs(props){
    return(
        <div className={style.breadcrumbs} id="breadcrumbs">
            <div>
                <span className={style.title}>
                    {props.title}:
                </span>
            </div>

            <div className="d-flex align-items-center">
                {props.children}
            </div>
        </div>
    )
}