import { useState, useEffect } from "react";
import Input from "../../../../components/body/form/input";
import Modal from "../../../../components/body/modal";
import ModalBody from "../../../../components/body/modal/modalBody";
import ModalHeader from "../../../../components/body/modal/modalHeader";
import ModalTitle from "../../../../components/body/modal/modalHeader/modalTitle";
import SelectReact from "../../../../components/body/select";
import Button from "../../../../components/body/button";
import CheckboxGroup from "../../../../components/body/form/checkboxGroup";
import { cd, get_date } from "../../../../_assets/js/global";
import Form from '../../../../components/body/form';

export default function Cadastro({ show, onHide, id, callback }) {
    // ESTADOS
    const [statusForm, setStatusForm] = useState('');

    //ESTADOS INPUT
    const [tipo, setTipo] = useState('');
    const [titulo, setTitulo] = useState('');
    const [subtitulo, setSubtitulo] = useState('');
    const [arquivo, setArquivo] = useState('');
    const [periodo, setPeriodo] = useState('');
    const [lojas, setLojas] = useState(null);
    const [departamentos, setDepartamentos] = useState(null);
    const [cargos, setCargos] = useState(null)


    //OPÇÕES TIPO
    const options_tipo = [
        { value: 1, label: "Objeto" }, { value: 267, label: "Documentos gerais" }, { value: 268, label: "Controle de pragas" }, { value: 269, label: "Análise do ar" }, { value: 270, label: "Potabilidade" },
        { value: 271, label: "Higienização" }, { value: 272, label: "SPDA" }, { value: 273, label: "Lixo hospitalar" }, { value: 292, label: "Boleto" }, { value: 544, label: "Manuais" }
    ];
    //OPÇÕES TÍTULO
    const options_titulo = [
        { value: 1, label: "Livros" }, { value: 1250, label: "Manual do lojista" }, { value: 1251, label: "Manual do portal do lojista" }, { value: 1252, label: "Normas gerais" }, { value: 1254, label: "Regimento de mídias sociais" },
        { value: 1256, label: "Regimento interno" }, { value: 1257, label: "Análise de qualidade do ar" }, { value: 1258, label: "Certificado de garantia de controle de pragas" }, { value: 1259, label: "Laudo de potabilidade da água" },
        { value: 1260, label: "Limpeza e higienização de reservatórios de água" }, { value: 1261, label: "Laudo de SPDA" }, { value: 1262, label: "Lixo hospitalar" }, { value: 1272, label: "Boleto" },
        { value: 2245, label: "Outros" }, { value: 2502, label: "Obra" }
    ]

    //VARIAVEL FECHAR MODAL
    const handleCloseModal = () => {
        onHide(false);
    };

    //FUNÇÃO GENÉRICA QUE ENVIA EVENTO PRO STATE
    const handleSet = (setState) => (e) => {
        setState(e)
    }

    // SETAR ANEXO
    const handleSetAnexo = (response) => {
        let aux = response && JSON.parse(response[0]);
        setArquivo(aux[0].id);
    };

    // DATA DO ENVIO
    const data = {
        arquivo: arquivo,
        tipo_id: tipo,
        subtipo_id: titulo,
        subtitulo: subtitulo,
        data: periodo && `${get_date('date_sql', cd(periodo)).split('-')[0]}-${get_date('date_sql', cd(periodo)).split('-')[1]}`,
        lojas: lojas,
        departamentos: departamentos,
        cargos: cargos,
    }

    function resetForm() {
        setTipo('');
        setTitulo('')
        setSubtitulo('');
        setPeriodo('');
        setCargos(null);
        setDepartamentos(null);        
        setLojas(null);
        setArquivo('');        
    }

    // STATUS FORM
    const handleStatusForm = (e) => {
        setStatusForm(e);
    }

    // CALLBACK FORM
    const handleCallbackForm = () => {
        if(callback){
            callback(true);
        }
        resetForm();
    }

    return (
        <Modal show={show} onHide={handleCloseModal} >
            <ModalHeader>
                <ModalTitle>
                    {id ? "Editar" : "Novo"}
                </ModalTitle>
            </ModalHeader>
            <ModalBody>
                <Form
                    api={process.env.REACT_APP_SERVER_URL+'/api/v1/documentos'}
                    data={data}
                    status={handleStatusForm}
                    callback={handleCallbackForm}
                    toast={'Documento cadastrado com sucesso!'}
                >
                    <SelectReact
                        label="Tipo"
                        value={tipo}
                        onChange={(e) => setTipo(e.value)}
                        options={options_tipo}
                    />
                    <SelectReact
                        label="Título"
                        value={titulo}
                        onChange={(e) => setTitulo(e.value)}
                        options={options_titulo}
                    />
                    <Input
                        name="subtitulo"
                        type="text"
                        label="Subtítulo"
                        value={subtitulo}
                        onChange={(e) => setSubtitulo(e.target.value)}
                    />
                    <Input
                        name="periodo"
                        type="date"
                        format="mm/yyyy"
                        label="Período"
                        icon="calendar"
                        value={periodo}
                        onChange={(e) => setPeriodo(e)}
                    />
                    <CheckboxGroup
                        group="cargo"
                        callback={handleSet(setCargos)}
                        required={false}
                    />
                    <CheckboxGroup
                        group="loja"
                        callback={handleSet(setLojas)}
                        required={false}
                    />
                    <CheckboxGroup
                        group="departamento"
                        callback={handleSet(setDepartamentos)}
                        required={false}
                    />
                    <Input
                        label="Arquivo"
                        type="file"
                        value={arquivo}
                        callback={handleSetAnexo}
                    />

                    <div style={{ marginTop: "4%" }}>
                        <Button type="submit" status={statusForm}>Enviar</Button>
                    </div>
                </Form>
            </ModalBody>
        </Modal>
    )
}