import { useEffect, useState, useContext } from "react";

import Row from "../../../components/body/row";
import Empreendimento from "./Empreendimento";
import Categorias from "./Categorias";
import Subcategorias from "./Subcategorias";
import { GlobalContext } from "../../../context/Global";

export default function Gerenciador({icons, filters, id_grupo, id_apl, id_emp, integrated, fases, chamados, visitas}){
    // GLOBAL CONTEXT
    const { handleSetFilter } = useContext(GlobalContext);

    // RECARREGA LISTA INICIAL AO ENTRAR NA PÁGINA
    useEffect(() => {
        if(integrated === false){
            handleSetFilter(true);
        }
    }, []);

    // ESTADOS
    const [gruposEdit, setGruposEdit] = useState(false);
    const [grupoId, setGrupoId] = useState(id_grupo);
    const [empreendimentos, setEmpreendimentos] = useState([]);
    const [empreendimentoActive, setEmpreendimentoActive] = useState((id_emp ? id_emp : null));
    const [categoriaActive, setCategoriaActive] = useState(null);

    // CALLBACK DO CLIQUE COMPONENTE DE EMPREENDIMENTOS
    const handleCallbackEmpreendimento = (e) => {
        setGrupoId(e?.id_grupo);
        setEmpreendimentoActive(e.active);
        setCategoriaActive('');
    }

    // CALLBACK DO CLIQUE COMPONENTE DE CATEGORIAS
    const handleCallbackCategoria = (e) => {
        setCategoriaActive(e.active);
    }

    // REMOVE FILTROS DO CABEÇALHO
    useEffect(() => {
        if(filters){
            filters('');
        }

        if(icons){
            icons('');
        }
    },[]);
    
    return(
        <Row wrap={(window.isMobile ? true : false)} disabled={(integrated !== false ? true : false)}>
            {(window.rs_id_emp == 26 && !id_emp ? 
                <Empreendimento
                    items={empreendimentos}   
                    callback={handleCallbackEmpreendimento}
                    disabled={(gruposEdit ? true : false)}
                    fases={fases}
                    chamados={chamados}
                    visitas={visitas}
                />
            :'')}
            
            {(empreendimentoActive ? 
                <Categorias
                    emp={empreendimentoActive}
                    id_grupo={grupoId}
                    id_apl={id_apl}
                    callback={handleCallbackCategoria}
                    disabled={(gruposEdit ? true : false)}
                    fases={fases}
                    chamados={chamados}
                    visitas={visitas}
                />
            :'')}

            {(categoriaActive ? 
                <Subcategorias
                    category={categoriaActive}
                    emp={empreendimentoActive}
                    id_grupo={grupoId}
                    id_apl={id_apl}
                    // callback={handleCallbackLoja}
                    disabled={(gruposEdit ? true : false)}
                    fases={fases}
                    chamados={chamados}
                    visitas={visitas}
                />
            :'')}
        </Row>
    )
}