import "./style.css";
import style from './home.module.scss';
import { decrypt } from "../../_assets/js/global";

// MAIN CARD
import MainModuleText from "../../components/body/home/moduleText";

//DRAG, DROP E SORTABLE
import { useState } from "react";
import {
    DndContext,
    pointerWithin,
    rectIntersection,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
} from '@dnd-kit/core';
import {
    arrayMove,
    sortableKeyboardCoordinates,
    verticalListSortingStrategy
} from '@dnd-kit/sortable';

import Icon from "../../components/body/icon";
import Tippy from "@tippyjs/react";
import Container from "./components/container";
import { JobsProvider } from "../../context/Jobs";
import { useParams } from "react-router";

export default function Home() {
    const params = useParams();    
    const paramsUrl = params['*'].substring(params['*'].indexOf('/')+1);
    const paramsUrlCount = (paramsUrl.match(/\//g)?paramsUrl.match(/\//g).length:0);
    var tokenUrl, idJob;

    if(paramsUrlCount>0){
        tokenUrl = params['*'].substring(params['*'].indexOf('/') + 1, params['*'].lastIndexOf('/'));
        idJob = params['*'].substring(params['*'].lastIndexOf('/') + 1)
    }else{
        tokenUrl = params['*'].substring(params['*'].indexOf('/')+1);
    }

    var decryptedReturn = JSON.parse(decrypt(tokenUrl));
    
    window.idJob = idJob;
    window.token = tokenUrl;
    window.rs_id_emp = decryptedReturn.id_emp; 
    window.rs_id_usr = decryptedReturn.id_usr;
    window.rs_id_lja = decryptedReturn.id_lja;
    window.rs_name_usr = decryptedReturn.name_usr;
    window.rs_id_apl = decryptedReturn.id_apl;
    window.rs_sistema_id = decryptedReturn.sistema_id;
    window.rs_permission_apl = decryptedReturn.pus;
    window.rs_id_module = decryptedReturn.id_module;

    // ESTADOS
    const [editorActive, setEditorActive] = useState(false);
    const [dragOver, setDragOver] = useState(false);
    const [dragEnd, setDragEnd] = useState(true);

    // ITENS DA PÁGINA
    const [items, setItems] = useState({
        others: {
            items: [
                { id: 3, label: "Autoavaliação", link: window.host+'/systems/autoavaliacao', category: 'atalho', info: '', size: 'calc(16.6666% - 21px)', component: '' },
                { id: 4, label: "Chamados R", link: window.host+'/systems/chamados-react', category: 'atalho', info: '', size: 'calc(16.6666% - 21px)', component: '' },
                { id: 5, label: "Comunicados", link: window.host+'/systems/comunicado', category: 'atalho', info: '', size: 'calc(16.6666% - 21px)', component: '' },
                { id: 6, label: "Documentos", link: window.host+'/systems/documentos-novo', category: 'atalho', info: '', size: 'calc(16.6666% - 21px)', component: '' },
                { id: 7, label: "Fases", link: window.host+'/systems/fases-react', category: 'atalho', info: '', size: 'calc(16.6666% - 21px)', component: '' },
                { id: 8, label: "Implantação", link: window.host+'/systems/fases-react', category: 'atalho', info: '', size: 'calc(16.6666% - 21px)', component: '' },
                { id: 9, label: "NPS", link: window.host+'/systems/nps', category: 'atalho', info: '', size: 'calc(16.6666% - 21px)', component: '' }
            ]
        },
        editor: {
            items: [
                { id: 10, label: "Avaliação", link: window.host+'/systems/avaliacao', category: 'atalho', info: '', size: 'calc(16.6666% - 21px)', component: '' },
                { id: 11, label: "Depósito", link: window.host+'/systems/deposito', category: 'atalho', info: '', size: 'calc(16.6666% - 21px)', component: '' },
                { id: 12, label: "Escala", link: window.host+'/systems/escala', category: 'atalho', info: '', size: 'calc(33.3333% - 17px)', component: '' },
                { id: 13, label: "FollowUp", link: window.host+'/systems/fases-react', category: 'atalho', info: '', size: 'calc(16.6666% - 21px)', component: '' },
                { id: 14, label: "Inventário", link: window.host+'/systems/inventario', category: 'atalho', info: '', size: 'calc(16.6666% - 21px)', component: '' },
                { id: 15, label: "Lote/Validade", link: window.host+'/systems/lote-validade', category: 'atalho', info: '', size: 'calc(16.6666% - 21px)', component: '' },                
                { id: 16, label: "Notificações", link: window.host+'/systems/notificacao', category: 'atalho', info: '', size: 'calc(16.6666% - 21px)', component: '' },
                { id: 17, label: "VM", link: window.host+'/systems/vm', category: 'atalho', info: '', size: 'calc(16.6666% - 21px)', component: '' },
                { id: 18, label: "Visitas", link: window.host+'/systems/visitas-react', category: 'atalho', info: '', size: 'calc(16.6666% - 21px)', component: '' },
                { id: 19, label: "Jobs R", link: '', category: 'modulo', info: '', size: 'calc(33.3333% - 17px)', component: 'jobs' },
                { id: 20, label: "News R", link: '', category: 'modulo', info: '', size: 'calc(33.3333% - 17px)', component: 'news' },
            ]              
        }
    });

    const [activeId, setActiveId] = useState();

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, { 
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    const [active, setActive] = useState([]);

    function customCollisionDetectionAlgorithm(args) {
        const pointerCollisions = pointerWithin(args);

        if (pointerCollisions.length > 0) {
            return pointerCollisions;
        }
        
        return rectIntersection(args);
    };

    function findContainer(id) {
        if (id in items) {
            return id;
        }

        return Object.keys(items).find((key) => items[key].items.filter((elem) => elem?.id === id).length > 0);  
    }

    function handleDragStart(event) {
        const { active } = event;
        const { id } = active;

        setActiveId(id);
        setDragEnd(false);
        setDragOver(true);
    };

    function handleDragOver(event) {
        const { active, over, activatorEvent } = event;
        const overId = over?.id;

        if (overId == null || active.id in items) {
            return;
        }

        const { id } = active;
    
        // Find the containers
        const activeContainer = findContainer(id);
        const overContainer = findContainer(overId);
    
        if (!activeContainer || !overContainer || activeContainer === overContainer) {
            return;
        }
    
        setItems((prev) => {
            const activeItems = prev[activeContainer];
            const overItems = prev[overContainer];
    
            //Find the indexes for the items
            const activeIndex = activeItems.items.findIndex((elem) => elem?.id == id);
            
            const overIndex = overItems.items.findIndex((elem) => elem?.id == overId);
    
            let newIndex;
            if (overId in prev) {
                newIndex = overItems.length + 1;
            } else {
                const isBelowLastItem = over && overIndex === overItems.length - 1;
                // && draggingRect.offsetTop > over.rect.offsetTop + over.rect.height;        
                const modifier = isBelowLastItem ? 1 : 0;        
                newIndex = overIndex >= 0 ? overIndex + modifier : overItems.length + 1;
            }

            return {
                ...prev,

                [activeContainer]: {
                    items:[
                    ...prev[activeContainer].items.filter((item) => item.id !== active.id)
                    ]
                },

                [overContainer]: {
                    items: [
                        ...prev[overContainer].items.slice(0, newIndex),
                        items[activeContainer].items[activeIndex],
                        ...prev[overContainer].items.slice(newIndex, prev[overContainer].length)
                    ]
                }
            };
        });

        setDragEnd(false);
        setDragOver(true);
    }

    function handleDragEnd(event) {
        const { active, over } = event;
        const { id } = active;
        const overId = over?.id;

        if (overId == null || active.id in items) {
            return;
        }

        const activeContainer = findContainer(id);
        const overContainer = findContainer(overId);

        if (!activeContainer || !overContainer || activeContainer !== overContainer) {
            return;
        }

        const activeIndex = items[activeContainer].items.findIndex((elem) => elem?.id == active.id);
        const overIndex = items[overContainer].items.findIndex((elem) => elem?.id == overId);

        if (activeIndex !== overIndex) {
            setItems((items) => ({
                ...items,
                [overContainer]: {
                    items: arrayMove(items[overContainer].items, activeIndex, overIndex)
                }
            }));

            // Object.assign(items[overContainer].items, arrayMove(items[overContainer].items, activeIndex, overIndex));
        }

        setActiveId(null);
        setDragEnd(true);
        setDragOver(false);
    };   

    return (    
        <JobsProvider>
            <div className="container my-4 my-lg-5">          
                <DndContext
                    sensors={sensors}
                    collisionDetection={customCollisionDetectionAlgorithm}
                    onDragStart={handleDragStart}
                    onDragOver={handleDragOver}
                    onDragEnd={handleDragEnd}
                >
                    <div className={style.favorite_container}>
                        <MainModuleText>
                            Bem-vindo, {window.rs_name_usr}!
                        </MainModuleText>

                        {/* <MainModuleText>
                            Principais Módulos
                        </MainModuleText>

                        <div className={style.moduleCard_container}>
                            <Container
                                id="favourites"
                                items={items.favourites.items}
                                large={true}
                                disabled={(editorActive ? false : true)}
                                dragEnd={dragEnd}
                            />
                        </div> */}
                    </div>

                    <div className={style.other_container}>              
                        <div className="div-modulo-2-b">
                            {/* <h3 className="bottomModuleTitle">Todos os módulos</h3> */}

                            <div className={style.moduleCard_container} >
                                <Container
                                    id="others"
                                    items={items.others.items}
                                    disabled={(editorActive ? false : true)}
                                    dragOver={dragOver} 
                                    dragEnd={dragEnd}
                                />
                            </div>
                        </div>

                    </div>

                    {/* MENU DE EDIÇÃO */}
                    <div className={style.editor + ' ' + (editorActive ? style.active : '')}>
                        <Tippy content={(editorActive ? 'Fechar editor' : 'Abrir editor')}>
                            <div className={style.editor__button} onClick={() => setEditorActive(!editorActive)}>
                                <Icon type={(editorActive ? 'no-edit' : 'edit')} />
                            </div>
                        </Tippy>
                        <div className={style.editor_container}>
                            {/* SESSÃO DE MÓDULOS */}
                            <div className="mb-4">
                                <h3 className="bottomModuleTitle">Módulos</h3>
                                
                                <div className={style.moduleCard_container}>
                                    <Container
                                        id="editor"
                                        items={items.editor.items.filter((elem) => elem?.category == 'modulo')}
                                        strategy={verticalListSortingStrategy}
                                        disabled={(editorActive ? false : true)}
                                        dragOver={dragOver} 
                                        dragEnd={dragEnd}
                                    />
                                </div>
                            </div> 

                            {/* SESSÃO DE ATALHOS */}
                            <div className="mb-4">
                                <h3 className="bottomModuleTitle">Atalhos</h3>
                                
                                <div className={style.moduleCard_container}>
                                    <Container
                                        id="editor"
                                        items={items.editor.items.filter((elem) => elem?.category == 'atalho')}
                                        strategy={verticalListSortingStrategy}
                                        disabled={(editorActive ? false : true)}
                                        dragOver={dragOver} 
                                        dragEnd={dragEnd}
                                    />
                                </div>
                            </div>                 
                        </div>
                    </div>
                </DndContext>  
            </div>
        </JobsProvider>
    );
};